import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
})


