export default {
	animals: [
		'mdi-bat',
		'mdi-bee',
		'mdi-bird',
		'mdi-bone',
		'mdi-bone-off',
		'mdi-bug',
		'mdi-bug-check',
		'mdi-bug-check-outline',
		'mdi-bug-outline',
		'mdi-butterfly',
		'mdi-butterfly-outline',
		'mdi-cat',
		'mdi-cow',
		'mdi-cow-off',
		'mdi-dog',
		'mdi-dog-service',
		'mdi-dog-side',
		'mdi-dog-side-off',
		'mdi-dolphin',
		'mdi-donkey',
		'mdi-duck',
		'mdi-elephant',
		'mdi-fish',
		'mdi-fishbowl',
		'mdi-fishbowl-outline',
		'mdi-google-downasaur',
		'mdi-horse',
		'mdi-horse-variant',
		'mdi-horse-variant-fast',
		'mdi-jellyfish',
		'mdi-jellyfish-outline',
		'mdi-kangaroo',
		'mdi-koala',
		'mdi-linux',
		'mdi-owl',
		'mdi-panda',
		'mdi-paw',
		'mdi-paw-off',
		'mdi-paw-off-outline',
		'mdi-paw-outline',
		'mdi-penguin',
		'mdi-pig',
		'mdi-pig-variant',
		'mdi-pig-variant-outline',
		'mdi-rabbit',
		'mdi-rabbit-variant',
		'mdi-rabbit-variant-outline',
		'mdi-rodent',
		'mdi-shark',
		'mdi-shark-fin',
		'mdi-shark-fin-outline',
		'mdi-shark-off',
		'mdi-sheep',
		'mdi-snail',
		'mdi-snake',
		'mdi-spider',
		'mdi-spider-thread',
		'mdi-tortoise',
		'mdi-turkey',
		'mdi-turtle',
		'mdi-unicorn',
		'mdi-unicorn-variant',
	],
	weather: [
		'mdi-aurora',
		'mdi-broadcast',
		'mdi-broadcast-off',
		'mdi-cloud',
		'mdi-cloud-alert',
		'mdi-cloud-alert-outline',
		'mdi-cloud-arrow-down',
		'mdi-cloud-arrow-down-outline',
		'mdi-cloud-arrow-left',
		'mdi-cloud-arrow-left-outline',
		'mdi-cloud-arrow-right',
		'mdi-cloud-arrow-right-outline',
		'mdi-cloud-arrow-up',
		'mdi-cloud-arrow-up-outline',
		'mdi-cloud-cancel',
		'mdi-cloud-cancel-outline',
		'mdi-cloud-check',
		'mdi-cloud-check-outline',
		'mdi-cloud-check-variant',
		'mdi-cloud-check-variant-outline',
		'mdi-cloud-circle',
		'mdi-cloud-circle-outline',
		'mdi-cloud-clock',
		'mdi-cloud-clock-outline',
		'mdi-cloud-cog',
		'mdi-cloud-cog-outline',
		'mdi-cloud-download',
		'mdi-cloud-download-outline',
		'mdi-cloud-off',
		'mdi-cloud-off-outline',
		'mdi-cloud-outline',
		'mdi-cloud-percent',
		'mdi-cloud-percent-outline',
		'mdi-cloud-question',
		'mdi-cloud-question-outline',
		'mdi-cloud-refresh',
		'mdi-cloud-refresh-outline',
		'mdi-cloud-refresh-variant',
		'mdi-cloud-refresh-variant-outline',
		'mdi-cloud-remove-outline',
		'mdi-cloud-search',
		'mdi-cloud-search-outline',
		'mdi-cloud-sync',
		'mdi-cloud-sync-outline',
		'mdi-cloud-upload',
		'mdi-cloud-upload-outline',
		'mdi-clouds',
		'mdi-flash',
		'mdi-flash-alert',
		'mdi-flash-alert-outline',
		'mdi-flash-outline',
		'mdi-heat-wave',
		'mdi-home-flood',
		'mdi-lightning-bolt',
		'mdi-lightning-bolt-circle',
		'mdi-lightning-bolt-outline',
		'mdi-looks',
		'mdi-moon-first-quarter',
		'mdi-moon-full',
		'mdi-moon-last-quarter',
		'mdi-moon-new',
		'mdi-moon-waning-crescent',
		'mdi-moon-waning-gibbous',
		'mdi-moon-waxing-crescent',
		'mdi-moon-waxing-gibbous',
		'mdi-shield-sun',
		'mdi-shield-sun-outline',
		'mdi-snowflake',
		'mdi-snowflake-alert',
		'mdi-snowflake-check',
		'mdi-snowflake-melt',
		'mdi-snowflake-off',
		'mdi-snowflake-thermometer',
		'mdi-snowflake-variant',
		'mdi-sun-angle',
		'mdi-sun-angle-outline',
		'mdi-sun-clock',
		'mdi-sun-clock-outline',
		'mdi-sun-compass',
		'mdi-sun-snowflake',
		'mdi-sun-snowflake-variant',
		'mdi-sun-thermometer',
		'mdi-sun-thermometer-outline',
		'mdi-sun-wireless',
		'mdi-sun-wireless-outline',
		'mdi-temperature-celsius',
		'mdi-temperature-fahrenheit',
		'mdi-temperature-kelvin',
		'mdi-theme-light-dark',
		'mdi-thermometer',
		'mdi-thermometer-alert',
		'mdi-thermometer-auto',
		'mdi-thermometer-bluetooth',
		'mdi-thermometer-check',
		'mdi-thermometer-chevron-down',
		'mdi-thermometer-chevron-up',
		'mdi-thermometer-high',
		'mdi-thermometer-lines',
		'mdi-thermometer-low',
		'mdi-thermometer-minus',
		'mdi-thermometer-off',
		'mdi-thermometer-plus',
		'mdi-thermometer-water',
		'mdi-tsunami',
		'mdi-umbrella',
		'mdi-umbrella-beach',
		'mdi-umbrella-beach-outline',
		'mdi-umbrella-closed',
		'mdi-umbrella-closed-outline',
		'mdi-umbrella-closed-variant',
		'mdi-umbrella-outline',
		'mdi-water',
		'mdi-water-opacity',
		'mdi-water-outline',
		'mdi-water-percent',
		'mdi-water-thermometer',
		'mdi-water-thermometer-outline',
		'mdi-waves',
		'mdi-waves-arrow-left',
		'mdi-waves-arrow-right',
		'mdi-waves-arrow-up',
		'mdi-weather-cloudy',
		'mdi-weather-cloudy-alert',
		'mdi-weather-cloudy-arrow-right',
		'mdi-weather-cloudy-clock',
		'mdi-weather-dust',
		'mdi-weather-fog',
		'mdi-weather-hail',
		'mdi-weather-hazy',
		'mdi-weather-hurricane',
		'mdi-weather-lightning',
		'mdi-weather-lightning-rainy',
		'mdi-weather-night',
		'mdi-weather-night-partly-cloudy',
		'mdi-weather-partly-cloudy',
		'mdi-weather-partly-lightning',
		'mdi-weather-partly-rainy',
		'mdi-weather-partly-snowy',
		'mdi-weather-partly-snowy-rainy',
		'mdi-weather-pouring',
		'mdi-weather-rainy',
		'mdi-weather-snowy',
		'mdi-weather-snowy-heavy',
		'mdi-weather-snowy-rainy',
		'mdi-weather-sunny',
		'mdi-weather-sunny-alert',
		'mdi-weather-sunny-off',
		'mdi-weather-sunset',
		'mdi-weather-sunset-down',
		'mdi-weather-sunset-up',
		'mdi-weather-tornado',
		'mdi-weather-windy',
		'mdi-weather-windy-variant',
		'mdi-white-balance-sunny',
		'mdi-windsock',
	],
	view: [
		'mdi-apps',
		'mdi-view-agenda',
		'mdi-view-agenda-outline',
		'mdi-view-array',
		'mdi-view-array-outline',
		'mdi-view-carousel',
		'mdi-view-carousel-outline',
		'mdi-view-column',
		'mdi-view-column-outline',
		'mdi-view-comfy',
		'mdi-view-comfy-outline',
		'mdi-view-compact',
		'mdi-view-compact-outline',
		'mdi-view-dashboard',
		'mdi-view-dashboard-edit',
		'mdi-view-dashboard-edit-outline',
		'mdi-view-dashboard-outline',
		'mdi-view-dashboard-variant',
		'mdi-view-dashboard-variant-outline',
		'mdi-view-day',
		'mdi-view-day-outline',
		'mdi-view-gallery',
		'mdi-view-gallery-outline',
		'mdi-view-grid',
		'mdi-view-grid-outline',
		'mdi-view-grid-plus',
		'mdi-view-grid-plus-outline',
		'mdi-view-headline',
		'mdi-view-list',
		'mdi-view-list-outline',
		'mdi-view-module',
		'mdi-view-module-outline',
		'mdi-view-parallel',
		'mdi-view-parallel-outline',
		'mdi-view-quilt',
		'mdi-view-quilt-outline',
		'mdi-view-sequential',
		'mdi-view-sequential-outline',
		'mdi-view-split-horizontal',
		'mdi-view-split-vertical',
		'mdi-view-stream',
		'mdi-view-stream-outline',
		'mdi-view-week',
		'mdi-view-week-outline',
	],
	movie: [
		'mdi-camcorder',
		'mdi-camcorder-off',
		'mdi-file-video',
		'mdi-film',
		'mdi-filmstrip',
		'mdi-filmstrip-box-multiple',
		'mdi-filmstrip-off',
		'mdi-grain',
		'mdi-hdmi-port',
		'mdi-high-definition',
		'mdi-high-definition-box',
		'mdi-message-video',
		'mdi-motion-pause',
		'mdi-motion-pause-outline',
		'mdi-motion-play',
		'mdi-motion-play-outline',
		'mdi-movie',
		'mdi-movie-check',
		'mdi-movie-check-outline',
		'mdi-movie-cog',
		'mdi-movie-cog-outline',
		'mdi-movie-edit',
		'mdi-movie-edit-outline',
		'mdi-movie-filter',
		'mdi-movie-filter-outline',
		'mdi-movie-minus',
		'mdi-movie-minus-outline',
		'mdi-movie-off',
		'mdi-movie-off-outline',
		'mdi-movie-open',
		'mdi-movie-open-check',
		'mdi-movie-open-check-outline',
		'mdi-movie-open-cog',
		'mdi-movie-open-cog-outline',
		'mdi-movie-open-edit',
		'mdi-movie-open-edit-outline',
		'mdi-movie-open-minus',
		'mdi-movie-open-minus-outline',
		'mdi-movie-open-off',
		'mdi-movie-open-off-outline',
		'mdi-movie-open-outline',
		'mdi-movie-open-play',
		'mdi-movie-open-play-outline',
		'mdi-movie-open-plus',
		'mdi-movie-open-plus-outline',
		'mdi-movie-open-remove',
		'mdi-movie-open-remove-outline',
		'mdi-movie-open-settings',
		'mdi-movie-open-settings-outline',
		'mdi-movie-open-star',
		'mdi-movie-open-star-outline',
		'mdi-movie-outline',
		'mdi-movie-play',
		'mdi-movie-play-outline',
		'mdi-movie-plus',
		'mdi-movie-plus-outline',
		'mdi-movie-remove',
		'mdi-movie-remove-outline',
		'mdi-movie-roll',
		'mdi-movie-search',
		'mdi-movie-search-outline',
		'mdi-movie-settings',
		'mdi-movie-settings-outline',
		'mdi-movie-star',
		'mdi-movie-star-outline',
		'mdi-multimedia',
		'mdi-play-box-lock',
		'mdi-play-box-lock-open',
		'mdi-play-box-lock-open-outline',
		'mdi-play-box-lock-outline',
		'mdi-quality-high',
		'mdi-standard-definition',
		'mdi-television-speaker',
		'mdi-television-speaker-off',
		'mdi-ultra-high-definition',
		'mdi-vhs',
		'mdi-video',
		'mdi-video-2d',
		'mdi-video-3d',
		'mdi-video-3d-off',
		'mdi-video-3d-variant',
		'mdi-video-4k-box',
		'mdi-video-account',
		'mdi-video-box',
		'mdi-video-box-off',
		'mdi-video-check',
		'mdi-video-check-outline',
		'mdi-video-high-definition',
		'mdi-video-image',
		'mdi-video-input-antenna',
		'mdi-video-input-component',
		'mdi-video-input-hdmi',
		'mdi-video-input-scart',
		'mdi-video-input-svideo',
		'mdi-video-marker',
		'mdi-video-marker-outline',
		'mdi-video-minus',
		'mdi-video-minus-outline',
		'mdi-video-off',
		'mdi-video-off-outline',
		'mdi-video-outline',
		'mdi-video-plus',
		'mdi-video-plus-outline',
		'mdi-video-stabilization',
		'mdi-video-switch',
		'mdi-video-switch-outline',
		'mdi-video-vintage',
		'mdi-video-wireless',
		'mdi-video-wireless-outline',
		'mdi-webcam',
	],
	vectors: [
		'mdi-vector-arrange-above',
		'mdi-vector-arrange-below',
		'mdi-vector-bezier',
		'mdi-vector-circle',
		'mdi-vector-circle-variant',
		'mdi-vector-combine',
		'mdi-vector-curve',
		'mdi-vector-difference',
		'mdi-vector-difference-ab',
		'mdi-vector-difference-ba',
		'mdi-vector-ellipse',
		'mdi-vector-intersection',
		'mdi-vector-line',
		'mdi-vector-link',
		'mdi-vector-point',
		'mdi-vector-point-edit',
		'mdi-vector-point-minus',
		'mdi-vector-point-plus',
		'mdi-vector-point-select',
		'mdi-vector-polygon',
		'mdi-vector-polygon-variant',
		'mdi-vector-polyline',
		'mdi-vector-radius',
		'mdi-vector-rectangle',
		'mdi-vector-selection',
		'mdi-vector-square',
		'mdi-vector-square-close',
		'mdi-vector-square-edit',
		'mdi-vector-square-minus',
		'mdi-vector-square-open',
		'mdi-vector-square-plus',
		'mdi-vector-square-remove',
		'mdi-vector-triangle',
		'mdi-vector-union',
	],
	transportation: [
		'mdi-anchor',
		'mdi-ferry',
		'mdi-lifebuoy',
		'mdi-pier',
		'mdi-pier-crane',
		'mdi-rowing',
		'mdi-sail-boat',
		'mdi-sail-boat-sink',
		'mdi-ship-wheel',
		'mdi-ski-water',
		'mdi-wave',
		'mdi-waves',
		'mdi-ambulance',
		'mdi-boom-gate',
		'mdi-boom-gate-alert',
		'mdi-boom-gate-alert-outline',
		'mdi-boom-gate-arrow-down',
		'mdi-boom-gate-arrow-down-outline',
		'mdi-boom-gate-arrow-up',
		'mdi-boom-gate-arrow-up-outline',
		'mdi-boom-gate-outline',
		'mdi-boom-gate-up',
		'mdi-boom-gate-up-outline',
		'mdi-bus',
		'mdi-bus-alert',
		'mdi-bus-articulated-end',
		'mdi-bus-articulated-front',
		'mdi-bus-clock',
		'mdi-bus-double-decker',
		'mdi-bus-electric',
		'mdi-bus-multiple',
		'mdi-bus-school',
		'mdi-bus-side',
		'mdi-bus-stop',
		'mdi-bus-stop-covered',
		'mdi-bus-stop-uncovered',
		'mdi-car',
		'mdi-car-2-plus',
		'mdi-car-3-plus',
		'mdi-car-arrow-left',
		'mdi-car-arrow-right',
		'mdi-car-back',
		'mdi-car-cog',
		'mdi-car-connected',
		'mdi-car-convertible',
		'mdi-car-electric',
		'mdi-car-electric-outline',
		'mdi-car-emergency',
		'mdi-car-estate',
		'mdi-car-hatchback',
		'mdi-car-key',
		'mdi-car-limousine',
		'mdi-car-multiple',
		'mdi-car-pickup',
		'mdi-car-side',
		'mdi-car-sports',
		'mdi-car-wash',
		'mdi-caravan',
		'mdi-cards-diamond',
		'mdi-cards-diamond-outline',
		'mdi-dump-truck',
		'mdi-fire-truck',
		'mdi-forklift',
		'mdi-hail',
		'mdi-highway',
		'mdi-jeepney',
		'mdi-minus-circle',
		'mdi-minus-circle-outline',
		'mdi-moped',
		'mdi-moped-electric',
		'mdi-moped-electric-outline',
		'mdi-moped-outline',
		'mdi-motorbike',
		'mdi-motorbike-electric',
		'mdi-motorbike-off',
		'mdi-octagon',
		'mdi-octagon-outline',
		'mdi-plane-car',
		'mdi-rickshaw',
		'mdi-rickshaw-electric',
		'mdi-road',
		'mdi-road-variant',
		'mdi-rv-truck',
		'mdi-sign-caution',
		'mdi-sign-yield',
		'mdi-tanker-truck',
		'mdi-taxi',
		'mdi-tow-truck',
		'mdi-tractor',
		'mdi-traffic-cone',
		'mdi-traffic-light',
		'mdi-traffic-light-outline',
		'mdi-truck',
		'mdi-truck-alert',
		'mdi-truck-alert-outline',
		'mdi-truck-cargo-container',
		'mdi-truck-check',
		'mdi-truck-check-outline',
		'mdi-truck-delivery',
		'mdi-truck-delivery-outline',
		'mdi-truck-fast',
		'mdi-truck-fast-outline',
		'mdi-truck-flatbed',
		'mdi-truck-minus',
		'mdi-truck-minus-outline',
		'mdi-truck-outline',
		'mdi-truck-plus',
		'mdi-truck-plus-outline',
		'mdi-truck-remove',
		'mdi-truck-remove-outline',
		'mdi-truck-snowflake',
		'mdi-truck-trailer',
		'mdi-tunnel',
		'mdi-tunnel-outline',
		'mdi-van-passenger',
		'mdi-van-utility',
		'mdi-airballoon',
		'mdi-atv',
		'mdi-bicycle',
		'mdi-bicycle-basket',
		'mdi-bicycle-cargo',
		'mdi-bicycle-electric',
		'mdi-bicycle-penny-farthing',
		'mdi-bike',
		'mdi-bike-fast',
		'mdi-elevator',
		'mdi-elevator-down',
		'mdi-elevator-passenger',
		'mdi-elevator-passenger-off',
		'mdi-elevator-passenger-off-outline',
		'mdi-elevator-passenger-outline',
		'mdi-elevator-up',
		'mdi-escalator',
		'mdi-escalator-down',
		'mdi-escalator-up',
		'mdi-golf-cart',
		'mdi-gondola',
		'mdi-horse',
		'mdi-horse-human',
		'mdi-human-capacity-decrease',
		'mdi-human-capacity-increase',
		'mdi-human-scooter',
		'mdi-moped',
		'mdi-plane-train',
		'mdi-railroad-light',
		'mdi-rickshaw',
		'mdi-rickshaw-electric',
		'mdi-scooter',
		'mdi-scooter-electric',
		'mdi-snowmobile',
		'mdi-stairs',
		'mdi-stairs-down',
		'mdi-stairs-up',
		'mdi-subway',
		'mdi-subway-alert-variant',
		'mdi-subway-variant',
		'mdi-train',
		'mdi-train-car',
		'mdi-train-car-autorack',
		'mdi-train-car-box',
		'mdi-train-car-box-full',
		'mdi-train-car-box-open',
		'mdi-train-car-caboose',
		'mdi-train-car-centerbeam',
		'mdi-train-car-centerbeam-full',
		'mdi-train-car-container',
		'mdi-train-car-flatbed',
		'mdi-train-car-flatbed-car',
		'mdi-train-car-flatbed-tank',
		'mdi-train-car-gondola',
		'mdi-train-car-gondola-full',
		'mdi-train-car-hopper',
		'mdi-train-car-hopper-covered',
		'mdi-train-car-hopper-full',
		'mdi-train-car-intermodal',
		'mdi-train-car-passenger',
		'mdi-train-car-passenger-door',
		'mdi-train-car-passenger-door-open',
		'mdi-train-car-passenger-variant',
		'mdi-train-car-tank',
		'mdi-train-variant',
		'mdi-tram',
		'mdi-tram-side',
		'mdi-transit-connection',
		'mdi-transit-connection-horizontal',
		'mdi-transit-connection-variant',
		'mdi-transit-detour',
		'mdi-transit-skip',
		'mdi-transit-transfer',
		'mdi-tunnel',
		'mdi-tunnel-outline',
		'mdi-unicycle',
		'mdi-walk',
		'mdi-account-tie-hat',
		'mdi-account-tie-hat-outline',
		'mdi-airballoon',
		'mdi-airballoon-outline',
		'mdi-airplane',
		'mdi-airplane-alert',
		'mdi-airplane-check',
		'mdi-airplane-clock',
		'mdi-airplane-cog',
		'mdi-airplane-edit',
		'mdi-airplane-landing',
		'mdi-airplane-marker',
		'mdi-airplane-minus',
		'mdi-airplane-off',
		'mdi-airplane-plus',
		'mdi-airplane-remove',
		'mdi-airplane-search',
		'mdi-airplane-settings',
		'mdi-airplane-takeoff',
		'mdi-airport',
		'mdi-bag-carry-on',
		'mdi-bag-carry-on-check',
		'mdi-bag-carry-on-off',
		'mdi-bag-checked',
		'mdi-bag-personal',
		'mdi-bag-personal-off',
		'mdi-bag-personal-off-outline',
		'mdi-bag-personal-outline',
		'mdi-bag-suitcase',
		'mdi-bag-suitcase-off',
		'mdi-bag-suitcase-off-outline',
		'mdi-bag-suitcase-outline',
		'mdi-drone',
		'mdi-helicopter',
		'mdi-parachute',
		'mdi-parachute-outline',
		'mdi-plane-car',
		'mdi-plane-train',
		'mdi-rocket',
		'mdi-rocket-launch',
		'mdi-rocket-launch-outline',
		'mdi-rocket-outline',
		'mdi-shield-airplane',
		'mdi-shield-airplane-outline',
		'mdi-turbine',
	],
	tooltip: [
		'mdi-tooltip',
		'mdi-tooltip-account',
		'mdi-tooltip-cellphone',
		'mdi-tooltip-check',
		'mdi-tooltip-check-outline',
		'mdi-tooltip-edit',
		'mdi-tooltip-edit-outline',
		'mdi-tooltip-image',
		'mdi-tooltip-image-outline',
		'mdi-tooltip-minus',
		'mdi-tooltip-minus-outline',
		'mdi-tooltip-outline',
		'mdi-tooltip-plus',
		'mdi-tooltip-plus-outline',
		'mdi-tooltip-question',
		'mdi-tooltip-question-outline',
		'mdi-tooltip-remove',
		'mdi-tooltip-remove-outline',
		'mdi-tooltip-text',
		'mdi-tooltip-text-outline',
	],
	'text formate': [
		'mdi-align-horizontal-distribute',
		'mdi-align-vertical-distribute',
		'mdi-border-all',
		'mdi-border-all-variant',
		'mdi-border-bottom',
		'mdi-border-bottom-variant',
		'mdi-border-color',
		'mdi-border-horizontal',
		'mdi-border-inside',
		'mdi-border-left',
		'mdi-border-left-variant',
		'mdi-border-none',
		'mdi-border-none-variant',
		'mdi-border-outside',
		'mdi-border-radius',
		'mdi-border-right',
		'mdi-border-right-variant',
		'mdi-border-style',
		'mdi-border-top',
		'mdi-border-top-variant',
		'mdi-border-vertical',
		'mdi-caps-lock',
		'mdi-color-helper',
		'mdi-content-copy',
		'mdi-content-cut',
		'mdi-content-paste',
		'mdi-fit-to-page',
		'mdi-fit-to-page-outline',
		'mdi-format-align-bottom',
		'mdi-format-align-center',
		'mdi-format-align-justify',
		'mdi-format-align-left',
		'mdi-format-align-middle',
		'mdi-format-align-right',
		'mdi-format-align-top',
		'mdi-format-annotation-minus',
		'mdi-format-annotation-plus',
		'mdi-format-bold',
		'mdi-format-clear',
		'mdi-format-color-fill',
		'mdi-format-color-highlight',
		'mdi-format-color-marker-cancel',
		'mdi-format-color-text',
		'mdi-format-columns',
		'mdi-format-float-center',
		'mdi-format-float-left',
		'mdi-format-float-none',
		'mdi-format-float-right',
		'mdi-format-font',
		'mdi-format-font-size-decrease',
		'mdi-format-font-size-increase',
		'mdi-format-header-1',
		'mdi-format-header-2',
		'mdi-format-header-3',
		'mdi-format-header-4',
		'mdi-format-header-5',
		'mdi-format-header-6',
		'mdi-format-header-decrease',
		'mdi-format-header-equal',
		'mdi-format-header-increase',
		'mdi-format-header-pound',
		'mdi-format-horizontal-align-center',
		'mdi-format-horizontal-align-left',
		'mdi-format-horizontal-align-right',
		'mdi-format-indent-decrease',
		'mdi-format-indent-increase',
		'mdi-format-italic',
		'mdi-format-letter-case',
		'mdi-format-letter-case-lower',
		'mdi-format-letter-case-upper',
		'mdi-format-letter-ends-with',
		'mdi-format-letter-matches',
		'mdi-format-letter-spacing',
		'mdi-format-letter-spacing-variant',
		'mdi-format-letter-starts-with',
		'mdi-format-line-height',
		'mdi-format-line-spacing',
		'mdi-format-line-style',
		'mdi-format-line-weight',
		'mdi-format-list-bulleted',
		'mdi-format-list-bulleted-square',
		'mdi-format-list-bulleted-triangle',
		'mdi-format-list-bulleted-type',
		'mdi-format-list-checkbox',
		'mdi-format-list-checks',
		'mdi-format-list-group',
		'mdi-format-list-group-plus',
		'mdi-format-list-numbered',
		'mdi-format-list-numbered-rtl',
		'mdi-format-list-text',
		'mdi-format-overline',
		'mdi-format-page-break',
		'mdi-format-page-split',
		'mdi-format-paint',
		'mdi-format-paragraph',
		'mdi-format-paragraph-spacing',
		'mdi-format-pilcrow',
		'mdi-format-pilcrow-arrow-left',
		'mdi-format-pilcrow-arrow-right',
		'mdi-format-quote-close',
		'mdi-format-quote-close-outline',
		'mdi-format-quote-open',
		'mdi-format-quote-open-outline',
		'mdi-format-rotate-90',
		'mdi-format-section',
		'mdi-format-size',
		'mdi-format-strikethrough',
		'mdi-format-strikethrough-variant',
		'mdi-format-subscript',
		'mdi-format-superscript',
		'mdi-format-text',
		'mdi-format-text-rotation-angle-down',
		'mdi-format-text-rotation-angle-up',
		'mdi-format-text-rotation-down',
		'mdi-format-text-rotation-down-vertical',
		'mdi-format-text-rotation-none',
		'mdi-format-text-rotation-up',
		'mdi-format-text-rotation-vertical',
		'mdi-format-text-variant',
		'mdi-format-text-variant-outline',
		'mdi-format-text-wrapping-clip',
		'mdi-format-text-wrapping-overflow',
		'mdi-format-text-wrapping-wrap',
		'mdi-format-textbox',
		'mdi-format-title',
		'mdi-format-underline',
		'mdi-format-underline-wavy',
		'mdi-format-vertical-align-bottom',
		'mdi-format-vertical-align-center',
		'mdi-format-vertical-align-top',
		'mdi-format-wrap-inline',
		'mdi-format-wrap-square',
		'mdi-format-wrap-tight',
		'mdi-format-wrap-top-bottom',
		'mdi-furigana-horizontal',
		'mdi-furigana-vertical',
		'mdi-list-status',
		'mdi-marker',
		'mdi-marker-cancel',
		'mdi-order-alphabetical-ascending',
		'mdi-order-alphabetical-descending',
		'mdi-order-bool-ascending',
		'mdi-order-bool-ascending-variant',
		'mdi-order-bool-descending',
		'mdi-order-bool-descending-variant',
		'mdi-order-numeric-ascending',
		'mdi-order-numeric-descending',
		'mdi-sort',
		'mdi-sort-alphabetical-ascending',
		'mdi-sort-alphabetical-ascending-variant',
		'mdi-sort-alphabetical-descending',
		'mdi-sort-alphabetical-descending-variant',
		'mdi-sort-alphabetical-variant',
		'mdi-sort-ascending',
		'mdi-sort-bool-ascending',
		'mdi-sort-bool-ascending-variant',
		'mdi-sort-bool-descending',
		'mdi-sort-bool-descending-variant',
		'mdi-sort-calendar-ascending',
		'mdi-sort-calendar-descending',
		'mdi-sort-clock-ascending',
		'mdi-sort-clock-ascending-outline',
		'mdi-sort-clock-descending',
		'mdi-sort-clock-descending-outline',
		'mdi-sort-descending',
		'mdi-sort-numeric-ascending',
		'mdi-sort-numeric-ascending-variant',
		'mdi-sort-numeric-descending',
		'mdi-sort-numeric-descending-variant',
		'mdi-sort-numeric-variant',
		'mdi-sort-reverse-variant',
		'mdi-sort-variant',
		'mdi-sort-variant-lock',
		'mdi-sort-variant-lock-open',
		'mdi-sort-variant-off',
		'mdi-sort-variant-remove',
		'mdi-stretch-to-page',
		'mdi-stretch-to-page-outline',
		'mdi-table',
		'mdi-table-border',
		'mdi-table-column',
		'mdi-table-column-plus-after',
		'mdi-table-column-plus-before',
		'mdi-table-column-remove',
		'mdi-table-column-width',
		'mdi-table-edit',
		'mdi-table-large',
		'mdi-table-large-plus',
		'mdi-table-large-remove',
		'mdi-table-merge-cells',
		'mdi-table-pivot',
		'mdi-table-plus',
		'mdi-table-remove',
		'mdi-table-row',
		'mdi-table-row-height',
		'mdi-table-row-plus-after',
		'mdi-table-row-plus-before',
		'mdi-table-row-remove',
		'mdi-table-split-cell',
		'mdi-text',
	],
	sport: [
		'mdi-arrow-projectile',
		'mdi-arrow-projectile-multiple',
		'mdi-atv',
		'mdi-badminton',
		'mdi-baseball',
		'mdi-baseball-bat',
		'mdi-baseball-diamond',
		'mdi-baseball-diamond-outline',
		'mdi-basketball',
		'mdi-basketball-hoop',
		'mdi-basketball-hoop-outline',
		'mdi-biathlon',
		'mdi-bike',
		'mdi-billiards',
		'mdi-billiards-rack',
		'mdi-bow-arrow',
		'mdi-bowling',
		'mdi-boxing-glove',
		'mdi-bullseye',
		'mdi-bullseye-arrow',
		'mdi-car-sports',
		'mdi-carabiner',
		'mdi-cricket',
		'mdi-curling',
		'mdi-dance-pole',
		'mdi-diving',
		'mdi-diving-flippers',
		'mdi-diving-scuba',
		'mdi-diving-scuba-mask',
		'mdi-diving-snorkel',
		'mdi-dumbbell',
		'mdi-fencing',
		'mdi-flag-checkered',
		'mdi-football',
		'mdi-football-australian',
		'mdi-football-helmet',
		'mdi-go-kart',
		'mdi-golf',
		'mdi-golf-cart',
		'mdi-golf-tee',
		'mdi-gymnastics',
		'mdi-hand-cycle',
		'mdi-handball',
		'mdi-hiking',
		'mdi-hockey-puck',
		'mdi-hockey-sticks',
		'mdi-horseshoe',
		'mdi-human-scooter',
		'mdi-jump-rope',
		'mdi-kabaddi',
		'mdi-karate',
		'mdi-kayaking',
		'mdi-kettlebell',
		'mdi-kite',
		'mdi-kite-outline',
		'mdi-medal',
		'mdi-medal-outline',
		'mdi-meditation',
		'mdi-mixed-martial-arts',
		'mdi-motorbike',
		'mdi-paragliding',
		'mdi-podium',
		'mdi-podium-bronze',
		'mdi-podium-gold',
		'mdi-podium-silver',
		'mdi-polo',
		'mdi-racing-helmet',
		'mdi-racquetball',
		'mdi-roller-skate',
		'mdi-roller-skate-off',
		'mdi-rollerblade',
		'mdi-rollerblade-off',
		'mdi-rowing',
		'mdi-rugby',
		'mdi-run',
		'mdi-run-fast',
		'mdi-sail-boat',
		'mdi-scoreboard',
		'mdi-scoreboard-outline',
		'mdi-shoe-ballet',
		'mdi-shoe-cleat',
		'mdi-shoe-sneaker',
		'mdi-skate',
		'mdi-skateboard',
		'mdi-skateboarding',
		'mdi-ski',
		'mdi-ski-cross-country',
		'mdi-ski-water',
		'mdi-sledding',
		'mdi-snowboard',
		'mdi-snowmobile',
		'mdi-snowshoeing',
		'mdi-soccer',
		'mdi-soccer-field',
		'mdi-stadium',
		'mdi-stadium-outline',
		'mdi-stadium-variant',
		'mdi-strategy',
		'mdi-swim',
		'mdi-table-tennis',
		'mdi-tennis',
		'mdi-tennis-ball',
		'mdi-timer',
		'mdi-timer-outline',
		'mdi-torch',
		'mdi-tournament',
		'mdi-trophy',
		'mdi-trophy-award',
		'mdi-trophy-broken',
		'mdi-trophy-outline',
		'mdi-trophy-variant',
		'mdi-trophy-variant-outline',
		'mdi-unicycle',
		'mdi-volleyball',
		'mdi-walk',
		'mdi-water-polo',
		'mdi-weight-lifter',
		'mdi-whistle',
		'mdi-whistle-outline',
		'mdi-yoga',
	],
	'social media': [
		'mdi-facebook',
		'mdi-facebook-messenger',
		'mdi-facebook-workplace',
		'mdi-google-plus',
		'mdi-linkedin',
		'mdi-microsoft-xbox',
		'mdi-reddit',
		'mdi-twitch',
		'mdi-twitter',
		'mdi-youtube',
	],
	shopping: [
		'mdi-basket',
		'mdi-basket-check',
		'mdi-basket-check-outline',
		'mdi-basket-fill',
		'mdi-basket-minus',
		'mdi-basket-minus-outline',
		'mdi-basket-off',
		'mdi-basket-off-outline',
		'mdi-basket-outline',
		'mdi-basket-plus',
		'mdi-basket-plus-outline',
		'mdi-basket-remove',
		'mdi-basket-remove-outline',
		'mdi-basket-unfill',
		'mdi-brightness-percent',
		'mdi-cart',
		'mdi-cart-arrow-down',
		'mdi-cart-arrow-right',
		'mdi-cart-arrow-up',
		'mdi-cart-check',
		'mdi-cart-heart',
		'mdi-cart-minus',
		'mdi-cart-off',
		'mdi-cart-outline',
		'mdi-cart-percent',
		'mdi-cart-plus',
		'mdi-cart-remove',
		'mdi-cart-variant',
		'mdi-cash',
		'mdi-cash-register',
		'mdi-credit-card-outline',
		'mdi-gift-outline',
		'mdi-percent',
		'mdi-percent-box',
		'mdi-percent-box-outline',
		'mdi-percent-circle',
		'mdi-percent-circle-outline',
		'mdi-percent-outline',
		'mdi-sale',
		'mdi-sale-outline',
		'mdi-shopping',
		'mdi-shopping-music',
		'mdi-shopping-outline',
		'mdi-shopping-search',
		'mdi-shopping-search-outline',
		'mdi-store',
		'mdi-store-24-hour',
		'mdi-store-alert',
		'mdi-store-alert-outline',
		'mdi-store-check',
		'mdi-store-check-outline',
		'mdi-store-clock',
		'mdi-store-clock-outline',
		'mdi-store-cog',
		'mdi-store-cog-outline',
		'mdi-store-edit',
		'mdi-store-edit-outline',
		'mdi-store-marker',
		'mdi-store-marker-outline',
		'mdi-store-minus',
		'mdi-store-minus-outline',
		'mdi-store-off',
		'mdi-store-off-outline',
		'mdi-store-outline',
		'mdi-store-plus',
		'mdi-store-plus-outline',
		'mdi-store-remove',
		'mdi-store-remove-outline',
		'mdi-store-search',
		'mdi-store-search-outline',
		'mdi-store-settings',
		'mdi-store-settings-outline',
		'mdi-storefront-outline',
		'mdi-wallet-giftcard',
	],
	shape: [
		'mdi-circle',
		'mdi-circle-double',
		'mdi-circle-half',
		'mdi-circle-half-full',
		'mdi-circle-opacity',
		'mdi-circle-outline',
		'mdi-cone',
		'mdi-cone-off',
		'mdi-cube',
		'mdi-cube-outline',
		'mdi-cylinder',
		'mdi-cylinder-off',
		'mdi-decagram',
		'mdi-decagram-outline',
		'mdi-drawing',
		'mdi-drawing-box',
		'mdi-ellipse',
		'mdi-ellipse-outline',
		'mdi-heart',
		'mdi-heart-outline',
		'mdi-hexagon',
		'mdi-hexagon-multiple',
		'mdi-hexagon-outline',
		'mdi-hexagram',
		'mdi-hexagram-outline',
		'mdi-octagon',
		'mdi-octagon-outline',
		'mdi-octagram',
		'mdi-octagram-outline',
		'mdi-octahedron',
		'mdi-octahedron-off',
		'mdi-pentagon',
		'mdi-pentagon-outline',
		'mdi-pyramid',
		'mdi-pyramid-off',
		'mdi-rectangle',
		'mdi-rectangle-outline',
		'mdi-rhombus',
		'mdi-rhombus-medium',
		'mdi-rhombus-medium-outline',
		'mdi-rhombus-outline',
		'mdi-rhombus-split',
		'mdi-rhombus-split-outline',
		'mdi-shape',
		'mdi-shape-circle-plus',
		'mdi-shape-outline',
		'mdi-shape-plus',
		'mdi-shape-polygon-plus',
		'mdi-shape-rectangle-plus',
		'mdi-shape-square-plus',
		'mdi-sphere',
		'mdi-sphere-off',
		'mdi-square',
		'mdi-square-medium',
		'mdi-square-medium-outline',
		'mdi-square-opacity',
		'mdi-square-outline',
		'mdi-square-rounded-badge',
		'mdi-square-rounded-badge-outline',
		'mdi-star',
		'mdi-star-check',
		'mdi-star-check-outline',
		'mdi-star-four-points',
		'mdi-star-four-points-outline',
		'mdi-star-half',
		'mdi-star-minus',
		'mdi-star-minus-outline',
		'mdi-star-outline',
		'mdi-star-plus',
		'mdi-star-plus-outline',
		'mdi-star-remove',
		'mdi-star-remove-outline',
		'mdi-star-three-points',
		'mdi-star-three-points-outline',
		'mdi-triangle',
		'mdi-triangle-outline',
		'mdi-triangle-small-down',
		'mdi-triangle-small-up',
	],
	settings: [
		'mdi-account-cog',
		'mdi-account-cog-outline',
		'mdi-account-details',
		'mdi-account-details-outline',
		'mdi-account-settings',
		'mdi-account-settings-outline',
		'mdi-airplane-cog',
		'mdi-airplane-settings',
		'mdi-application-cog',
		'mdi-application-cog-outline',
		'mdi-application-settings',
		'mdi-application-settings-outline',
		'mdi-archive-cog',
		'mdi-archive-cog-outline',
		'mdi-archive-settings',
		'mdi-archive-settings-outline',
		'mdi-bell-cog',
		'mdi-bell-cog-outline',
		'mdi-bluetooth-settings',
		'mdi-book-cog',
		'mdi-book-cog-outline',
		'mdi-book-settings',
		'mdi-book-settings-outline',
		'mdi-car-cog',
		'mdi-car-settings',
		'mdi-card-bulleted-settings',
		'mdi-card-bulleted-settings-outline',
		'mdi-cellphone-cog',
		'mdi-cellphone-settings',
		'mdi-cog',
		'mdi-cog-box',
		'mdi-cog-clockwise',
		'mdi-cog-counterclockwise',
		'mdi-cog-off',
		'mdi-cog-off-outline',
		'mdi-cog-outline',
		'mdi-cog-pause',
		'mdi-cog-pause-outline',
		'mdi-cog-play',
		'mdi-cog-play-outline',
		'mdi-cog-refresh',
		'mdi-cog-refresh-outline',
		'mdi-cog-stop',
		'mdi-cog-stop-outline',
		'mdi-cog-sync',
		'mdi-cog-sync-outline',
		'mdi-cog-transfer',
		'mdi-cog-transfer-outline',
		'mdi-cogs',
		'mdi-content-save-cog',
		'mdi-content-save-cog-outline',
		'mdi-content-save-settings',
		'mdi-content-save-settings-outline',
		'mdi-cookie-cog',
		'mdi-cookie-cog-outline',
		'mdi-cookie-settings',
		'mdi-cookie-settings-outline',
		'mdi-credit-card-settings',
		'mdi-credit-card-settings-outline',
		'mdi-database-cog',
		'mdi-database-cog-outline',
		'mdi-database-settings',
		'mdi-database-settings-outline',
		'mdi-eye-settings',
		'mdi-eye-settings-outline',
		'mdi-file-cog',
		'mdi-file-cog-outline',
		'mdi-file-settings',
		'mdi-file-settings-outline',
		'mdi-filter-cog',
		'mdi-filter-cog-outline',
		'mdi-filter-settings',
		'mdi-filter-settings-outline',
		'mdi-folder-cog',
		'mdi-folder-cog-outline',
		'mdi-folder-settings',
		'mdi-folder-settings-outline',
		'mdi-head-cog',
		'mdi-head-cog-outline',
		'mdi-headphones-settings',
		'mdi-heart-cog',
		'mdi-heart-cog-outline',
		'mdi-heart-settings',
		'mdi-heart-settings-outline',
		'mdi-keyboard-settings',
		'mdi-keyboard-settings-outline',
		'mdi-message-cog',
		'mdi-message-cog-outline',
		'mdi-message-settings',
		'mdi-message-settings-outline',
		'mdi-microphone-settings',
		'mdi-movie-cog',
		'mdi-movie-cog-outline',
		'mdi-movie-open-cog',
		'mdi-movie-open-cog-outline',
		'mdi-movie-open-settings',
		'mdi-movie-open-settings-outline',
		'mdi-movie-settings',
		'mdi-movie-settings-outline',
		'mdi-network-strength-4-cog',
		'mdi-office-building-cog',
		'mdi-office-building-cog-outline',
		'mdi-phone-settings',
		'mdi-phone-settings-outline',
		'mdi-power-settings',
		'mdi-printer-settings',
		'mdi-router-wireless-settings',
		'mdi-settings-helper',
		'mdi-star-cog',
		'mdi-star-cog-outline',
		'mdi-star-settings',
		'mdi-star-settings-outline',
		'mdi-store-cog',
		'mdi-store-cog-outline',
		'mdi-store-settings',
		'mdi-store-settings-outline',
		'mdi-table-cog',
		'mdi-table-settings',
		'mdi-timer-cog',
		'mdi-timer-cog-outline',
		'mdi-timer-settings',
		'mdi-timer-settings-outline',
		'mdi-tune',
		'mdi-tune-variant',
		'mdi-tune-vertical',
		'mdi-tune-vertical-variant',
		'mdi-wifi-cog',
		'mdi-wifi-settings',
		'mdi-window-shutter-cog',
		'mdi-window-shutter-settings',
		'mdi-wrench-cog',
		'mdi-wrench-cog-outline',
	],
	science: [
		'mdi-atom',
		'mdi-atom-variant',
		'mdi-aurora',
		'mdi-bacteria',
		'mdi-bacteria-outline',
		'mdi-beaker',
		'mdi-beaker-alert',
		'mdi-beaker-alert-outline',
		'mdi-beaker-check',
		'mdi-beaker-check-outline',
		'mdi-beaker-minus',
		'mdi-beaker-minus-outline',
		'mdi-beaker-outline',
		'mdi-beaker-plus',
		'mdi-beaker-plus-outline',
		'mdi-beaker-question',
		'mdi-beaker-question-outline',
		'mdi-beaker-remove',
		'mdi-beaker-remove-outline',
		'mdi-biohazard',
		'mdi-bottle-tonic',
		'mdi-bottle-tonic-outline',
		'mdi-dna',
		'mdi-eyedropper',
		'mdi-eyedropper-minus',
		'mdi-eyedropper-off',
		'mdi-eyedropper-plus',
		'mdi-eyedropper-remove',
		'mdi-eyedropper-variant',
		'mdi-flask',
		'mdi-flask-empty',
		'mdi-flask-empty-minus',
		'mdi-flask-empty-minus-outline',
		'mdi-flask-empty-outline',
		'mdi-flask-empty-plus',
		'mdi-flask-empty-plus-outline',
		'mdi-flask-empty-remove',
		'mdi-flask-empty-remove-outline',
		'mdi-flask-minus',
		'mdi-flask-minus-outline',
		'mdi-flask-outline',
		'mdi-flask-plus',
		'mdi-flask-plus-outline',
		'mdi-flask-remove',
		'mdi-flask-remove-outline',
		'mdi-flask-round-bottom',
		'mdi-flask-round-bottom-empty',
		'mdi-flask-round-bottom-empty-outline',
		'mdi-flask-round-bottom-outline',
		'mdi-microscope',
		'mdi-molecule',
		'mdi-molecule-co',
		'mdi-molecule-co2',
		'mdi-orbit',
		'mdi-periodic-table',
		'mdi-ph',
		'mdi-radioactive',
		'mdi-radioactive-circle',
		'mdi-radioactive-circle-outline',
		'mdi-radioactive-off',
		'mdi-rocket',
		'mdi-rocket-launch',
		'mdi-rocket-launch-outline',
		'mdi-rocket-outline',
		'mdi-safety-goggles',
		'mdi-scale',
		'mdi-scale-balance',
		'mdi-scale-off',
		'mdi-telescope',
		'mdi-test-tube',
		'mdi-test-tube-empty',
		'mdi-test-tube-off',
		'mdi-virus',
		'mdi-virus-off',
		'mdi-virus-off-outline',
		'mdi-virus-outline',
	],
	religion: [
		'mdi-mosque',
		'mdi-mosque-outline',
	],
	printer: [
		'mdi-cloud-print',
		'mdi-cloud-print-outline',
		'mdi-fax',
		'mdi-paper-roll',
		'mdi-paper-roll-outline',
		'mdi-printer',
		'mdi-printer-3d',
		'mdi-printer-3d-nozzle',
		'mdi-printer-3d-nozzle-alert',
		'mdi-printer-3d-nozzle-alert-outline',
		'mdi-printer-3d-nozzle-heat',
		'mdi-printer-3d-nozzle-heat-outline',
		'mdi-printer-3d-nozzle-off',
		'mdi-printer-3d-nozzle-off-outline',
		'mdi-printer-3d-nozzle-outline',
		'mdi-printer-3d-off',
		'mdi-printer-alert',
		'mdi-printer-check',
		'mdi-printer-eye',
		'mdi-printer-off',
		'mdi-printer-off-outline',
		'mdi-printer-outline',
		'mdi-printer-pos',
		'mdi-printer-pos-alert',
		'mdi-printer-pos-alert-outline',
		'mdi-printer-pos-cancel',
		'mdi-printer-pos-cancel-outline',
		'mdi-printer-pos-check',
		'mdi-printer-pos-check-outline',
		'mdi-printer-pos-cog',
		'mdi-printer-pos-cog-outline',
		'mdi-printer-pos-edit',
		'mdi-printer-pos-edit-outline',
		'mdi-printer-pos-minus',
		'mdi-printer-pos-minus-outline',
		'mdi-printer-pos-network',
		'mdi-printer-pos-network-outline',
		'mdi-printer-pos-off',
		'mdi-printer-pos-off-outline',
		'mdi-printer-pos-outline',
		'mdi-printer-pos-pause',
		'mdi-printer-pos-pause-outline',
		'mdi-printer-pos-play',
		'mdi-printer-pos-play-outline',
		'mdi-printer-pos-plus',
		'mdi-printer-pos-plus-outline',
		'mdi-printer-pos-refresh',
		'mdi-printer-pos-refresh-outline',
		'mdi-printer-pos-remove',
		'mdi-printer-pos-remove-outline',
		'mdi-printer-pos-star',
		'mdi-printer-pos-star-outline',
		'mdi-printer-pos-stop',
		'mdi-printer-pos-stop-outline',
		'mdi-printer-pos-sync',
		'mdi-printer-pos-sync-outline',
		'mdi-printer-pos-wrench',
		'mdi-printer-pos-wrench-outline',
		'mdi-printer-search',
		'mdi-printer-settings',
		'mdi-printer-wireless',
	],
	places: [
		'mdi-airport',
		'mdi-bank',
		'mdi-beach',
		'mdi-bridge',
		'mdi-car-wash',
		'mdi-castle',
		'mdi-city',
		'mdi-domain',
		'mdi-eiffel-tower',
		'mdi-ev-station',
		'mdi-factory',
		'mdi-gas-station',
		'mdi-home',
		'mdi-home-outline',
		'mdi-hospital-building',
		'mdi-island',
		'mdi-library',
		'mdi-library-outline',
		'mdi-mosque-outline',
		'mdi-office-building',
		'mdi-office-building-cog',
		'mdi-office-building-cog-outline',
		'mdi-office-building-marker',
		'mdi-office-building-marker-outline',
		'mdi-office-building-outline',
		'mdi-parking',
		'mdi-pier',
		'mdi-pier-crane',
		'mdi-pine-tree',
		'mdi-pizza',
		'mdi-police-station',
		'mdi-pool',
		'mdi-silverware-variant',
		'mdi-stadium',
		'mdi-stadium-outline',
		'mdi-stadium-variant',
		'mdi-store',
		'mdi-store-24-hour',
		'mdi-store-alert',
		'mdi-store-alert-outline',
		'mdi-store-check',
		'mdi-store-check-outline',
		'mdi-store-clock',
		'mdi-store-clock-outline',
		'mdi-store-cog',
		'mdi-store-cog-outline',
		'mdi-store-edit',
		'mdi-store-edit-outline',
		'mdi-store-marker',
		'mdi-store-marker-outline',
		'mdi-store-minus',
		'mdi-store-minus-outline',
		'mdi-store-off',
		'mdi-store-off-outline',
		'mdi-store-outline',
		'mdi-store-plus',
		'mdi-store-plus-outline',
		'mdi-store-remove',
		'mdi-store-remove-outline',
		'mdi-store-search',
		'mdi-store-search-outline',
		'mdi-store-settings',
		'mdi-store-settings-outline',
		'mdi-storefront',
		'mdi-storefront-outline',
		'mdi-synagogue',
		'mdi-synagogue-outline',
		'mdi-temple-buddhist',
		'mdi-temple-buddhist-outline',
		'mdi-theater',
		'mdi-town-hall',
		'mdi-warehouse',
	],
	photography: [
		'mdi-auto-fix',
		'mdi-camera',
		'mdi-camera-account',
		'mdi-camera-burst',
		'mdi-camera-control',
		'mdi-camera-document',
		'mdi-camera-document-off',
		'mdi-camera-enhance',
		'mdi-camera-enhance-outline',
		'mdi-camera-flip',
		'mdi-camera-flip-outline',
		'mdi-camera-front',
		'mdi-camera-front-variant',
		'mdi-camera-gopro',
		'mdi-camera-image',
		'mdi-camera-iris',
		'mdi-camera-lock',
		'mdi-camera-lock-open',
		'mdi-camera-lock-open-outline',
		'mdi-camera-lock-outline',
		'mdi-camera-marker',
		'mdi-camera-marker-outline',
		'mdi-camera-metering-center',
		'mdi-camera-metering-matrix',
		'mdi-camera-metering-partial',
		'mdi-camera-metering-spot',
		'mdi-camera-off',
		'mdi-camera-off-outline',
		'mdi-camera-outline',
		'mdi-camera-party-mode',
		'mdi-camera-plus',
		'mdi-camera-plus-outline',
		'mdi-camera-rear',
		'mdi-camera-rear-variant',
		'mdi-camera-retake',
		'mdi-camera-retake-outline',
		'mdi-camera-switch',
		'mdi-camera-switch-outline',
		'mdi-camera-timer',
		'mdi-camera-wireless',
		'mdi-camera-wireless-outline',
		'mdi-face-man-shimmer',
		'mdi-face-man-shimmer-outline',
		'mdi-face-recognition',
		'mdi-face-woman-shimmer',
		'mdi-face-woman-shimmer-outline',
		'mdi-film',
		'mdi-focus-auto',
		'mdi-focus-field',
		'mdi-focus-field-horizontal',
		'mdi-focus-field-vertical',
		'mdi-grain',
		'mdi-image-auto-adjust',
		'mdi-image-filter-black-white',
		'mdi-image-filter-center-focus',
		'mdi-image-filter-center-focus-strong',
		'mdi-image-filter-center-focus-strong-outline',
		'mdi-image-filter-center-focus-weak',
		'mdi-image-filter-drama',
		'mdi-image-filter-drama-outline',
		'mdi-image-filter-frames',
		'mdi-image-filter-hdr',
		'mdi-image-filter-none',
		'mdi-image-filter-tilt-shift',
		'mdi-image-filter-vintage',
		'mdi-image-lock',
		'mdi-image-lock-outline',
		'mdi-image-multiple-outline',
		'mdi-image-refresh',
		'mdi-image-refresh-outline',
		'mdi-image-sync',
		'mdi-image-sync-outline',
		'mdi-multimedia',
		'mdi-orbit-variant',
		'mdi-panorama',
		'mdi-panorama-horizontal',
		'mdi-panorama-horizontal-outline',
		'mdi-panorama-outline',
		'mdi-panorama-sphere',
		'mdi-panorama-sphere-outline',
		'mdi-panorama-variant',
		'mdi-panorama-variant-outline',
		'mdi-panorama-vertical',
		'mdi-panorama-vertical-outline',
		'mdi-panorama-wide-angle',
		'mdi-panorama-wide-angle-outline',
		'mdi-raw',
		'mdi-raw-off',
		'mdi-white-balance-auto',
		'mdi-white-balance-incandescent',
		'mdi-white-balance-iridescent',
		'mdi-white-balance-sunny',
	],
	people: [
		'mdi-mdi-account-tie',
		'mdi-account-tie-woman',
		'mdi-baby',
		'mdi-baby-bottle',
		'mdi-baby-bottle-outline',
		'mdi-baby-buggy',
		'mdi-baby-buggy-off',
		'mdi-baby-carriage',
		'mdi-baby-carriage-off',
		'mdi-baby-face',
		'mdi-baby-face-outline',
		'mdi-biathlon',
		'mdi-car-child-seat',
		'mdi-cradle',
		'mdi-cradle-outline',
		'mdi-crowd',
		'mdi-dance-ballroom',
		'mdi-dance-pole',
		'mdi-diving',
		'mdi-face-man',
		'mdi-face-man-outline',
		'mdi-face-man-profile',
		'mdi-face-man-shimmer',
		'mdi-face-man-shimmer-outline',
		'mdi-face-woman',
		'mdi-face-woman-outline',
		'mdi-face-woman-profile',
		'mdi-face-woman-shimmer',
		'mdi-face-woman-shimmer-outline',
		'mdi-family-tree',
		'mdi-handball',
		'mdi-hiking',
		'mdi-home-heart',
		'mdi-horse-human',
		'mdi-human',
		'mdi-human-baby-changing-table',
		'mdi-human-cane',
		'mdi-human-capacity-decrease',
		'mdi-human-capacity-increase',
		'mdi-human-child',
		'mdi-human-dolly',
		'mdi-human-edit',
		'mdi-human-female',
		'mdi-human-female-boy',
		'mdi-human-female-dance',
		'mdi-human-female-female',
		'mdi-human-female-girl',
		'mdi-human-greeting',
		'mdi-human-greeting-proximity',
		'mdi-human-greeting-variant',
		'mdi-human-handsdown',
		'mdi-human-handsup',
		'mdi-human-male',
		'mdi-human-male-board',
		'mdi-human-male-board-poll',
		'mdi-human-male-boy',
		'mdi-human-male-child',
		'mdi-human-male-female',
		'mdi-human-male-female-child',
		'mdi-human-male-girl',
		'mdi-human-male-height',
		'mdi-human-male-height-variant',
		'mdi-human-male-male',
		'mdi-human-non-binary',
		'mdi-human-pregnant',
		'mdi-human-queue',
		'mdi-human-scooter',
		'mdi-human-walker',
		'mdi-human-wheelchair',
		'mdi-human-white-cane',
		'mdi-kabaddi',
		'mdi-karate',
		'mdi-kayaking',
		'mdi-meditation',
		'mdi-mother-heart',
		'mdi-mother-nurse',
		'mdi-rowing',
		'mdi-run',
		'mdi-run-fast',
		'mdi-skateboarding',
		'mdi-ski',
		'mdi-ski-cross-country',
		'mdi-ski-water',
		'mdi-sledding',
		'mdi-snowboard',
		'mdi-walk',
		'mdi-weight-lifter',
		'mdi-wheelchair',
	],
	notification: [
		'mdi-alarm-bell',
		'mdi-bell',
		'mdi-bell-alert',
		'mdi-bell-alert-outline',
		'mdi-bell-badge',
		'mdi-bell-badge-outline',
		'mdi-bell-cancel',
		'mdi-bell-cancel-outline',
		'mdi-bell-check',
		'mdi-bell-check-outline',
		'mdi-bell-circle',
		'mdi-bell-circle-outline',
		'mdi-bell-cog',
		'mdi-bell-cog-outline',
		'mdi-bell-minus',
		'mdi-bell-minus-outline',
		'mdi-bell-off',
		'mdi-bell-off-outline',
		'mdi-bell-outline',
		'mdi-bell-plus',
		'mdi-bell-plus-outline',
		'mdi-bell-remove',
		'mdi-bell-remove-outline',
		'mdi-bell-ring',
		'mdi-bell-ring-outline',
		'mdi-bell-sleep',
		'mdi-bell-sleep-outline',
		'mdi-checkbox-blank-badge',
		'mdi-checkbox-blank-badge-outline',
		'mdi-message-badge',
		'mdi-message-badge-outline',
		'mdi-notification-clear-all',
		'mdi-square-rounded-badge',
		'mdi-square-rounded-badge-outline',
	],
	navigation: [
		'mdi-airplane',
		'mdi-airplane-marker',
		'mdi-archive-marker',
		'mdi-archive-marker-outline',
		'mdi-book-marker',
		'mdi-book-marker-outline',
		'mdi-bus',
		'mdi-bus-marker',
		'mdi-bus-stop',
		'mdi-bus-stop-covered',
		'mdi-bus-stop-uncovered',
		'mdi-camera-marker',
		'mdi-camera-marker-outline',
		'mdi-car',
		'mdi-cash-marker',
		'mdi-cellphone-marker',
		'mdi-compass',
		'mdi-compass-off',
		'mdi-compass-off-outline',
		'mdi-compass-outline',
		'mdi-compass-rose',
		'mdi-credit-card-marker',
		'mdi-credit-card-marker-outline',
		'mdi-crosshairs-gps',
		'mdi-crosshairs-question',
		'mdi-database-marker',
		'mdi-database-marker-outline',
		'mdi-earth',
		'mdi-earth-arrow-right',
		'mdi-earth-box',
		'mdi-earth-box-minus',
		'mdi-earth-box-off',
		'mdi-earth-box-plus',
		'mdi-earth-box-remove',
		'mdi-earth-minus',
		'mdi-earth-off',
		'mdi-earth-plus',
		'mdi-earth-remove',
		'mdi-ferry',
		'mdi-file-image-marker',
		'mdi-file-image-marker-outline',
		'mdi-file-marker',
		'mdi-file-marker-outline',
		'mdi-folder-marker',
		'mdi-folder-marker-outline',
		'mdi-google-maps',
		'mdi-hail',
		'mdi-home-map-marker',
		'mdi-hospital-marker',
		'mdi-image-marker',
		'mdi-image-marker-outline',
		'mdi-latitude',
		'mdi-longitude',
		'mdi-map',
		'mdi-map-check',
		'mdi-map-check-outline',
		'mdi-map-clock',
		'mdi-map-clock-outline',
		'mdi-map-legend',
		'mdi-map-marker',
		'mdi-map-marker-account',
		'mdi-map-marker-account-outline',
		'mdi-map-marker-alert',
		'mdi-map-marker-alert-outline',
		'mdi-map-marker-check',
		'mdi-map-marker-check-outline',
		'mdi-map-marker-circle',
		'mdi-map-marker-distance',
		'mdi-map-marker-down',
		'mdi-map-marker-left',
		'mdi-map-marker-left-outline',
		'mdi-map-marker-minus',
		'mdi-map-marker-minus-outline',
		'mdi-map-marker-multiple',
		'mdi-map-marker-multiple-outline',
		'mdi-map-marker-off',
		'mdi-map-marker-off-outline',
		'mdi-map-marker-outline',
		'mdi-map-marker-path',
		'mdi-map-marker-plus',
		'mdi-map-marker-plus-outline',
		'mdi-map-marker-question',
		'mdi-map-marker-question-outline',
		'mdi-map-marker-radius',
		'mdi-map-marker-radius-outline',
		'mdi-map-marker-remove',
		'mdi-map-marker-remove-outline',
		'mdi-map-marker-remove-variant',
		'mdi-map-marker-right',
		'mdi-map-marker-right-outline',
		'mdi-map-marker-star',
		'mdi-map-marker-star-outline',
		'mdi-map-marker-up',
		'mdi-map-minus',
		'mdi-map-outline',
		'mdi-map-plus',
		'mdi-map-search',
		'mdi-map-search-outline',
		'mdi-math-compass',
		'mdi-navigation',
		'mdi-navigation-outline',
		'mdi-navigation-variant',
		'mdi-navigation-variant-outline',
		'mdi-office-building-marker',
		'mdi-office-building-marker-outline',
		'mdi-select-marker',
		'mdi-select-multiple-marker',
		'mdi-selection-marker',
		'mdi-selection-multiple-marker',
		'mdi-store-marker',
		'mdi-store-marker-outline',
		'mdi-sun-compass',
		'mdi-taxi',
		'mdi-timer-marker',
		'mdi-timer-marker-outline',
		'mdi-toy-brick-marker',
		'mdi-toy-brick-marker-outline',
		'mdi-train',
		'mdi-tram',
		'mdi-transit-connection',
		'mdi-transit-connection-variant',
		'mdi-transit-detour',
		'mdi-transit-transfer',
		'mdi-video-marker',
		'mdi-video-marker-outline',
		'mdi-wifi-marker',
	],
	nature: [
		'mdi-bee',
		'mdi-bee-flower',
		'mdi-beehive-off-outline',
		'mdi-beehive-outline',
		'mdi-beekeeper',
		'mdi-bug',
		'mdi-bug-outline',
		'mdi-butterfly',
		'mdi-butterfly-outline',
		'mdi-cactus',
		'mdi-cannabis',
		'mdi-cloud-percent',
		'mdi-cloud-percent-outline',
		'mdi-clover',
		'mdi-compost',
		'mdi-feather',
		'mdi-flower',
		'mdi-flower-outline',
		'mdi-flower-pollen',
		'mdi-flower-pollen-outline',
		'mdi-flower-poppy',
		'mdi-flower-tulip',
		'mdi-flower-tulip-outline',
		'mdi-forest',
		'mdi-grass',
		'mdi-greenhouse',
		'mdi-hexagon-multiple-outline',
		'mdi-home-flood',
		'mdi-image-filter-drama',
		'mdi-image-filter-drama-outline',
		'mdi-image-filter-hdr',
		'mdi-image-filter-vintage',
		'mdi-ladybug',
		'mdi-landslide',
		'mdi-landslide-outline',
		'mdi-leaf',
		'mdi-leaf-circle',
		'mdi-leaf-circle-outline',
		'mdi-leaf-maple',
		'mdi-leaf-maple-off',
		'mdi-leaf-off',
		'mdi-mushroom',
		'mdi-mushroom-off',
		'mdi-mushroom-off-outline',
		'mdi-mushroom-outline',
		'mdi-nature',
		'mdi-palm-tree',
		'mdi-paw',
		'mdi-pine-tree',
		'mdi-pine-tree-box',
		'mdi-pine-tree-fire',
		'mdi-rabbit',
		'mdi-rabbit-variant',
		'mdi-rabbit-variant-outline',
		'mdi-seed',
		'mdi-seed-off',
		'mdi-seed-off-outline',
		'mdi-seed-outline',
		'mdi-seed-plus',
		'mdi-seed-plus-outline',
		'mdi-spa',
		'mdi-spa-outline',
		'mdi-spider',
		'mdi-spider-thread',
		'mdi-sprout',
		'mdi-sprout-outline',
		'mdi-terrain',
		'mdi-tree',
		'mdi-tree-outline',
		'mdi-tsunami',
		'mdi-volcano',
		'mdi-volcano-outline',
		'mdi-water-percent',
		'mdi-water-percent-alert',
		'mdi-waterfall',
		'mdi-waves-arrow-left',
		'mdi-waves-arrow-right',
		'mdi-waves-arrow-up',
		'mdi-weather-hurricane',
	],
	medical: [
		'mdi-account-heart',
		'mdi-account-heart-outline',
		'mdi-account-injury',
		'mdi-account-injury-outline',
		'mdi-allergy',
		'mdi-ambulance',
		'mdi-bacteria',
		'mdi-bacteria-outline',
		'mdi-blood-bag',
		'mdi-brain',
		'mdi-cannabis',
		'mdi-clipboard-pulse',
		'mdi-clipboard-pulse-outline',
		'mdi-diabetes',
		'mdi-doctor',
		'mdi-ear-hearing',
		'mdi-ear-hearing-loop',
		'mdi-ear-hearing-off',
		'mdi-emoticon-sick',
		'mdi-emoticon-sick-outline',
		'mdi-face-mask',
		'mdi-face-mask-outline',
		'mdi-hand-wash',
		'mdi-hand-wash-outline',
		'mdi-hand-water',
		'mdi-heart',
		'mdi-heart-flash',
		'mdi-heart-off',
		'mdi-heart-off-outline',
		'mdi-heart-outline',
		'mdi-heart-pulse',
		'mdi-hospital',
		'mdi-hospital-box',
		'mdi-hospital-box-outline',
		'mdi-hospital-building',
		'mdi-hospital-marker',
		'mdi-human-baby-changing-table',
		'mdi-human-cane',
		'mdi-human-male-height',
		'mdi-human-male-height-variant',
		'mdi-human-walker',
		'mdi-human-wheelchair',
		'mdi-human-white-cane',
		'mdi-iv-bag',
		'mdi-liquid-spot',
		'mdi-lotion',
		'mdi-lotion-outline',
		'mdi-lotion-plus',
		'mdi-lotion-plus-outline',
		'mdi-lungs',
		'mdi-medical-bag',
		'mdi-medical-cotton-swab',
		'mdi-medication',
		'mdi-medication-outline',
		'mdi-minus-circle',
		'mdi-minus-circle-outline',
		'mdi-mortar-pestle-plus',
		'mdi-mother-nurse',
		'mdi-needle',
		'mdi-needle-off',
		'mdi-pill',
		'mdi-pill-multiple',
		'mdi-pill-off',
		'mdi-prescription',
		'mdi-pulse',
		'mdi-radiology-box',
		'mdi-radiology-box-outline',
		'mdi-reproduction',
		'mdi-scale-bathroom',
		'mdi-skull-scan',
		'mdi-skull-scan-outline',
		'mdi-social-distance-2-meters',
		'mdi-social-distance-6-feet',
		'mdi-stethoscope',
		'mdi-stomach',
		'mdi-tooth',
		'mdi-tooth-outline',
		'mdi-toothbrush',
		'mdi-toothbrush-electric',
		'mdi-toothbrush-paste',
		'mdi-truck-plus',
		'mdi-truck-plus-outline',
		'mdi-virus',
		'mdi-virus-outline',
		'mdi-wheelchair',
		'mdi-wheelchair-accessibility',
	],
	maths:[ 
		'mdi-abacus',
		'mdi-angle-acute',
		'mdi-angle-obtuse',
		'mdi-angle-right',
		'mdi-approximately-equal',
		'mdi-approximately-equal-box',
		'mdi-calculator',
		'mdi-calculator-variant',
		'mdi-calculator-variant-outline',
		'mdi-chart-arc',
		'mdi-chart-areaspline',
		'mdi-chart-areaspline-variant',
		'mdi-chart-bar',
		'mdi-chart-bar-stacked',
		'mdi-chart-bell-curve',
		'mdi-chart-bell-curve-cumulative',
		'mdi-chart-box',
		'mdi-chart-box-outline',
		'mdi-chart-box-plus-outline',
		'mdi-chart-bubble',
		'mdi-chart-donut',
		'mdi-chart-donut-variant',
		'mdi-chart-gantt',
		'mdi-chart-histogram',
		'mdi-chart-line',
		'mdi-chart-line-stacked',
		'mdi-chart-line-variant',
		'mdi-chart-multiline',
		'mdi-chart-multiple',
		'mdi-chart-pie',
		'mdi-chart-pie-outline',
		'mdi-chart-ppf',
		'mdi-chart-sankey',
		'mdi-chart-sankey-variant',
		'mdi-chart-scatter-plot',
		'mdi-chart-scatter-plot-hexbin',
		'mdi-chart-timeline',
		'mdi-chart-timeline-variant',
		'mdi-chart-timeline-variant-shimmer',
		'mdi-chart-tree',
		'mdi-chart-waterfall',
		'mdi-chevron-up',
		'mdi-circle-small',
		'mdi-close',
		'mdi-close-box',
		'mdi-close-box-outline',
		'mdi-code-braces',
		'mdi-code-brackets',
		'mdi-code-greater-than',
		'mdi-code-greater-than-or-equal',
		'mdi-code-less-than',
		'mdi-code-less-than-or-equal',
		'mdi-decimal',
		'mdi-decimal-comma',
		'mdi-decimal-comma-decrease',
		'mdi-decimal-comma-increase',
		'mdi-decimal-decrease',
		'mdi-decimal-increase',
		'mdi-delta',
		'mdi-diameter',
		'mdi-diameter-outline',
		'mdi-diameter-variant',
		'mdi-division',
		'mdi-division-box',
		'mdi-equal',
		'mdi-equal-box',
		'mdi-exclamation',
		'mdi-exponent',
		'mdi-exponent-box',
		'mdi-finance',
		'mdi-format-superscript',
		'mdi-function',
		'mdi-function-variant',
		'mdi-greater-than',
		'mdi-greater-than-or-equal',
		'mdi-infinity',
		'mdi-lambda',
		'mdi-less-than',
		'mdi-less-than-or-equal',
		'mdi-math-compass',
		'mdi-math-cos',
		'mdi-math-integral',
		'mdi-math-integral-box',
		'mdi-math-log',
		'mdi-math-norm',
		'mdi-math-norm-box',
		'mdi-math-sin',
		'mdi-math-tan',
		'mdi-minus',
		'mdi-minus-box',
		'mdi-minus-box-outline',
		'mdi-multiplication',
		'mdi-multiplication-box',
		'mdi-not-equal-variant',
		'mdi-percent',
		'mdi-percent-box',
		'mdi-percent-box-outline',
		'mdi-percent-circle',
		'mdi-percent-circle-outline',
		'mdi-percent-outline',
		'mdi-perspective-less',
		'mdi-perspective-more',
		'mdi-pi',
		'mdi-pi-box',
		'mdi-plus',
		'mdi-plus-box',
		'mdi-plus-box-outline',
		'mdi-plus-minus',
		'mdi-plus-minus-box',
		'mdi-plus-minus-variant',
		'mdi-plus-thick',
		'mdi-radius',
		'mdi-radius-outline',
		'mdi-sigma',
		'mdi-skew-less',
		'mdi-skew-more',
		'mdi-slash-forward',
		'mdi-slash-forward-box',
		'mdi-square-root',
		'mdi-tally-mark-1',
		'mdi-tally-mark-2',
		'mdi-tally-mark-3',
		'mdi-tally-mark-4',
		'mdi-tally-mark-5',
		'mdi-texture-box',
		'mdi-variable',
	],
	lock:[ 
		'mdi-account-lock',
		'mdi-account-lock-open',
		'mdi-account-lock-open-outline',
		'mdi-account-lock-outline',
		'mdi-archive-lock',
		'mdi-archive-lock-open',
		'mdi-archive-lock-open-outline',
		'mdi-archive-lock-outline',
		'mdi-arrow-horizontal-lock',
		'mdi-arrow-vertical-lock',
		'mdi-attachment-lock',
		'mdi-axis-arrow-lock',
		'mdi-axis-lock',
		'mdi-axis-x-arrow-lock',
		'mdi-axis-x-y-arrow-lock',
		'mdi-axis-y-arrow-lock',
		'mdi-axis-z-arrow-lock',
		'mdi-battery-lock',
		'mdi-battery-lock-open',
		'mdi-book-lock',
		'mdi-book-lock-open',
		'mdi-book-lock-open-outline',
		'mdi-book-lock-outline',
		'mdi-calendar-lock',
		'mdi-calendar-lock-open',
		'mdi-calendar-lock-open-outline',
		'mdi-calendar-lock-outline',
		'mdi-camera-lock',
		'mdi-camera-lock-outline',
		'mdi-car-door-lock',
		'mdi-cash-lock',
		'mdi-cash-lock-open',
		'mdi-cellphone-lock',
		'mdi-cloud-lock',
		'mdi-cloud-lock-outline',
		'mdi-cookie-lock',
		'mdi-cookie-lock-outline',
		'mdi-credit-card-lock',
		'mdi-credit-card-lock-outline',
		'mdi-database-lock',
		'mdi-database-lock-outline',
		'mdi-door-closed-lock',
		'mdi-door-sliding-lock',
		'mdi-download-lock',
		'mdi-download-lock-outline',
		'mdi-email-lock',
		'mdi-email-lock-outline',
		'mdi-file-lock',
		'mdi-file-lock-open',
		'mdi-file-lock-open-outline',
		'mdi-file-lock-outline',
		'mdi-folder-lock',
		'mdi-folder-lock-open',
		'mdi-folder-lock-open-outline',
		'mdi-folder-lock-outline',
		'mdi-form-textbox-lock',
		'mdi-garage-lock',
		'mdi-garage-variant-lock',
		'mdi-home-lock',
		'mdi-home-lock-open',
		'mdi-image-lock',
		'mdi-image-lock-outline',
		'mdi-link-lock',
		'mdi-lock',
		'mdi-lock-alert',
		'mdi-lock-alert-outline',
		'mdi-lock-check',
		'mdi-lock-check-outline',
		'mdi-lock-clock',
		'mdi-lock-minus',
		'mdi-lock-minus-outline',
		'mdi-lock-off',
		'mdi-lock-off-outline',
		'mdi-lock-open',
		'mdi-lock-open-alert',
		'mdi-lock-open-alert-outline',
		'mdi-lock-open-check',
		'mdi-lock-open-check-outline',
		'mdi-lock-open-minus',
		'mdi-lock-open-minus-outline',
		'mdi-lock-open-outline',
		'mdi-lock-open-plus',
		'mdi-lock-open-plus-outline',
		'mdi-lock-open-remove',
		'mdi-lock-open-remove-outline',
		'mdi-lock-open-variant',
		'mdi-lock-open-variant-outline',
		'mdi-lock-outline',
		'mdi-lock-plus',
		'mdi-lock-plus-outline',
		'mdi-lock-question',
		'mdi-lock-remove',
		'mdi-lock-remove-outline',
		'mdi-lock-reset',
		'mdi-message-lock',
		'mdi-message-lock-outline',
		'mdi-message-text-lock',
		'mdi-message-text-lock-outline',
		'mdi-monitor-lock',
		'mdi-paperclip-lock',
		'mdi-pen-lock',
		'mdi-pencil-lock',
		'mdi-pencil-lock-outline',
		'mdi-phone-lock',
		'mdi-phone-lock-outline',
		'mdi-play-box-lock',
		'mdi-play-box-lock-open',
		'mdi-play-box-lock-open-outline',
		'mdi-play-box-lock-outline',
		'mdi-plus-lock',
		'mdi-plus-lock-open',
		'mdi-screen-rotation-lock',
		'mdi-send-lock',
		'mdi-send-lock-outline',
		'mdi-shield-lock',
		'mdi-shield-lock-open',
		'mdi-shield-lock-open-outline',
		'mdi-shield-lock-outline',
		'mdi-sort-variant-lock',
		'mdi-sort-variant-lock-open',
		'mdi-table-lock',
		'mdi-timer-lock',
		'mdi-timer-lock-open',
		'mdi-timer-lock-open-outline',
		'mdi-timer-lock-outline',
		'mdi-trackpad-lock',
		'mdi-upload-lock',
		'mdi-upload-lock-outline',
		'mdi-wifi-lock',
		'mdi-wifi-lock-open',
		'mdi-wifi-strength-1-lock',
		'mdi-wifi-strength-1-lock-open',
		'mdi-wifi-strength-2-lock',
		'mdi-wifi-strength-2-lock-open',
		'mdi-wifi-strength-3-lock',
		'mdi-wifi-strength-3-lock-open',
		'mdi-wifi-strength-4-lock',
		'mdi-wifi-strength-4-lock-open',
		'mdi-wifi-strength-lock-open-outline',
		'mdi-wifi-strength-lock-outline',
	],
	'home automation': [
		'mdi-account',
		'mdi-account-group',
		'mdi-account-plus',
		'mdi-air-conditioner',
		'mdi-air-filter',
		'mdi-air-humidifier',
		'mdi-air-humidifier-off',
		'mdi-air-purifier',
		'mdi-air-purifier-off',
		'mdi-alarm-light',
		'mdi-alarm-light-off',
		'mdi-alarm-light-off-outline',
		'mdi-alarm-light-outline',
		'mdi-alarm-panel',
		'mdi-alarm-panel-outline',
		'mdi-amplifier',
		'mdi-audio-video',
		'mdi-audio-video-off',
		'mdi-awning',
		'mdi-awning-outline',
		'mdi-balcony',
		'mdi-bathtub',
		'mdi-bathtub-outline',
		'mdi-battery',
		'mdi-battery-10',
		'mdi-battery-20',
		'mdi-battery-30',
		'mdi-battery-40',
		'mdi-battery-50',
		'mdi-battery-60',
		'mdi-battery-70',
		'mdi-battery-80',
		'mdi-battery-90',
		'mdi-battery-alert',
		'mdi-battery-charging',
		'mdi-battery-charging-100',
		'mdi-battery-charging-20',
		'mdi-battery-charging-30',
		'mdi-battery-charging-40',
		'mdi-battery-charging-60',
		'mdi-battery-charging-80',
		'mdi-battery-charging-90',
		'mdi-battery-charging-wireless',
		'mdi-battery-charging-wireless-10',
		'mdi-battery-charging-wireless-20',
		'mdi-battery-charging-wireless-30',
		'mdi-battery-charging-wireless-40',
		'mdi-battery-charging-wireless-50',
		'mdi-battery-charging-wireless-60',
		'mdi-battery-charging-wireless-70',
		'mdi-battery-charging-wireless-80',
		'mdi-battery-charging-wireless-90',
		'mdi-battery-charging-wireless-alert',
		'mdi-battery-charging-wireless-outline',
		'mdi-battery-clock',
		'mdi-battery-clock-outline',
		'mdi-battery-minus-variant',
		'mdi-battery-negative',
		'mdi-battery-outline',
		'mdi-battery-plus-variant',
		'mdi-battery-positive',
		'mdi-battery-unknown',
		'mdi-bed',
		'mdi-bed-double',
		'mdi-bed-double-outline',
		'mdi-bed-empty',
		'mdi-bed-king',
		'mdi-bed-king-outline',
		'mdi-bed-outline',
		'mdi-bed-queen',
		'mdi-bed-queen-outline',
		'mdi-bed-single',
		'mdi-bed-single-outline',
		'mdi-bell',
		'mdi-bell-outline',
		'mdi-blender',
		'mdi-blender-outline',
		'mdi-blinds',
		'mdi-blinds-horizontal',
		'mdi-blinds-horizontal-closed',
		'mdi-blinds-open',
		'mdi-blinds-vertical',
		'mdi-blinds-vertical-closed',
		'mdi-boom-gate',
		'mdi-boom-gate-outline',
		'mdi-boom-gate-up',
		'mdi-boom-gate-up-outline',
		'mdi-boombox',
		'mdi-brightness-7',
		'mdi-buffet',
		'mdi-bulkhead-light',
		'mdi-bunk-bed',
		'mdi-bunk-bed-outline',
		'mdi-cabin-a-frame',
		'mdi-camera',
		'mdi-candelabra',
		'mdi-candelabra-fire',
		'mdi-candle',
		'mdi-caravan',
		'mdi-cast',
		'mdi-cast-connected',
		'mdi-cast-off',
		'mdi-cast-variant',
		'mdi-cctv',
		'mdi-cctv-off',
		'mdi-ceiling-fan',
		'mdi-ceiling-fan-light',
		'mdi-ceiling-light',
		'mdi-ceiling-light-multiple',
		'mdi-ceiling-light-multiple-outline',
		'mdi-ceiling-light-outline',
		'mdi-chair-rolling',
		'mdi-chandelier',
		'mdi-clock-digital',
		'mdi-cloud-print',
		'mdi-cloud-print-outline',
		'mdi-coach-lamp',
		'mdi-coach-lamp-variant',
		'mdi-coat-rack',
		'mdi-coffee-maker',
		'mdi-coffee-maker-check',
		'mdi-coffee-maker-check-outline',
		'mdi-coffee-maker-outline',
		'mdi-connection',
		'mdi-countertop',
		'mdi-countertop-outline',
		'mdi-cradle',
		'mdi-cradle-outline',
		'mdi-cupboard',
		'mdi-cupboard-outline',
		'mdi-curtains',
		'mdi-curtains-closed',
		'mdi-desk-lamp',
		'mdi-desk-lamp-off',
		'mdi-desk-lamp-on',
		'mdi-desktop-classic',
		'mdi-desktop-tower',
		'mdi-devices',
		'mdi-disc-player',
		'mdi-dishwasher',
		'mdi-dishwasher-alert',
		'mdi-dishwasher-off',
		'mdi-dolby',
		'mdi-door',
		'mdi-door-closed',
		'mdi-door-closed-lock',
		'mdi-door-open',
		'mdi-door-sliding',
		'mdi-door-sliding-lock',
		'mdi-door-sliding-open',
		'mdi-doorbell',
		'mdi-doorbell-video',
		'mdi-dresser',
		'mdi-dresser-outline',
		'mdi-exit-run',
		'mdi-fan',
		'mdi-fan-alert',
		'mdi-fan-chevron-down',
		'mdi-fan-chevron-up',
		'mdi-fan-clock',
		'mdi-fan-minus',
		'mdi-fan-off',
		'mdi-fan-plus',
		'mdi-fan-remove',
		'mdi-fan-speed-1',
		'mdi-fan-speed-2',
		'mdi-fan-speed-3',
		'mdi-faucet',
		'mdi-faucet-variant',
		'mdi-fence',
		'mdi-fence-electric',
		'mdi-fire',
		'mdi-fire-alert',
		'mdi-fire-circle',
		'mdi-fire-extinguisher',
		'mdi-fire-off',
		'mdi-fireplace',
		'mdi-fireplace-off',
		'mdi-flash-triangle',
		'mdi-flash-triangle-outline',
		'mdi-floor-lamp',
		'mdi-floor-lamp-dual',
		'mdi-floor-lamp-dual-outline',
		'mdi-floor-lamp-outline',
		'mdi-floor-lamp-torchiere',
		'mdi-floor-lamp-torchiere-outline',
		'mdi-floor-lamp-torchiere-variant',
		'mdi-floor-lamp-torchiere-variant-outline',
		'mdi-floor-plan',
		'mdi-folder-home',
		'mdi-folder-home-outline',
		'mdi-fridge',
		'mdi-fridge-alert',
		'mdi-fridge-alert-outline',
		'mdi-fridge-bottom',
		'mdi-fridge-industrial',
		'mdi-fridge-industrial-alert',
		'mdi-fridge-industrial-alert-outline',
		'mdi-fridge-industrial-off',
		'mdi-fridge-industrial-off-outline',
		'mdi-fridge-industrial-outline',
		'mdi-fridge-off',
		'mdi-fridge-off-outline',
		'mdi-fridge-outline',
		'mdi-fridge-top',
		'mdi-fridge-variant',
		'mdi-fridge-variant-alert',
		'mdi-fridge-variant-alert-outline',
		'mdi-fridge-variant-off',
		'mdi-fridge-variant-off-outline',
		'mdi-fridge-variant-outline',
		'mdi-gamepad',
		'mdi-gamepad-outline',
		'mdi-garage',
		'mdi-garage-alert',
		'mdi-garage-alert-variant',
		'mdi-garage-lock',
		'mdi-garage-open',
		'mdi-garage-open-variant',
		'mdi-garage-variant',
		'mdi-garage-variant-lock',
		'mdi-gas-burner',
		'mdi-gate',
		'mdi-gate-alert',
		'mdi-gate-arrow-left',
		'mdi-gate-arrow-right',
		'mdi-gate-open',
		'mdi-gauge',
		'mdi-gauge-empty',
		'mdi-gauge-full',
		'mdi-gauge-low',
		'mdi-globe-light',
		'mdi-globe-light-outline',
		'mdi-greenhouse',
		'mdi-hanger',
		'mdi-hdmi-port',
		'mdi-heat-pump',
		'mdi-heat-pump-outline',
		'mdi-heat-wave',
		'mdi-heating-coil',
		'mdi-home',
		'mdi-home-account',
		'mdi-home-alert',
		'mdi-home-alert-outline',
		'mdi-home-analytics',
		'mdi-home-assistant',
		'mdi-home-automation',
		'mdi-home-battery',
		'mdi-home-battery-outline',
		'mdi-home-circle',
		'mdi-home-circle-outline',
		'mdi-home-city',
		'mdi-home-city-outline',
		'mdi-home-clock',
		'mdi-home-clock-outline',
		'mdi-home-edit',
		'mdi-home-edit-outline',
		'mdi-home-export-outline',
		'mdi-home-flood',
		'mdi-home-floor-0',
		'mdi-home-floor-1',
		'mdi-home-floor-2',
		'mdi-home-floor-3',
		'mdi-home-floor-a',
		'mdi-home-floor-b',
		'mdi-home-floor-g',
		'mdi-home-floor-l',
		'mdi-home-floor-negative-1',
		'mdi-home-group',
		'mdi-home-group-minus',
		'mdi-home-group-plus',
		'mdi-home-group-remove',
		'mdi-home-heart',
		'mdi-home-import-outline',
		'mdi-home-lightbulb',
		'mdi-home-lightbulb-outline',
		'mdi-home-lightning-bolt',
		'mdi-home-lightning-bolt-outline',
		'mdi-home-lock',
		'mdi-home-lock-open',
		'mdi-home-map-marker',
		'mdi-home-minus',
		'mdi-home-minus-outline',
		'mdi-home-modern',
		'mdi-home-off',
		'mdi-home-off-outline',
		'mdi-home-outline',
		'mdi-home-plus',
		'mdi-home-plus-outline',
		'mdi-home-remove',
		'mdi-home-remove-outline',
		'mdi-home-roof',
		'mdi-home-search',
		'mdi-home-search-outline',
		'mdi-home-silo',
		'mdi-home-silo-outline',
		'mdi-home-switch',
		'mdi-home-switch-outline',
		'mdi-home-thermometer',
		'mdi-home-thermometer-outline',
		'mdi-home-variant',
		'mdi-home-variant-outline',
		'mdi-hoop-house',
		'mdi-hvac',
		'mdi-hvac-off',
		'mdi-image-frame',
		'mdi-induction',
		'mdi-iron',
		'mdi-iron-board',
		'mdi-iron-outline',
		'mdi-kettle',
		'mdi-kettle-alert',
		'mdi-kettle-alert-outline',
		'mdi-kettle-off',
		'mdi-kettle-off-outline',
		'mdi-kettle-outline',
		'mdi-kettle-steam',
		'mdi-kettle-steam-outline',
		'mdi-key-chain',
		'mdi-key-chain-variant',
		'mdi-lamp',
		'mdi-lamp-outline',
		'mdi-lamps',
		'mdi-lamps-outline',
		'mdi-laptop',
		'mdi-lava-lamp',
		'mdi-led-off',
		'mdi-led-on',
		'mdi-led-outline',
		'mdi-led-strip',
		'mdi-led-strip-variant',
		'mdi-led-strip-variant-off',
		'mdi-led-variant-off',
		'mdi-led-variant-on',
		'mdi-led-variant-outline',
		'mdi-light-flood-down',
		'mdi-light-flood-up',
		'mdi-light-recessed',
		'mdi-light-switch',
		'mdi-light-switch-off',
		'mdi-lightbulb',
		'mdi-lightbulb-alert',
		'mdi-lightbulb-alert-outline',
		'mdi-lightbulb-auto',
		'mdi-lightbulb-auto-outline',
		'mdi-lightbulb-cfl',
		'mdi-lightbulb-cfl-off',
		'mdi-lightbulb-cfl-spiral',
		'mdi-lightbulb-cfl-spiral-off',
		'mdi-lightbulb-fluorescent-tube',
		'mdi-lightbulb-fluorescent-tube-outline',
		'mdi-lightbulb-group',
		'mdi-lightbulb-group-off',
		'mdi-lightbulb-group-off-outline',
		'mdi-lightbulb-group-outline',
		'mdi-lightbulb-multiple',
		'mdi-lightbulb-multiple-off',
		'mdi-lightbulb-multiple-off-outline',
		'mdi-lightbulb-multiple-outline',
		'mdi-lightbulb-night',
		'mdi-lightbulb-night-outline',
		'mdi-lightbulb-off',
		'mdi-lightbulb-off-outline',
		'mdi-lightbulb-on',
		'mdi-lightbulb-on-10',
		'mdi-lightbulb-on-20',
		'mdi-lightbulb-on-30',
		'mdi-lightbulb-on-40',
		'mdi-lightbulb-on-50',
		'mdi-lightbulb-on-60',
		'mdi-lightbulb-on-70',
		'mdi-lightbulb-on-80',
		'mdi-lightbulb-on-90',
		'mdi-lightbulb-on-outline',
		'mdi-lightbulb-outline',
		'mdi-lightbulb-question',
		'mdi-lightbulb-question-outline',
		'mdi-lightbulb-spot',
		'mdi-lightbulb-spot-off',
		'mdi-lightbulb-variant',
		'mdi-lightbulb-variant-outline',
		'mdi-lightning-bolt',
		'mdi-lightning-bolt-circle',
		'mdi-lightning-bolt-outline',
		'mdi-location-enter',
		'mdi-location-exit',
		'mdi-lock',
		'mdi-lock-alert',
		'mdi-lock-alert-outline',
		'mdi-lock-open',
		'mdi-lock-open-alert',
		'mdi-lock-open-alert-outline',
		'mdi-lock-open-outline',
		'mdi-lock-open-variant',
		'mdi-lock-open-variant-outline',
		'mdi-lock-outline',
		'mdi-lock-smart',
		'mdi-map-marker-radius',
		'mdi-map-marker-radius-outline',
		'mdi-meter-electric',
		'mdi-meter-electric-outline',
		'mdi-meter-gas',
		'mdi-meter-gas-outline',
		'mdi-microwave',
		'mdi-microwave-off',
		'mdi-mirror',
		'mdi-mirror-rectangle',
		'mdi-mirror-variant',
		'mdi-molecule-co',
		'mdi-molecule-co2',
		'mdi-motion-sensor',
		'mdi-motion-sensor-off',
		'mdi-mower',
		'mdi-mower-bag',
		'mdi-mower-bag-on',
		'mdi-mower-on',
		'mdi-nfc-variant',
		'mdi-nfc-variant-off',
		'mdi-outdoor-lamp',
		'mdi-paper-roll',
		'mdi-paper-roll-outline',
		'mdi-patio-heater',
		'mdi-pause',
		'mdi-ph',
		'mdi-pipe',
		'mdi-pipe-disconnected',
		'mdi-pipe-leak',
		'mdi-pipe-valve',
		'mdi-play',
		'mdi-play-pause',
		'mdi-pool',
		'mdi-pool-thermometer',
		'mdi-post-lamp',
		'mdi-power',
		'mdi-power-plug',
		'mdi-power-plug-off',
		'mdi-power-plug-off-outline',
		'mdi-power-plug-outline',
		'mdi-power-socket',
		'mdi-power-socket-au',
		'mdi-power-socket-ch',
		'mdi-power-socket-de',
		'mdi-power-socket-eu',
		'mdi-power-socket-fr',
		'mdi-power-socket-jp',
		'mdi-power-socket-uk',
		'mdi-power-socket-us',
		'mdi-printer',
		'mdi-printer-3d',
		'mdi-printer-alert',
		'mdi-projector',
		'mdi-projector-off',
		'mdi-projector-screen',
		'mdi-projector-screen-off',
		'mdi-projector-screen-off-outline',
		'mdi-projector-screen-outline',
		'mdi-projector-screen-variant',
		'mdi-projector-screen-variant-off',
		'mdi-projector-screen-variant-off-outline',
		'mdi-projector-screen-variant-outline',
		'mdi-radiator',
		'mdi-radiator-disabled',
		'mdi-radiator-off',
		'mdi-record',
		'mdi-record-player',
		'mdi-record-rec',
		'mdi-remote',
		'mdi-robot',
		'mdi-robot-mower',
		'mdi-robot-mower-outline',
		'mdi-robot-vacuum',
		'mdi-robot-vacuum-alert',
		'mdi-robot-vacuum-off',
		'mdi-robot-vacuum-variant',
		'mdi-robot-vacuum-variant-alert',
		'mdi-robot-vacuum-variant-off',
		'mdi-roller-shade',
		'mdi-roller-shade-closed',
		'mdi-rug',
		'mdi-run-fast',
		'mdi-scale-bathroom',
		'mdi-seat',
		'mdi-seat-outline',
		'mdi-set-top-box',
		'mdi-shield-account',
		'mdi-shield-account-outline',
		'mdi-shield-home',
		'mdi-shield-home-outline',
		'mdi-shield-lock',
		'mdi-shield-lock-open',
		'mdi-shield-lock-open-outline',
		'mdi-shield-lock-outline',
		'mdi-shield-moon',
		'mdi-shield-moon-outline',
		'mdi-shower',
		'mdi-shower-head',
		'mdi-skip-backward',
		'mdi-skip-forward',
		'mdi-skip-next',
		'mdi-skip-previous',
		'mdi-smoke-detector',
		'mdi-smoke-detector-alert',
		'mdi-smoke-detector-alert-outline',
		'mdi-smoke-detector-off',
		'mdi-smoke-detector-off-outline',
		'mdi-smoke-detector-outline',
		'mdi-smoke-detector-variant',
		'mdi-smoke-detector-variant-alert',
		'mdi-smoke-detector-variant-off',
		'mdi-snowflake-alert',
		'mdi-snowflake-thermometer',
		'mdi-sofa',
		'mdi-sofa-outline',
		'mdi-sofa-single',
		'mdi-sofa-single-outline',
		'mdi-solar-panel',
		'mdi-solar-panel-large',
		'mdi-solar-power',
		'mdi-solar-power-variant',
		'mdi-solar-power-variant-outline',
		'mdi-soundbar',
		'mdi-speaker',
		'mdi-speaker-message',
		'mdi-speaker-off',
		'mdi-speaker-wireless',
		'mdi-spotlight',
		'mdi-spotlight-beam',
		'mdi-sprinkler',
		'mdi-sprinkler-fire',
		'mdi-sprinkler-variant',
		'mdi-stove',
		'mdi-string-lights',
		'mdi-string-lights-off',
		'mdi-sun-clock',
		'mdi-sun-clock-outline',
		'mdi-sun-compass',
		'mdi-sun-snowflake',
		'mdi-sun-snowflake-variant',
		'mdi-sun-thermometer',
		'mdi-sun-thermometer-outline',
		'mdi-sun-wireless',
		'mdi-sun-wireless-outline',
		'mdi-table-chair',
		'mdi-table-furniture',
		'mdi-teddy-bear',
		'mdi-television',
		'mdi-television-ambient-light',
		'mdi-television-classic',
		'mdi-television-classic-off',
		'mdi-television-guide',
		'mdi-television-off',
		'mdi-theater',
		'mdi-thermometer',
		'mdi-thermometer-alert',
		'mdi-thermometer-auto',
		'mdi-thermometer-bluetooth',
		'mdi-thermometer-check',
		'mdi-thermometer-chevron-down',
		'mdi-thermometer-chevron-up',
		'mdi-thermometer-high',
		'mdi-thermometer-lines',
		'mdi-thermometer-low',
		'mdi-thermometer-minus',
		'mdi-thermometer-off',
		'mdi-thermometer-plus',
		'mdi-thermometer-water',
		'mdi-thermostat',
		'mdi-thermostat-auto',
		'mdi-thermostat-box',
		'mdi-thermostat-box-auto',
		'mdi-toaster',
		'mdi-toaster-off',
		'mdi-toaster-oven',
		'mdi-toggle-switch-variant',
		'mdi-toggle-switch-variant-off',
		'mdi-toilet',
		'mdi-track-light',
		'mdi-transmission-tower',
		'mdi-transmission-tower-export',
		'mdi-transmission-tower-import',
		'mdi-transmission-tower-off',
		'mdi-tumble-dryer',
		'mdi-tumble-dryer-alert',
		'mdi-tumble-dryer-off',
		'mdi-vacuum',
		'mdi-vacuum-outline',
		'mdi-valve',
		'mdi-valve-closed',
		'mdi-valve-open',
		'mdi-vanity-light',
		'mdi-video',
		'mdi-video-off',
		'mdi-volume-high',
		'mdi-volume-low',
		'mdi-volume-medium',
		'mdi-volume-minus',
		'mdi-volume-off',
		'mdi-volume-plus',
		'mdi-wall-sconce',
		'mdi-wall-sconce-flat',
		'mdi-wall-sconce-flat-outline',
		'mdi-wall-sconce-flat-variant',
		'mdi-wall-sconce-flat-variant-outline',
		'mdi-wall-sconce-outline',
		'mdi-wall-sconce-round',
		'mdi-wall-sconce-round-outline',
		'mdi-wall-sconce-round-variant',
		'mdi-wall-sconce-round-variant-outline',
		'mdi-wardrobe',
		'mdi-wardrobe-outline',
		'mdi-washing-machine',
		'mdi-washing-machine-alert',
		'mdi-washing-machine-off',
		'mdi-water',
		'mdi-water-boiler',
		'mdi-water-boiler-alert',
		'mdi-water-boiler-auto',
		'mdi-water-boiler-off',
		'mdi-water-circle',
		'mdi-water-opacity',
		'mdi-water-outline',
		'mdi-water-percent',
		'mdi-water-pump',
		'mdi-water-pump-off',
		'mdi-water-thermometer',
		'mdi-water-thermometer-outline',
		'mdi-waterfall',
		'mdi-weather-sunny-alert',
		'mdi-webcam',
		'mdi-wind-power',
		'mdi-wind-power-outline',
		'mdi-wind-turbine',
		'mdi-wind-turbine-alert',
		'mdi-wind-turbine-check',
		'mdi-window-closed',
		'mdi-window-closed-variant',
		'mdi-window-open',
		'mdi-window-open-variant',
		'mdi-window-shutter',
		'mdi-window-shutter-alert',
		'mdi-window-shutter-auto',
		'mdi-window-shutter-cog',
		'mdi-window-shutter-open',
		'mdi-window-shutter-settings',
		'mdi-z-wave',
		'mdi-zigbee',
	],
	holiday: [
		'mdi-balloon',
		'mdi-bat',
		'mdi-bed',
		'mdi-bed-double',
		'mdi-bed-double-outline',
		'mdi-bed-empty',
		'mdi-bed-king',
		'mdi-bed-king-outline',
		'mdi-bed-outline',
		'mdi-bed-queen',
		'mdi-bed-queen-outline',
		'mdi-bed-single',
		'mdi-bed-single-outline',
		'mdi-bone',
		'mdi-bone-off',
		'mdi-bottle-tonic-skull',
		'mdi-bottle-tonic-skull-outline',
		'mdi-cake',
		'mdi-cake-layered',
		'mdi-cake-variant',
		'mdi-cake-variant-outline',
		'mdi-candelabra',
		'mdi-candelabra-fire',
		'mdi-candle',
		'mdi-candycane',
		'mdi-cat',
		'mdi-coffin',
		'mdi-cross',
		'mdi-cross-celtic',
		'mdi-egg-easter',
		'mdi-firework',
		'mdi-food-turkey',
		'mdi-gift',
		'mdi-gift-off',
		'mdi-gift-off-outline',
		'mdi-gift-open',
		'mdi-gift-open-outline',
		'mdi-gift-outline',
		'mdi-grave-stone',
		'mdi-halloween',
		'mdi-hexagram',
		'mdi-hexagram-outline',
		'mdi-menorah',
		'mdi-menorah-fire',
		'mdi-ornament',
		'mdi-ornament-variant',
		'mdi-owl',
		'mdi-party-popper',
		'mdi-pine-tree',
		'mdi-pine-tree-box',
		'mdi-pot',
		'mdi-pot-mix',
		'mdi-pot-mix-outline',
		'mdi-pot-outline',
		'mdi-pot-steam',
		'mdi-pot-steam-outline',
		'mdi-pumpkin',
		'mdi-rabbit-variant',
		'mdi-rabbit-variant-outline',
		'mdi-skull',
		'mdi-skull-crossbones',
		'mdi-skull-crossbones-outline',
		'mdi-skull-outline',
		'mdi-snowflake',
		'mdi-snowflake-variant',
		'mdi-snowman',
		'mdi-spider',
		'mdi-spider-thread',
		'mdi-spider-web',
		'mdi-stocking',
		'mdi-teddy-bear',
		'mdi-turkey',
		'mdi-weather-night',
	],
	health: [
		'mdi-content-cut',
		'mdi-face-man-shimmer',
		'mdi-face-man-shimmer-outline',
		'mdi-face-woman-shimmer',
		'mdi-face-woman-shimmer-outline',
		'mdi-hair-dryer',
		'mdi-hair-dryer-outline',
		'mdi-lipstick',
		'mdi-lotion',
		'mdi-lotion-outline',
		'mdi-medication',
		'mdi-medication-outline',
		'mdi-razor-double-edge',
		'mdi-water',
	],
	tools: [
		'mdi-axe',
		'mdi-bolt',
		'mdi-box-cutter',
		'mdi-bulldozer',
		'mdi-car-wrench',
		'mdi-circular-saw',
		'mdi-dump-truck',
		'mdi-excavator',
		'mdi-fire-extinguisher',
		'mdi-hammer',
		'mdi-hammer-screwdriver',
		'mdi-hammer-wrench',
		'mdi-hand-saw',
		'mdi-hard-hat',
		'mdi-ladder',
		'mdi-mower',
		'mdi-mower-bag',
		'mdi-mower-bag-on',
		'mdi-mower-on',
		'mdi-nail',
		'mdi-nut',
		'mdi-pipe-wrench',
		'mdi-pitchfork',
		'mdi-pliers',
		'mdi-progress-wrench',
		'mdi-rake',
		'mdi-razor-double-edge',
		'mdi-razor-single-edge',
		'mdi-rivet',
		'mdi-ruler',
		'mdi-ruler-square',
		'mdi-ruler-square-compass',
		'mdi-saw-blade',
		'mdi-screw-flat-top',
		'mdi-screw-lag',
		'mdi-screw-machine-flat-top',
		'mdi-screw-machine-round-top',
		'mdi-screw-round-top',
		'mdi-screwdriver',
		'mdi-set-square',
		'mdi-shovel',
		'mdi-shovel-off',
		'mdi-sickle',
		'mdi-spade',
		'mdi-spirit-level',
		'mdi-tape-measure',
		'mdi-toolbox',
		'mdi-toolbox-outline',
		'mdi-tools',
		'mdi-wheel-barrow',
		'mdi-wrench',
		'mdi-wrench-clock',
		'mdi-wrench-outline',
	],
	'geographic information': [
		'mdi-arrange-bring-forward',
		'mdi-arrange-bring-to-front',
		'mdi-arrange-send-backward',
		'mdi-arrange-send-to-back',
		'mdi-arrow-expand-all',
		'mdi-checkerboard',
		'mdi-checkerboard-minus',
		'mdi-checkerboard-plus',
		'mdi-checkerboard-remove',
		'mdi-compass',
		'mdi-compass-off',
		'mdi-compass-off-outline',
		'mdi-compass-outline',
		'mdi-crosshairs',
		'mdi-crosshairs-gps',
		'mdi-crosshairs-off',
		'mdi-crosshairs-question',
		'mdi-database',
		'mdi-database-check',
		'mdi-database-edit',
		'mdi-database-export',
		'mdi-database-import',
		'mdi-database-lock',
		'mdi-database-marker',
		'mdi-database-minus',
		'mdi-database-plus',
		'mdi-database-remove',
		'mdi-database-search',
		'mdi-database-settings',
		'mdi-database-sync',
		'mdi-earth',
		'mdi-earth-off',
		'mdi-folder-marker',
		'mdi-folder-marker-outline',
		'mdi-latitude',
		'mdi-layers',
		'mdi-layers-edit',
		'mdi-layers-minus',
		'mdi-layers-off',
		'mdi-layers-off-outline',
		'mdi-layers-outline',
		'mdi-layers-plus',
		'mdi-layers-remove',
		'mdi-layers-search',
		'mdi-layers-search-outline',
		'mdi-longitude',
		'mdi-magnify',
		'mdi-magnify-expand',
		'mdi-magnify-minus-outline',
		'mdi-magnify-plus-outline',
		'mdi-magnify-remove-outline',
		'mdi-map',
		'mdi-map-check',
		'mdi-map-check-outline',
		'mdi-map-clock',
		'mdi-map-clock-outline',
		'mdi-map-legend',
		'mdi-map-marker',
		'mdi-map-marker-alert',
		'mdi-map-marker-alert-outline',
		'mdi-map-marker-check',
		'mdi-map-marker-check-outline',
		'mdi-map-marker-circle',
		'mdi-map-marker-distance',
		'mdi-map-marker-down',
		'mdi-map-marker-left',
		'mdi-map-marker-left-outline',
		'mdi-map-marker-minus',
		'mdi-map-marker-minus-outline',
		'mdi-map-marker-multiple',
		'mdi-map-marker-multiple-outline',
		'mdi-map-marker-off',
		'mdi-map-marker-off-outline',
		'mdi-map-marker-outline',
		'mdi-map-marker-path',
		'mdi-map-marker-plus',
		'mdi-map-marker-plus-outline',
		'mdi-map-marker-question',
		'mdi-map-marker-question-outline',
		'mdi-map-marker-radius',
		'mdi-map-marker-radius-outline',
		'mdi-map-marker-remove',
		'mdi-map-marker-remove-outline',
		'mdi-map-marker-remove-variant',
		'mdi-map-marker-right',
		'mdi-map-marker-right-outline',
		'mdi-map-marker-up',
		'mdi-map-minus',
		'mdi-map-outline',
		'mdi-map-plus',
		'mdi-map-search',
		'mdi-map-search-outline',
		'mdi-palette-outline',
		'mdi-table-large',
		'mdi-table-large-plus',
		'mdi-table-large-remove',
		'mdi-vector-arrange-above',
		'mdi-vector-arrange-below',
		'mdi-vector-circle',
		'mdi-vector-combine',
		'mdi-vector-curve',
		'mdi-vector-difference',
		'mdi-vector-difference-ab',
		'mdi-vector-difference-ba',
		'mdi-vector-ellipse',
		'mdi-vector-intersection',
		'mdi-vector-line',
		'mdi-vector-link',
		'mdi-vector-point-select',
		'mdi-vector-polygon',
		'mdi-vector-polyline',
		'mdi-vector-radius',
		'mdi-vector-rectangle',
		'mdi-vector-selection',
		'mdi-vector-square',
		'mdi-vector-triangle',
		'mdi-vector-union',
		'mdi-web',
		'mdi-web-box',
	],
	form: [
		'mdi-button-cursor',
		'mdi-button-pointer',
		'mdi-card',
		'mdi-card-outline',
		'mdi-checkbox-blank',
		'mdi-checkbox-blank-circle',
		'mdi-checkbox-blank-circle-outline',
		'mdi-checkbox-blank-outline',
		'mdi-checkbox-intermediate',
		'mdi-checkbox-intermediate-variant',
		'mdi-checkbox-marked',
		'mdi-checkbox-marked-circle',
		'mdi-checkbox-marked-circle-outline',
		'mdi-checkbox-marked-outline',
		'mdi-checkbox-multiple-blank',
		'mdi-checkbox-multiple-blank-circle',
		'mdi-checkbox-multiple-blank-circle-outline',
		'mdi-checkbox-multiple-blank-outline',
		'mdi-checkbox-multiple-marked',
		'mdi-checkbox-multiple-marked-circle',
		'mdi-checkbox-multiple-marked-circle-outline',
		'mdi-checkbox-multiple-marked-outline',
		'mdi-chevron-down-box',
		'mdi-chevron-down-box-outline',
		'mdi-draw',
		'mdi-draw-pen',
		'mdi-form-dropdown',
		'mdi-form-select',
		'mdi-form-textarea',
		'mdi-form-textbox',
		'mdi-form-textbox-lock',
		'mdi-form-textbox-password',
		'mdi-gesture-tap-button',
		'mdi-radiobox-blank',
		'mdi-radiobox-marked',
		'mdi-signature',
		'mdi-signature-freehand',
		'mdi-signature-image',
		'mdi-signature-text',
	],
	emoji: [
		'mdi-emoticon',
		'mdi-emoticon-angry',
		'mdi-emoticon-angry-outline',
		'mdi-emoticon-confused',
		'mdi-emoticon-confused-outline',
		'mdi-emoticon-cool',
		'mdi-emoticon-cool-outline',
		'mdi-emoticon-cry',
		'mdi-emoticon-cry-outline',
		'mdi-emoticon-dead',
		'mdi-emoticon-dead-outline',
		'mdi-emoticon-devil',
		'mdi-emoticon-devil-outline',
		'mdi-emoticon-excited',
		'mdi-emoticon-excited-outline',
		'mdi-emoticon-frown',
		'mdi-emoticon-frown-outline',
		'mdi-emoticon-happy',
		'mdi-emoticon-happy-outline',
		'mdi-emoticon-kiss',
		'mdi-emoticon-kiss-outline',
		'mdi-emoticon-lol',
		'mdi-emoticon-lol-outline',
		'mdi-emoticon-neutral',
		'mdi-emoticon-neutral-outline',
		'mdi-emoticon-outline',
		'mdi-emoticon-poop',
		'mdi-emoticon-poop-outline',
		'mdi-emoticon-sad',
		'mdi-emoticon-sad-outline',
		'mdi-emoticon-sick',
		'mdi-emoticon-sick-outline',
		'mdi-emoticon-tongue',
		'mdi-emoticon-tongue-outline',
		'mdi-emoticon-wink',
		'mdi-emoticon-wink-outline',
		'mdi-sticker-emoji',
	],
	edit: [
		'mdi-account-edit',
		'mdi-account-edit-outline',
		'mdi-airplane-edit',
		'mdi-application-edit',
		'mdi-application-edit-outline',
		'mdi-archive-edit',
		'mdi-archive-edit-outline',
		'mdi-book-edit',
		'mdi-book-edit-outline',
		'mdi-briefcase-edit',
		'mdi-briefcase-edit-outline',
		'mdi-calendar-edit',
		'mdi-calendar-edit-outline',
		'mdi-circle-edit-outline',
		'mdi-clipboard-edit',
		'mdi-clipboard-edit-outline',
		'mdi-clock-edit',
		'mdi-clock-edit-outline',
		'mdi-comment-edit',
		'mdi-comment-edit-outline',
		'mdi-content-save-edit',
		'mdi-content-save-edit-outline',
		'mdi-cookie-edit',
		'mdi-cookie-edit-outline',
		'mdi-credit-card-edit',
		'mdi-credit-card-edit-outline',
		'mdi-data-matrix-edit',
		'mdi-database-edit',
		'mdi-database-edit-outline',
		'mdi-email-edit',
		'mdi-email-edit-outline',
		'mdi-file-document-edit',
		'mdi-file-document-edit-outline',
		'mdi-file-edit',
		'mdi-file-edit-outline',
		'mdi-folder-edit',
		'mdi-folder-edit-outline',
		'mdi-home-edit',
		'mdi-home-edit-outline',
		'mdi-human-edit',
		'mdi-image-edit',
		'mdi-image-edit-outline',
		'mdi-layers-edit',
		'mdi-monitor-edit',
		'mdi-movie-edit',
		'mdi-movie-edit-outline',
		'mdi-movie-open-edit',
		'mdi-movie-open-edit-outline',
		'mdi-note-edit',
		'mdi-note-edit-outline',
		'mdi-notebook-edit',
		'mdi-notebook-edit-outline',
		'mdi-pencil',
		'mdi-pencil-box-multiple',
		'mdi-pencil-box-multiple-outline',
		'mdi-pencil-outline',
		'mdi-playlist-edit',
		'mdi-puzzle-edit',
		'mdi-puzzle-edit-outline',
		'mdi-qrcode-edit',
		'mdi-shield-edit',
		'mdi-shield-edit-outline',
		'mdi-square-edit-outline',
		'mdi-store-edit',
		'mdi-store-edit-outline',
		'mdi-storefront-edit',
		'mdi-storefront-edit-outline',
		'mdi-table-edit',
		'mdi-text-box-edit',
		'mdi-text-box-edit-outline',
		'mdi-timer-edit',
		'mdi-timer-edit-outline',
		'mdi-tooltip-edit',
		'mdi-tooltip-edit-outline',
		'mdi-vector-point-edit',
		'mdi-vector-polyline-edit',
		'mdi-vector-square-edit',
		'mdi-view-dashboard-edit',
		'mdi-view-dashboard-edit-outline',
	],
	drawing: [
		'mdi-artboard',
		'mdi-brush',
		'mdi-brush-outline',
		'mdi-brush-variant',
		'mdi-circle-opacity',
		'mdi-draw',
		'mdi-draw-pen',
		'mdi-drawing',
		'mdi-drawing-box',
		'mdi-eyedropper',
		'mdi-format-line-style',
		'mdi-format-line-weight',
		'mdi-format-paint',
		'mdi-fountain-pen',
		'mdi-fountain-pen-tip',
		'mdi-gesture',
		'mdi-gradient-horizontal',
		'mdi-gradient-vertical',
		'mdi-grease-pencil',
		'mdi-lead-pencil',
		'mdi-math-compass',
		'mdi-palette',
		'mdi-palette-advanced',
		'mdi-palette-outline',
		'mdi-palette-swatch',
		'mdi-palette-swatch-outline',
		'mdi-palette-swatch-variant',
		'mdi-pen',
		'mdi-pencil',
		'mdi-pencil-box',
		'mdi-pencil-box-outline',
		'mdi-pencil-circle',
		'mdi-pencil-circle-outline',
		'mdi-pencil-outline',
		'mdi-pencil-ruler',
		'mdi-pencil-ruler-outline',
		'mdi-ruler',
		'mdi-ruler-square',
		'mdi-spray',
		'mdi-square-opacity',
		'mdi-water-opacity',
	],
	devices: [
		'mdi-camera-gopro',
		'mdi-cellphone',
		'mdi-cellphone-arrow-down',
		'mdi-cellphone-basic',
		'mdi-cellphone-cog',
		'mdi-cellphone-dock',
		'mdi-cellphone-key',
		'mdi-cellphone-link',
		'mdi-cellphone-link-off',
		'mdi-cellphone-lock',
		'mdi-cellphone-message',
		'mdi-cellphone-nfc',
		'mdi-cellphone-off',
		'mdi-cellphone-remove',
		'mdi-cellphone-screenshot',
		'mdi-cellphone-settings',
		'mdi-cellphone-sound',
		'mdi-cellphone-text',
		'mdi-cellphone-wireless',
		'mdi-deskphone',
		'mdi-desktop-classic',
		'mdi-desktop-tower',
		'mdi-desktop-tower-monitor',
		'mdi-devices',
		'mdi-disc-player',
		'mdi-headphones',
		'mdi-headphones-off',
		'mdi-headset',
		'mdi-headset-off',
		'mdi-hydro-power',
		'mdi-laptop',
		'mdi-laptop-account',
		'mdi-laptop-off',
		'mdi-monitor',
		'mdi-monitor-account',
		'mdi-monitor-arrow-down',
		'mdi-monitor-arrow-down-variant',
		'mdi-monitor-cellphone',
		'mdi-monitor-cellphone-star',
		'mdi-monitor-dashboard',
		'mdi-monitor-lock',
		'mdi-monitor-multiple',
		'mdi-monitor-off',
		'mdi-monitor-screenshot',
		'mdi-monitor-shimmer',
		'mdi-monitor-small',
		'mdi-monitor-speaker',
		'mdi-monitor-speaker-off',
		'mdi-monitor-star',
		'mdi-projector',
		'mdi-projector-off',
		'mdi-projector-screen',
		'mdi-radio',
		'mdi-radio-handheld',
		'mdi-remote-tv',
		'mdi-remote-tv-off',
		'mdi-robot-vacuum',
		'mdi-scanner',
		'mdi-scanner-off',
		'mdi-smoke-detector',
		'mdi-tablet',
		'mdi-tablet-cellphone',
		'mdi-tablet-dashboard',
		'mdi-television',
		'mdi-television-classic',
		'mdi-television-classic-off',
		'mdi-television-guide',
		'mdi-television-off',
		'mdi-television-pause',
		'mdi-television-play',
		'mdi-television-shimmer',
		'mdi-television-stop',
		'mdi-thermostat',
		'mdi-thermostat-box',
		'mdi-wall-fire',
		'mdi-watch',
		'mdi-watch-export',
		'mdi-watch-export-variant',
		'mdi-watch-import',
		'mdi-watch-import-variant',
		'mdi-watch-variant',
		'mdi-watch-vibrate',
		'mdi-watch-vibrate-off',
	],
	developers: [
		'mdi-ab-testing',
		'mdi-angular',
		'mdi-angularjs',
		'mdi-ansible',
		'mdi-apache-kafka',
		'mdi-api',
		'mdi-api-off',
		'mdi-application-array',
		'mdi-application-array-outline',
		'mdi-application-braces',
		'mdi-application-braces-outline',
		'mdi-application-brackets',
		'mdi-application-brackets-outline',
		'mdi-application-parentheses',
		'mdi-application-parentheses-outline',
		'mdi-application-variable',
		'mdi-application-variable-outline',
		'mdi-bash',
		'mdi-bootstrap',
		'mdi-bulma',
		'mdi-cloud-braces',
		'mdi-code-array',
		'mdi-code-braces',
		'mdi-code-braces-box',
		'mdi-code-brackets',
		'mdi-code-equal',
		'mdi-code-greater-than',
		'mdi-code-greater-than-or-equal',
		'mdi-code-json',
		'mdi-code-less-than',
		'mdi-code-less-than-or-equal',
		'mdi-code-not-equal',
		'mdi-code-not-equal-variant',
		'mdi-code-parentheses',
		'mdi-code-parentheses-box',
		'mdi-code-string',
		'mdi-code-tags',
		'mdi-code-tags-check',
		'mdi-codepen',
		'mdi-cordova',
		'mdi-digital-ocean',
		'mdi-dot-net',
		'mdi-electron-framework',
		'mdi-eslint',
		'mdi-file-code',
		'mdi-file-code-outline',
		'mdi-folder-pound',
		'mdi-folder-pound-outline',
		'mdi-gatsby',
		'mdi-git',
		'mdi-github',
		'mdi-gitlab',
		'mdi-hexadecimal',
		'mdi-identifier',
		'mdi-kubernetes',
		'mdi-language-c',
		'mdi-language-cpp',
		'mdi-language-csharp',
		'mdi-language-css3',
		'mdi-language-fortran',
		'mdi-language-go',
		'mdi-language-haskell',
		'mdi-language-html5',
		'mdi-language-java',
		'mdi-language-javascript',
		'mdi-language-kotlin',
		'mdi-language-lua',
		'mdi-language-markdown',
		'mdi-language-markdown-outline',
		'mdi-language-php',
		'mdi-language-python',
		'mdi-language-r',
		'mdi-language-ruby',
		'mdi-language-ruby-on-rails',
		'mdi-language-rust',
		'mdi-language-swift',
		'mdi-language-typescript',
		'mdi-language-xaml',
		'mdi-math-norm',
		'mdi-math-norm-box',
		'mdi-microsoft-visual-studio-code',
		'mdi-nix',
		'mdi-nuxt',
		'mdi-oci',
		'mdi-polymer',
		'mdi-react',
		'mdi-rollupjs',
		'mdi-sass',
		'mdi-semantic-web',
		'mdi-source-branch',
		'mdi-source-branch-check',
		'mdi-source-branch-minus',
		'mdi-source-branch-plus',
		'mdi-source-branch-refresh',
		'mdi-source-branch-remove',
		'mdi-source-branch-sync',
		'mdi-source-fork',
		'mdi-source-merge',
		'mdi-source-pull',
		'mdi-source-repository',
		'mdi-source-repository-multiple',
		'mdi-translate-variant',
		'mdi-unicode',
		'mdi-variable',
		'mdi-variable-box',
		'mdi-vuejs',
		'mdi-webpack',
		'mdi-xml',
		'mdi-zend',
	],
	database: [
		'mdi-database',
		'mdi-database-alert',
		'mdi-database-alert-outline',
		'mdi-database-arrow-down',
		'mdi-database-arrow-down-outline',
		'mdi-database-arrow-left',
		'mdi-database-arrow-left-outline',
		'mdi-database-arrow-right',
		'mdi-database-arrow-right-outline',
		'mdi-database-arrow-up',
		'mdi-database-arrow-up-outline',
		'mdi-database-check',
		'mdi-database-check-outline',
		'mdi-database-clock',
		'mdi-database-clock-outline',
		'mdi-database-cog',
		'mdi-database-cog-outline',
		'mdi-database-edit',
		'mdi-database-edit-outline',
		'mdi-database-export',
		'mdi-database-export-outline',
		'mdi-database-eye',
		'mdi-database-eye-off',
		'mdi-database-eye-off-outline',
		'mdi-database-eye-outline',
		'mdi-database-import',
		'mdi-database-import-outline',
		'mdi-database-lock',
		'mdi-database-lock-outline',
		'mdi-database-marker',
		'mdi-database-marker-outline',
		'mdi-database-minus',
		'mdi-database-minus-outline',
		'mdi-database-off',
		'mdi-database-off-outline',
		'mdi-database-outline',
		'mdi-database-plus',
		'mdi-database-plus-outline',
		'mdi-database-refresh',
		'mdi-database-refresh-outline',
		'mdi-database-remove',
		'mdi-database-remove-outline',
		'mdi-database-search',
		'mdi-database-search-outline',
		'mdi-database-settings',
		'mdi-database-settings-outline',
		'mdi-database-sync',
		'mdi-database-sync-outline',
		'mdi-relation-many-to-many',
		'mdi-relation-many-to-one',
		'mdi-relation-many-to-one-or-many',
		'mdi-relation-many-to-only-one',
		'mdi-relation-many-to-zero-or-many',
		'mdi-relation-many-to-zero-or-one',
		'mdi-relation-one-or-many-to-many',
		'mdi-relation-one-or-many-to-one',
		'mdi-relation-one-or-many-to-one-or-many',
		'mdi-relation-one-or-many-to-only-one',
		'mdi-relation-one-or-many-to-zero-or-many',
		'mdi-relation-one-or-many-to-zero-or-one',
		'mdi-relation-one-to-many',
		'mdi-relation-one-to-one',
		'mdi-relation-one-to-one-or-many',
		'mdi-relation-one-to-only-one',
		'mdi-relation-one-to-zero-or-many',
		'mdi-relation-one-to-zero-or-one',
		'mdi-relation-only-one-to-many',
		'mdi-relation-only-one-to-one',
		'mdi-relation-only-one-to-one-or-many',
		'mdi-relation-only-one-to-only-one',
		'mdi-relation-only-one-to-zero-or-many',
		'mdi-relation-only-one-to-zero-or-one',
		'mdi-relation-zero-or-many-to-many',
		'mdi-relation-zero-or-many-to-one',
		'mdi-relation-zero-or-many-to-one-or-many',
		'mdi-relation-zero-or-many-to-only-one',
		'mdi-relation-zero-or-many-to-zero-or-many',
		'mdi-relation-zero-or-many-to-zero-or-one',
		'mdi-relation-zero-or-one-to-many',
		'mdi-relation-zero-or-one-to-one',
		'mdi-relation-zero-or-one-to-one-or-many',
		'mdi-relation-zero-or-one-to-only-one',
		'mdi-relation-zero-or-one-to-zero-or-many',
		'mdi-relation-zero-or-one-to-zero-or-one',
		'mdi-set-all',
		'mdi-set-center',
		'mdi-set-center-right',
		'mdi-set-left',
		'mdi-set-left-center',
		'mdi-set-left-right',
		'mdi-set-none',
		'mdi-set-right',
	],
	currency: [
		'mdi-account-cash',
		'mdi-account-cash-outline',
		'mdi-bitcoin',
		'mdi-cash',
		'mdi-cash-100',
		'mdi-cash-check',
		'mdi-cash-clock',
		'mdi-cash-fast',
		'mdi-cash-lock',
		'mdi-cash-lock-open',
		'mdi-cash-marker',
		'mdi-cash-minus',
		'mdi-cash-multiple',
		'mdi-cash-plus',
		'mdi-cash-refund',
		'mdi-cash-remove',
		'mdi-cash-sync',
		'mdi-circle-multiple',
		'mdi-circle-multiple-outline',
		'mdi-contactless-payment',
		'mdi-contactless-payment-circle',
		'mdi-contactless-payment-circle-outline',
		'mdi-credit-card',
		'mdi-credit-card-outline',
		'mdi-credit-card-wireless',
		'mdi-credit-card-wireless-outline',
		'mdi-currency-bdt',
		'mdi-currency-brl',
		'mdi-currency-btc',
		'mdi-currency-cny',
		'mdi-currency-eth',
		'mdi-currency-eur',
		'mdi-currency-eur-off',
		'mdi-currency-fra',
		'mdi-currency-gbp',
		'mdi-currency-ils',
		'mdi-currency-inr',
		'mdi-currency-jpy',
		'mdi-currency-krw',
		'mdi-currency-kzt',
		'mdi-currency-mnt',
		'mdi-currency-ngn',
		'mdi-currency-php',
		'mdi-currency-rial',
		'mdi-currency-rub',
		'mdi-currency-rupee',
		'mdi-currency-sign',
		'mdi-currency-try',
		'mdi-currency-twd',
		'mdi-currency-usd',
		'mdi-currency-usd-off',
		'mdi-litecoin',
		'mdi-wallet',
		'mdi-wallet-outline',
	],
	color: [
		'mdi-border-color',
		'mdi-color-helper',
		'mdi-eyedropper',
		'mdi-eyedropper-variant',
		'mdi-format-color-fill',
		'mdi-format-color-highlight',
		'mdi-format-color-text',
		'mdi-format-paint',
		'mdi-invert-colors',
		'mdi-invert-colors-off',
		'mdi-looks',
		'mdi-palette',
		'mdi-palette-advanced',
		'mdi-palette-outline',
		'mdi-palette-swatch',
		'mdi-palette-swatch-outline',
		'mdi-palette-swatch-variant',
		'mdi-select-color',
		'mdi-spray',
	],
	cloud:[ 
		'mdi-cloud',
		'mdi-cloud-alert',
		'mdi-cloud-alert-outline',
		'mdi-cloud-arrow-down',
		'mdi-cloud-arrow-down-outline',
		'mdi-cloud-arrow-left',
		'mdi-cloud-arrow-left-outline',
		'mdi-cloud-arrow-right',
		'mdi-cloud-arrow-right-outline',
		'mdi-cloud-arrow-up',
		'mdi-cloud-arrow-up-outline',
		'mdi-cloud-braces',
		'mdi-cloud-cancel',
		'mdi-cloud-cancel-outline',
		'mdi-cloud-check',
		'mdi-cloud-check-outline',
		'mdi-cloud-check-variant',
		'mdi-cloud-check-variant-outline',
		'mdi-cloud-circle',
		'mdi-cloud-circle-outline',
		'mdi-cloud-clock',
		'mdi-cloud-clock-outline',
		'mdi-cloud-cog',
		'mdi-cloud-cog-outline',
		'mdi-cloud-download',
		'mdi-cloud-download-outline',
		'mdi-cloud-lock',
		'mdi-cloud-lock-open',
		'mdi-cloud-lock-open-outline',
		'mdi-cloud-lock-outline',
		'mdi-cloud-minus',
		'mdi-cloud-minus-outline',
		'mdi-cloud-off',
		'mdi-cloud-off-outline',
		'mdi-cloud-outline',
		'mdi-cloud-percent',
		'mdi-cloud-percent-outline',
		'mdi-cloud-plus',
		'mdi-cloud-plus-outline',
		'mdi-cloud-print',
		'mdi-cloud-print-outline',
		'mdi-cloud-question',
		'mdi-cloud-question-outline',
		'mdi-cloud-refresh',
		'mdi-cloud-refresh-outline',
		'mdi-cloud-refresh-variant',
		'mdi-cloud-refresh-variant-outline',
		'mdi-cloud-remove',
		'mdi-cloud-remove-outline',
		'mdi-cloud-search',
		'mdi-cloud-search-outline',
		'mdi-cloud-sync',
		'mdi-cloud-sync-outline',
		'mdi-cloud-tags',
		'mdi-cloud-upload',
		'mdi-cloud-upload-outline',
		'mdi-clouds',
		'mdi-file-cloud',
		'mdi-file-cloud-outline',
		'mdi-image-filter-drama',
		'mdi-image-filter-drama-outline',
		'mdi-weather-cloudy',
		'mdi-weather-cloudy-alert',
		'mdi-weather-cloudy-arrow-right',
		'mdi-weather-cloudy-clock',
		'mdi-weather-night-partly-cloudy',
		'mdi-weather-partly-cloudy',
	],
	clothes: [
		'mdi-bow-tie',
		'mdi-chef-hat',
		'mdi-coat-rack',
		'mdi-face-mask',
		'mdi-face-mask-outline',
		'mdi-glasses',
		'mdi-hanger',
		'mdi-hard-hat',
		'mdi-hat-fedora',
		'mdi-iron',
		'mdi-iron-board',
		'mdi-iron-outline',
		'mdi-lingerie',
		'mdi-necklace',
		'mdi-shoe-ballet',
		'mdi-shoe-cleat',
		'mdi-shoe-formal',
		'mdi-shoe-heel',
		'mdi-shoe-sneaker',
		'mdi-sunglasses',
		'mdi-tie',
		'mdi-tshirt-crew',
		'mdi-tshirt-crew-outline',
		'mdi-tshirt-v',
		'mdi-tshirt-v-outline',
		'mdi-wizard-hat',
	],
	users: [
		'mdi-account',
		'mdi-account-alert',
		'mdi-account-alert-outline',
		'mdi-account-arrow-down',
		'mdi-account-arrow-down-outline',
		'mdi-account-arrow-left',
		'mdi-account-arrow-left-outline',
		'mdi-account-arrow-right',
		'mdi-account-arrow-right-outline',
		'mdi-account-arrow-up',
		'mdi-account-arrow-up-outline',
		'mdi-account-badge',
		'mdi-account-badge-outline',
		'mdi-account-box',
		'mdi-account-box-multiple',
		'mdi-account-box-multiple-outline',
		'mdi-account-box-outline',
		'mdi-account-cancel',
		'mdi-account-cancel-outline',
		'mdi-account-card',
		'mdi-account-card-outline',
		'mdi-account-cash',
		'mdi-account-cash-outline',
		'mdi-account-check',
		'mdi-account-check-outline',
		'mdi-account-child',
		'mdi-account-child-circle',
		'mdi-account-child-outline',
		'mdi-account-circle',
		'mdi-account-circle-outline',
		'mdi-account-clock',
		'mdi-account-clock-outline',
		'mdi-account-cog',
		'mdi-account-cog-outline',
		'mdi-account-convert',
		'mdi-account-convert-outline',
		'mdi-account-cowboy-hat',
		'mdi-account-cowboy-hat-outline',
		'mdi-account-credit-card',
		'mdi-account-credit-card-outline',
		'mdi-account-details',
		'mdi-account-details-outline',
		'mdi-account-edit',
		'mdi-account-edit-outline',
		'mdi-account-eye',
		'mdi-account-eye-outline',
		'mdi-account-filter',
		'mdi-account-filter-outline',
		'mdi-account-group',
		'mdi-account-group-outline',
		'mdi-account-hard-hat',
		'mdi-account-hard-hat-outline',
		'mdi-account-heart',
		'mdi-account-heart-outline',
		'mdi-account-injury',
		'mdi-account-injury-outline',
		'mdi-account-key',
		'mdi-account-key-outline',
		'mdi-account-lock',
		'mdi-account-lock-open',
		'mdi-account-lock-open-outline',
		'mdi-account-lock-outline',
		'mdi-account-minus',
		'mdi-account-minus-outline',
		'mdi-account-multiple',
		'mdi-account-multiple-check',
		'mdi-account-multiple-check-outline',
		'mdi-account-multiple-minus',
		'mdi-account-multiple-minus-outline',
		'mdi-account-multiple-outline',
		'mdi-account-multiple-plus',
		'mdi-account-multiple-plus-outline',
		'mdi-account-multiple-remove',
		'mdi-account-multiple-remove-outline',
		'mdi-account-network',
		'mdi-account-network-off',
		'mdi-account-network-off-outline',
		'mdi-account-network-outline',
		'mdi-account-off',
		'mdi-account-off-outline',
		'mdi-account-outline',
		'mdi-account-plus',
		'mdi-account-plus-outline',
		'mdi-account-question',
		'mdi-account-question-outline',
		'mdi-account-reactivate',
		'mdi-account-reactivate-outline',
		'mdi-account-remove',
		'mdi-account-remove-outline',
		'mdi-account-school',
		'mdi-account-school-outline',
		'mdi-account-search',
		'mdi-account-search-outline',
		'mdi-account-settings',
		'mdi-account-settings-outline',
		'mdi-account-star',
		'mdi-account-star-outline',
		'mdi-account-supervisor',
		'mdi-account-supervisor-circle',
		'mdi-account-supervisor-circle-outline',
		'mdi-account-supervisor-outline',
		'mdi-account-switch',
		'mdi-account-switch-outline',
		'mdi-account-sync',
		'mdi-account-sync-outline',
		'mdi-account-tag',
		'mdi-account-tag-outline',
		'mdi-account-tie',
		'mdi-account-tie-hat',
		'mdi-account-tie-hat-outline',
		'mdi-account-tie-outline',
		'mdi-account-tie-voice',
		'mdi-account-tie-voice-off',
		'mdi-account-tie-voice-off-outline',
		'mdi-account-tie-voice-outline',
		'mdi-account-tie-woman',
		'mdi-account-voice',
		'mdi-account-voice-off',
		'mdi-account-wrench',
		'mdi-account-wrench-outline',
		'mdi-badge-account',
		'mdi-badge-account-alert',
		'mdi-badge-account-alert-outline',
		'mdi-badge-account-horizontal',
		'mdi-badge-account-horizontal-outline',
		'mdi-badge-account-outline',
		'mdi-book-account',
		'mdi-book-account-outline',
		'mdi-briefcase-account',
		'mdi-briefcase-account-outline',
		'mdi-calendar-account',
		'mdi-calendar-account-outline',
		'mdi-camera-account',
		'mdi-card-account-details',
		'mdi-card-account-details-outline',
		'mdi-card-account-details-star',
		'mdi-card-account-details-star-outline',
		'mdi-card-account-mail',
		'mdi-card-account-mail-outline',
		'mdi-card-account-phone',
		'mdi-card-account-phone-outline',
		'mdi-clipboard-account',
		'mdi-clipboard-account-outline',
		'mdi-comment-account',
		'mdi-comment-account-outline',
		'mdi-crowd',
		'mdi-face-man-shimmer',
		'mdi-face-man-shimmer-outline',
		'mdi-face-woman-shimmer',
		'mdi-face-woman-shimmer-outline',
		'mdi-file-account',
		'mdi-file-account-outline',
		'mdi-folder-account',
		'mdi-folder-account-outline',
		'mdi-home-account',
		'mdi-human-capacity-decrease',
		'mdi-human-capacity-increase',
		'mdi-human-greeting-proximity',
		'mdi-laptop-account',
		'mdi-map-marker-account',
		'mdi-map-marker-account-outline',
		'mdi-monitor-account',
		'mdi-nature-people',
		'mdi-shield-account',
		'mdi-shield-account-outline',
		'mdi-shield-account-variant',
		'mdi-shield-account-variant-outline',
		'mdi-shield-check',
		'mdi-smart-card',
		'mdi-smart-card-off',
		'mdi-smart-card-off-outline',
		'mdi-smart-card-outline',
		'mdi-smart-card-reader',
		'mdi-smart-card-reader-outline',
		'mdi-table-account',
		'mdi-target-account',
		'mdi-text-account',
		'mdi-ticket-account',
		'mdi-tooltip-account',
		'mdi-video-account',
	],
	agriculture: [
		'mdi-account-cowboy-hat',
		'mdi-account-cowboy-hat-outline',
		'mdi-barley',
		'mdi-barley-off',
		'mdi-barn',
		'mdi-bee',
		'mdi-bee-flower',
		'mdi-beehive-off-outline',
		'mdi-beehive-outline',
		'mdi-beekeeper',
		'mdi-car-lifted-pickup',
		'mdi-car-pickup',
		'mdi-carrot',
		'mdi-chili-mild',
		'mdi-compost',
		'mdi-corn',
		'mdi-corn-off',
		'mdi-cow',
		'mdi-cow-off',
		'mdi-egg',
		'mdi-egg-off',
		'mdi-egg-off-outline',
		'mdi-egg-outline',
		'mdi-fence',
		'mdi-fence-electric',
		'mdi-flower',
		'mdi-flower-outline',
		'mdi-flower-pollen',
		'mdi-flower-pollen-outline',
		'mdi-flower-poppy',
		'mdi-flower-tulip',
		'mdi-flower-tulip-outline',
		'mdi-food-apple',
		'mdi-food-apple-outline',
		'mdi-forest',
		'mdi-fruit-cherries',
		'mdi-fruit-cherries-off',
		'mdi-fruit-citrus',
		'mdi-fruit-citrus-off',
		'mdi-fruit-grapes',
		'mdi-fruit-grapes-outline',
		'mdi-fruit-pineapple',
		'mdi-fruit-watermelon',
		'mdi-grain',
		'mdi-grass',
		'mdi-greenhouse',
		'mdi-heat-wave',
		'mdi-home-silo',
		'mdi-home-silo-outline',
		'mdi-hoop-house',
		'mdi-hops',
		'mdi-horse',
		'mdi-horse-human',
		'mdi-horse-variant',
		'mdi-horse-variant-fast',
		'mdi-horseshoe',
		'mdi-hydro-power',
		'mdi-land-fields',
		'mdi-land-plots',
		'mdi-land-plots-circle',
		'mdi-land-plots-circle-variant',
		'mdi-land-rows-horizontal',
		'mdi-land-rows-vertical',
		'mdi-leaf',
		'mdi-leaf-circle',
		'mdi-leaf-circle-outline',
		'mdi-leaf-off',
		'mdi-mushroom',
		'mdi-mushroom-off',
		'mdi-mushroom-off-outline',
		'mdi-mushroom-outline',
		'mdi-peanut',
		'mdi-peanut-off',
		'mdi-peanut-off-outline',
		'mdi-peanut-outline',
		'mdi-pig',
		'mdi-pig-variant',
		'mdi-pig-variant-outline',
		'mdi-pine-tree',
		'mdi-pine-tree-box',
		'mdi-pine-tree-fire',
		'mdi-seed',
		'mdi-seed-off',
		'mdi-seed-off-outline',
		'mdi-seed-outline',
		'mdi-seed-plus',
		'mdi-seed-plus-outline',
		'mdi-sheep',
		'mdi-silo',
		'mdi-silo-outline',
		'mdi-spray',
		'mdi-sprinkler',
		'mdi-sprinkler-fire',
		'mdi-sprinkler-variant',
		'mdi-sprout',
		'mdi-sprout-outline',
		'mdi-tire',
		'mdi-tractor',
		'mdi-tractor-variant',
		'mdi-tree',
		'mdi-tree-outline',
		'mdi-turkey',
		'mdi-water',
		'mdi-water-alert',
		'mdi-water-alert-outline',
		'mdi-water-pump',
		'mdi-water-pump-off',
		'mdi-water-sync',
		'mdi-watering-can',
		'mdi-watering-can-outline',
		'mdi-waves',
		'mdi-weather-cloudy',
		'mdi-weather-dust',
		'mdi-weather-fog',
		'mdi-weather-hail',
		'mdi-weather-hazy',
		'mdi-weather-hurricane',
		'mdi-weather-lightning',
		'mdi-weather-pouring',
		'mdi-weather-rainy',
	],
	alert: [
		'mdi-account-alert',
		'mdi-account-alert-outline',
		'mdi-airplane-alert',
		'mdi-alert',
		'mdi-alert-box',
		'mdi-alert-box-outline',
		'mdi-alert-circle',
		'mdi-alert-circle-check',
		'mdi-alert-circle-check-outline',
		'mdi-alert-circle-outline',
		'mdi-alert-decagram',
		'mdi-alert-decagram-outline',
		'mdi-alert-minus',
		'mdi-alert-minus-outline',
		'mdi-alert-octagon',
		'mdi-alert-octagon-outline',
		'mdi-alert-octagram',
		'mdi-alert-octagram-outline',
		'mdi-alert-outline',
		'mdi-alert-plus',
		'mdi-alert-plus-outline',
		'mdi-alert-remove',
		'mdi-alert-remove-outline',
		'mdi-alert-rhombus',
		'mdi-alert-rhombus-outline',
		'mdi-archive-alert',
		'mdi-archive-alert-outline',
		'mdi-badge-account-alert',
		'mdi-badge-account-alert-outline',
		'mdi-battery-alert',
		'mdi-battery-alert-bluetooth',
		'mdi-battery-alert-variant',
		'mdi-battery-alert-variant-outline',
		'mdi-battery-charging-wireless-alert',
		'mdi-beaker-alert',
		'mdi-beaker-alert-outline',
		'mdi-bell-alert',
		'mdi-bell-alert-outline',
		'mdi-book-alert',
		'mdi-book-alert-outline',
		'mdi-boom-gate-alert',
		'mdi-boom-gate-alert-outline',
		'mdi-bus-alert',
		'mdi-calendar-alert',
		'mdi-calendar-alert-outline',
		'mdi-car-brake-alert',
		'mdi-car-light-alert',
		'mdi-car-tire-alert',
		'mdi-chat-alert',
		'mdi-chat-alert-outline',
		'mdi-chili-alert',
		'mdi-chili-alert-outline',
		'mdi-clipboard-alert',
		'mdi-clipboard-alert-outline',
		'mdi-clock-alert',
		'mdi-clock-alert-outline',
		'mdi-cloud-alert',
		'mdi-comment-alert',
		'mdi-comment-alert-outline',
		'mdi-content-save-alert',
		'mdi-content-save-alert-outline',
		'mdi-cookie-alert',
		'mdi-cookie-alert-outline',
		'mdi-database-alert',
		'mdi-database-alert-outline',
		'mdi-delete-alert',
		'mdi-delete-alert-outline',
		'mdi-disc-alert',
		'mdi-dishwasher-alert',
		'mdi-email-alert',
		'mdi-email-alert-outline',
		'mdi-fan-alert',
		'mdi-file-alert',
		'mdi-file-alert-outline',
		'mdi-file-document-alert',
		'mdi-file-document-alert-outline',
		'mdi-fire-alert',
		'mdi-fire-hydrant-alert',
		'mdi-flash-alert',
		'mdi-flash-alert-outline',
		'mdi-folder-alert',
		'mdi-folder-alert-outline',
		'mdi-fridge-alert',
		'mdi-fridge-alert-outline',
		'mdi-fridge-industrial-alert',
		'mdi-fridge-industrial-alert-outline',
		'mdi-fridge-variant-alert',
		'mdi-fridge-variant-alert-outline',
		'mdi-fuse-alert',
		'mdi-garage-alert',
		'mdi-garage-alert-variant',
		'mdi-gate-alert',
		'mdi-head-alert',
		'mdi-head-alert-outline',
		'mdi-home-alert',
		'mdi-home-alert-outline',
		'mdi-kettle-alert',
		'mdi-kettle-alert-outline',
		'mdi-key-alert',
		'mdi-key-alert-outline',
		'mdi-lightbulb-alert',
		'mdi-lightbulb-alert-outline',
		'mdi-lock-alert',
		'mdi-lock-alert-outline',
		'mdi-lock-open-alert',
		'mdi-lock-open-alert-outline',
		'mdi-map-marker-alert',
		'mdi-map-marker-alert-outline',
		'mdi-message-alert',
		'mdi-message-alert-outline',
		'mdi-microsoft-xbox-controller-battery-alert',
		'mdi-network-strength-1-alert',
		'mdi-network-strength-2-alert',
		'mdi-network-strength-3-alert',
		'mdi-network-strength-4-alert',
		'mdi-note-alert',
		'mdi-note-alert-outline',
		'mdi-phone-alert',
		'mdi-phone-alert-outline',
		'mdi-printer-3d-nozzle-alert',
		'mdi-printer-3d-nozzle-alert-outline',
		'mdi-printer-alert',
		'mdi-progress-alert',
		'mdi-reload-alert',
		'mdi-restart-alert',
		'mdi-restore-alert',
		'mdi-robot-vacuum-alert',
		'mdi-robot-vacuum-variant-alert',
		'mdi-shield-alert',
		'mdi-shield-alert-outline',
		'mdi-sim-alert',
		'mdi-sim-alert-outline',
		'mdi-smoke-detector-alert',
		'mdi-smoke-detector-alert-outline',
		'mdi-smoke-detector-variant-alert',
		'mdi-snowflake-alert',
		'mdi-sticker-alert',
		'mdi-sticker-alert-outline',
		'mdi-store-alert',
		'mdi-store-alert-outline',
		'mdi-subway-alert-variant',
		'mdi-sync-alert',
		'mdi-table-alert',
		'mdi-thermometer-alert',
		'mdi-timeline-alert',
		'mdi-timeline-alert-outline',
		'mdi-timer-alert',
		'mdi-timer-alert-outline',
		'mdi-tray-alert',
		'mdi-truck-alert',
		'mdi-truck-alert-outline',
		'mdi-tumble-dryer-alert',
		'mdi-washing-machine-alert',
		'mdi-water-alert',
		'mdi-water-alert-outline',
		'mdi-water-boiler-alert',
		'mdi-water-percent-alert',
		'mdi-weather-cloudy-alert',
		'mdi-weather-sunny-alert',
		'mdi-wifi-alert',
		'mdi-wifi-strength-1-alert',
		'mdi-wifi-strength-2-alert',
		'mdi-wifi-strength-3-alert',
		'mdi-wifi-strength-4-alert',
		'mdi-wifi-strength-alert-outline',
		'mdi-wind-turbine-alert',
		'mdi-window-shutter-alert',
		'mdi-wiper-wash-alert',
	],
	'aphabatic numeric': [ 
		'mdi-abjad-arabic',
		'mdi-abjad-hebrew',
		'mdi-abugida-devanagari',
		'mdi-abugida-thai',
		'mdi-alpha',
		'mdi-alpha-a',
		'mdi-alpha-a-box',
		'mdi-alpha-a-box-outline',
		'mdi-alpha-a-circle',
		'mdi-alpha-a-circle-outline',
		'mdi-alpha-b',
		'mdi-alpha-b-box',
		'mdi-alpha-b-box-outline',
		'mdi-alpha-b-circle',
		'mdi-alpha-b-circle-outline',
		'mdi-alpha-c',
		'mdi-alpha-c-box',
		'mdi-alpha-c-box-outline',
		'mdi-alpha-c-circle',
		'mdi-alpha-c-circle-outline',
		'mdi-alpha-d',
		'mdi-alpha-d-box',
		'mdi-alpha-d-box-outline',
		'mdi-alpha-d-circle',
		'mdi-alpha-d-circle-outline',
		'mdi-alpha-e',
		'mdi-alpha-e-box',
		'mdi-alpha-e-box-outline',
		'mdi-alpha-e-circle',
		'mdi-alpha-e-circle-outline',
		'mdi-alpha-f',
		'mdi-alpha-f-box',
		'mdi-alpha-f-box-outline',
		'mdi-alpha-f-circle',
		'mdi-alpha-f-circle-outline',
		'mdi-alpha-g',
		'mdi-alpha-g-box',
		'mdi-alpha-g-box-outline',
		'mdi-alpha-g-circle',
		'mdi-alpha-g-circle-outline',
		'mdi-alpha-h',
		'mdi-alpha-h-box',
		'mdi-alpha-h-box-outline',
		'mdi-alpha-h-circle',
		'mdi-alpha-h-circle-outline',
		'mdi-alpha-i',
		'mdi-alpha-i-box',
		'mdi-alpha-i-box-outline',
		'mdi-alpha-i-circle',
		'mdi-alpha-i-circle-outline',
		'mdi-alpha-j',
		'mdi-alpha-j-box',
		'mdi-alpha-j-box-outline',
		'mdi-alpha-j-circle',
		'mdi-alpha-j-circle-outline',
		'mdi-alpha-k',
		'mdi-alpha-k-box',
		'mdi-alpha-k-box-outline',
		'mdi-alpha-k-circle',
		'mdi-alpha-k-circle-outline',
		'mdi-alpha-l',
		'mdi-alpha-l-box',
		'mdi-alpha-l-box-outline',
		'mdi-alpha-l-circle',
		'mdi-alpha-l-circle-outline',
		'mdi-alpha-m',
		'mdi-alpha-m-box',
		'mdi-alpha-m-box-outline',
		'mdi-alpha-m-circle',
		'mdi-alpha-m-circle-outline',
		'mdi-alpha-n',
		'mdi-alpha-n-box',
		'mdi-alpha-n-box-outline',
		'mdi-alpha-n-circle',
		'mdi-alpha-n-circle-outline',
		'mdi-alpha-o',
		'mdi-alpha-o-box',
		'mdi-alpha-o-box-outline',
		'mdi-alpha-o-circle',
		'mdi-alpha-o-circle-outline',
		'mdi-alpha-p',
		'mdi-alpha-p-box',
		'mdi-alpha-p-box-outline',
		'mdi-alpha-p-circle',
		'mdi-alpha-p-circle-outline',
		'mdi-alpha-q',
		'mdi-alpha-q-box',
		'mdi-alpha-q-box-outline',
		'mdi-alpha-q-circle',
		'mdi-alpha-q-circle-outline',
		'mdi-alpha-r',
		'mdi-alpha-r-box',
		'mdi-alpha-r-box-outline',
		'mdi-alpha-r-circle',
		'mdi-alpha-r-circle-outline',
		'mdi-alpha-s',
		'mdi-alpha-s-box',
		'mdi-alpha-s-box-outline',
		'mdi-alpha-s-circle',
		'mdi-alpha-s-circle-outline',
		'mdi-alpha-t',
		'mdi-alpha-t-box',
		'mdi-alpha-t-box-outline',
		'mdi-alpha-t-circle',
		'mdi-alpha-t-circle-outline',
		'mdi-alpha-u',
		'mdi-alpha-u-box',
		'mdi-alpha-u-box-outline',
		'mdi-alpha-u-circle',
		'mdi-alpha-u-circle-outline',
		'mdi-alpha-v',
		'mdi-alpha-v-box',
		'mdi-alpha-v-box-outline',
		'mdi-alpha-v-circle',
		'mdi-alpha-v-circle-outline',
		'mdi-alpha-w',
		'mdi-alpha-w-box',
		'mdi-alpha-w-box-outline',
		'mdi-alpha-w-circle',
		'mdi-alpha-w-circle-outline',
		'mdi-alpha-x',
		'mdi-alpha-x-box',
		'mdi-alpha-x-box-outline',
		'mdi-alpha-x-circle',
		'mdi-alpha-x-circle-outline',
		'mdi-alpha-y',
		'mdi-alpha-y-box',
		'mdi-alpha-y-box-outline',
		'mdi-alpha-y-circle',
		'mdi-alpha-y-circle-outline',
		'mdi-alpha-z',
		'mdi-alpha-z-box',
		'mdi-alpha-z-box-outline',
		'mdi-alpha-z-circle',
		'mdi-alpha-z-circle-outline',
		'mdi-alphabet-aurebesh',
		'mdi-alphabet-cyrillic',
		'mdi-alphabet-greek',
		'mdi-alphabet-latin',
		'mdi-alphabet-piqad',
		'mdi-alphabet-tengwar',
		'mdi-alphabetical',
		'mdi-alphabetical-off',
		'mdi-alphabetical-variant',
		'mdi-alphabetical-variant-off',
		'mdi-beta',
		'mdi-delta',
		'mdi-epsilon',
		'mdi-gamma',
		'mdi-ideogram-cjk',
		'mdi-ideogram-cjk-variant',
		'mdi-numeric',
		'mdi-numeric-0',
		'mdi-numeric-0-box',
		'mdi-numeric-0-box-multiple',
		'mdi-numeric-0-box-multiple-outline',
		'mdi-numeric-0-box-outline',
		'mdi-numeric-0-circle',
		'mdi-numeric-0-circle-outline',
		'mdi-numeric-1',
		'mdi-numeric-1-box',
		'mdi-numeric-1-box-multiple',
		'mdi-numeric-1-box-multiple-outline',
		'mdi-numeric-1-box-outline',
		'mdi-numeric-1-circle',
		'mdi-numeric-1-circle-outline',
		'mdi-numeric-10',
		'mdi-numeric-10-box',
		'mdi-numeric-10-box-multiple',
		'mdi-numeric-10-box-multiple-outline',
		'mdi-numeric-10-box-outline',
		'mdi-numeric-10-circle',
		'mdi-numeric-10-circle-outline',
		'mdi-numeric-2',
		'mdi-numeric-2-box',
		'mdi-numeric-2-box-multiple',
		'mdi-numeric-2-box-multiple-outline',
		'mdi-numeric-2-box-outline',
		'mdi-numeric-2-circle',
		'mdi-numeric-2-circle-outline',
		'mdi-numeric-3',
		'mdi-numeric-3-box',
		'mdi-numeric-3-box-multiple',
		'mdi-numeric-3-box-multiple-outline',
		'mdi-numeric-3-box-outline',
		'mdi-numeric-3-circle',
		'mdi-numeric-3-circle-outline',
		'mdi-numeric-4',
		'mdi-numeric-4-box',
		'mdi-numeric-4-box-multiple',
		'mdi-numeric-4-box-multiple-outline',
		'mdi-numeric-4-box-outline',
		'mdi-numeric-4-circle',
		'mdi-numeric-4-circle-outline',
		'mdi-numeric-5',
		'mdi-numeric-5-box',
		'mdi-numeric-5-box-multiple',
		'mdi-numeric-5-box-multiple-outline',
		'mdi-numeric-5-box-outline',
		'mdi-numeric-5-circle',
		'mdi-numeric-5-circle-outline',
		'mdi-numeric-6',
		'mdi-numeric-6-box',
		'mdi-numeric-6-box-multiple',
		'mdi-numeric-6-box-multiple-outline',
		'mdi-numeric-6-box-outline',
		'mdi-numeric-6-circle',
		'mdi-numeric-6-circle-outline',
		'mdi-numeric-7',
		'mdi-numeric-7-box',
		'mdi-numeric-7-box-multiple',
		'mdi-numeric-7-box-multiple-outline',
		'mdi-numeric-7-box-outline',
		'mdi-numeric-7-circle',
		'mdi-numeric-7-circle-outline',
		'mdi-numeric-8',
		'mdi-numeric-8-box',
		'mdi-numeric-8-box-multiple',
		'mdi-numeric-8-box-multiple-outline',
		'mdi-numeric-8-box-outline',
		'mdi-numeric-8-circle',
		'mdi-numeric-8-circle-outline',
		'mdi-numeric-9',
		'mdi-numeric-9-box',
		'mdi-numeric-9-box-multiple',
		'mdi-numeric-9-box-multiple-outline',
		'mdi-numeric-9-box-outline',
		'mdi-numeric-9-circle',
		'mdi-numeric-9-circle-outline',
		'mdi-numeric-9-plus',
		'mdi-numeric-9-plus-box',
		'mdi-numeric-9-plus-box-multiple',
		'mdi-numeric-9-plus-box-multiple-outline',
		'mdi-numeric-9-plus-box-outline',
		'mdi-numeric-9-plus-circle',
		'mdi-numeric-9-plus-circle-outline',
		'mdi-numeric-negative-1',
		'mdi-numeric-off',
		'mdi-numeric-positive-1',
		'mdi-roman-numeral-1',
		'mdi-roman-numeral-10',
		'mdi-roman-numeral-2',
		'mdi-roman-numeral-3',
		'mdi-roman-numeral-4',
		'mdi-roman-numeral-5',
		'mdi-roman-numeral-6',
		'mdi-roman-numeral-7',
		'mdi-roman-numeral-8',
		'mdi-roman-numeral-9',
		'mdi-syllabary-hangul',
		'mdi-syllabary-hiragana',
		'mdi-syllabary-katakana',
		'mdi-syllabary-katakana-halfwidth',
	],
	arrange: [
		'mdi-arrange-bring-forward',
		'mdi-arrange-bring-to-front',
		'mdi-arrange-send-backward',
		'mdi-arrange-send-to-back',
		'mdi-flip-horizontal',
		'mdi-flip-to-back',
		'mdi-flip-to-front',
		'mdi-flip-vertical',
		'mdi-vector-arrange-above',
		'mdi-vector-arrange-below',
	],
	arrow: [
		'mdi-arrow-all',
		'mdi-arrow-bottom-left',
		'mdi-arrow-bottom-left-bold-box',
		'mdi-arrow-bottom-left-bold-box-outline',
		'mdi-arrow-bottom-left-bold-outline',
		'mdi-arrow-bottom-left-thick',
		'mdi-arrow-bottom-left-thin',
		'mdi-arrow-bottom-left-thin-circle-outline',
		'mdi-arrow-bottom-right',
		'mdi-arrow-bottom-right-bold-box',
		'mdi-arrow-bottom-right-bold-box-outline',
		'mdi-arrow-bottom-right-bold-outline',
		'mdi-arrow-bottom-right-thick',
		'mdi-arrow-bottom-right-thin',
		'mdi-arrow-bottom-right-thin-circle-outline',
		'mdi-arrow-collapse',
		'mdi-arrow-collapse-all',
		'mdi-arrow-collapse-down',
		'mdi-arrow-collapse-horizontal',
		'mdi-arrow-collapse-left',
		'mdi-arrow-collapse-right',
		'mdi-arrow-collapse-up',
		'mdi-arrow-collapse-vertical',
		'mdi-arrow-decision',
		'mdi-arrow-decision-outline',
		'mdi-arrow-down',
		'mdi-arrow-down-bold',
		'mdi-arrow-down-bold-box',
		'mdi-arrow-down-bold-box-outline',
		'mdi-arrow-down-bold-circle',
		'mdi-arrow-down-bold-circle-outline',
		'mdi-arrow-down-bold-hexagon-outline',
		'mdi-arrow-down-bold-outline',
		'mdi-arrow-down-box',
		'mdi-arrow-down-circle',
		'mdi-arrow-down-circle-outline',
		'mdi-arrow-down-drop-circle',
		'mdi-arrow-down-drop-circle-outline',
		'mdi-arrow-down-left',
		'mdi-arrow-down-left-bold',
		'mdi-arrow-down-right',
		'mdi-arrow-down-thick',
		'mdi-arrow-down-thin',
		'mdi-arrow-down-thin-circle-outline',
		'mdi-arrow-expand',
		'mdi-arrow-expand-all',
		'mdi-arrow-expand-down',
		'mdi-arrow-expand-horizontal',
		'mdi-arrow-expand-left',
		'mdi-arrow-expand-right',
		'mdi-arrow-expand-up',
		'mdi-arrow-expand-vertical',
		'mdi-arrow-horizontal-lock',
		'mdi-arrow-left',
		'mdi-arrow-left-bold',
		'mdi-arrow-left-bold-box',
		'mdi-arrow-left-bold-box-outline',
		'mdi-arrow-left-bold-circle',
		'mdi-arrow-left-bold-circle-outline',
		'mdi-arrow-left-bold-hexagon-outline',
		'mdi-arrow-left-bold-outline',
		'mdi-arrow-left-box',
		'mdi-arrow-left-circle',
		'mdi-arrow-left-circle-outline',
		'mdi-arrow-left-drop-circle',
		'mdi-arrow-left-drop-circle-outline',
		'mdi-arrow-left-right',
		'mdi-arrow-left-right-bold',
		'mdi-arrow-left-right-bold-outline',
		'mdi-arrow-left-thick',
		'mdi-arrow-left-thin',
		'mdi-arrow-left-thin-circle-outline',
		'mdi-arrow-right',
		'mdi-arrow-right-bold',
		'mdi-arrow-right-bold-box',
		'mdi-arrow-right-bold-box-outline',
		'mdi-arrow-right-bold-circle',
		'mdi-arrow-right-bold-circle-outline',
		'mdi-arrow-right-bold-hexagon-outline',
		'mdi-arrow-right-bold-outline',
		'mdi-arrow-right-box',
		'mdi-arrow-right-circle',
		'mdi-arrow-right-circle-outline',
		'mdi-arrow-right-drop-circle',
		'mdi-arrow-right-drop-circle-outline',
		'mdi-arrow-right-thick',
		'mdi-arrow-right-thin',
		'mdi-arrow-right-thin-circle-outline',
		'mdi-arrow-split-horizontal',
		'mdi-arrow-split-vertical',
		'mdi-arrow-top-left',
		'mdi-arrow-top-left-bold-box',
		'mdi-arrow-top-left-bold-box-outline',
		'mdi-arrow-top-left-bold-outline',
		'mdi-arrow-top-left-bottom-right',
		'mdi-arrow-top-left-bottom-right-bold',
		'mdi-arrow-top-left-thick',
		'mdi-arrow-top-left-thin',
		'mdi-arrow-top-left-thin-circle-outline',
		'mdi-arrow-top-right',
		'mdi-arrow-top-right-bold-box',
		'mdi-arrow-top-right-bold-box-outline',
		'mdi-arrow-top-right-bold-outline',
		'mdi-arrow-top-right-bottom-left',
		'mdi-arrow-top-right-bottom-left-bold',
		'mdi-arrow-top-right-thick',
		'mdi-arrow-top-right-thin',
		'mdi-arrow-top-right-thin-circle-outline',
		'mdi-arrow-up',
		'mdi-arrow-up-bold',
		'mdi-arrow-up-bold-box',
		'mdi-arrow-up-bold-box-outline',
		'mdi-arrow-up-bold-circle',
		'mdi-arrow-up-bold-circle-outline',
		'mdi-arrow-up-bold-hexagon-outline',
		'mdi-arrow-up-bold-outline',
		'mdi-arrow-up-box',
		'mdi-arrow-up-circle',
		'mdi-arrow-up-circle-outline',
		'mdi-arrow-up-down',
		'mdi-arrow-up-down-bold',
		'mdi-arrow-up-down-bold-outline',
		'mdi-arrow-up-drop-circle',
		'mdi-arrow-up-drop-circle-outline',
		'mdi-arrow-up-thick',
		'mdi-arrow-up-thin',
		'mdi-arrow-up-thin-circle-outline',
		'mdi-arrow-vertical-lock',
		'mdi-autorenew',
		'mdi-autorenew-off',
		'mdi-axis-arrow',
		'mdi-axis-arrow-info',
		'mdi-axis-arrow-lock',
		'mdi-axis-x-arrow',
		'mdi-axis-x-arrow-lock',
		'mdi-axis-x-y-arrow-lock',
		'mdi-axis-y-arrow',
		'mdi-axis-y-arrow-lock',
		'mdi-axis-z-arrow',
		'mdi-axis-z-arrow-lock',
		'mdi-cached',
		'mdi-call-made',
		'mdi-call-merge',
		'mdi-call-missed',
		'mdi-call-received',
		'mdi-call-split',
		'mdi-chevron-double-down',
		'mdi-chevron-double-left',
		'mdi-chevron-double-right',
		'mdi-chevron-double-up',
		'mdi-chevron-down',
		'mdi-chevron-down-box',
		'mdi-chevron-down-box-outline',
		'mdi-chevron-down-circle',
		'mdi-chevron-down-circle-outline',
		'mdi-chevron-left',
		'mdi-chevron-left-box',
		'mdi-chevron-left-box-outline',
		'mdi-chevron-left-circle',
		'mdi-chevron-left-circle-outline',
		'mdi-chevron-right',
		'mdi-chevron-right-box',
		'mdi-chevron-right-box-outline',
		'mdi-chevron-right-circle',
		'mdi-chevron-right-circle-outline',
		'mdi-chevron-up',
		'mdi-chevron-up-box',
		'mdi-chevron-up-box-outline',
		'mdi-chevron-up-circle',
		'mdi-chevron-up-circle-outline',
		'mdi-compare-horizontal',
		'mdi-compare-vertical',
		'mdi-fit-to-page',
		'mdi-fit-to-page-outline',
		'mdi-forward',
		'mdi-menu-down',
		'mdi-menu-down-outline',
		'mdi-menu-left',
		'mdi-menu-right',
		'mdi-menu-swap',
		'mdi-menu-swap-outline',
		'mdi-menu-up',
		'mdi-menu-up-outline',
		'mdi-middleware',
		'mdi-middleware-outline',
		'mdi-publish-off',
		'mdi-refresh',
		'mdi-reiterate',
		'mdi-reload',
		'mdi-repeat',
		'mdi-repeat-variant',
		'mdi-reply',
		'mdi-reply-all',
		'mdi-reply-all-outline',
		'mdi-reply-circle',
		'mdi-reply-outline',
		'mdi-restore',
		'mdi-rotate-360',
		'mdi-rotate-left',
		'mdi-rotate-right',
		'mdi-share',
		'mdi-share-circle',
		'mdi-share-off',
		'mdi-share-off-outline',
		'mdi-share-outline',
		'mdi-shuffle',
		'mdi-shuffle-disabled',
		'mdi-shuffle-variant',
		'mdi-stretch-to-page',
		'mdi-stretch-to-page-outline',
		'mdi-subdirectory-arrow-left',
		'mdi-subdirectory-arrow-right',
		'mdi-swap-horizontal',
		'mdi-swap-horizontal-bold',
		'mdi-swap-horizontal-circle',
		'mdi-swap-horizontal-circle-outline',
		'mdi-swap-horizontal-variant',
		'mdi-swap-vertical',
		'mdi-swap-vertical-bold',
		'mdi-swap-vertical-circle',
		'mdi-swap-vertical-circle-outline',
		'mdi-swap-vertical-variant',
		'mdi-transfer-down',
		'mdi-transfer-left',
		'mdi-transfer-right',
		'mdi-transfer-up',
		'mdi-tray-arrow-down',
		'mdi-tray-arrow-up',
	],
	automative: [
		'mdi-air-conditioner',
		'mdi-airbag',
		'mdi-alpha-d',
		'mdi-alpha-n',
		'mdi-alpha-p',
		'mdi-alpha-r',
		'mdi-arrow-left-bold',
		'mdi-arrow-left-bold-outline',
		'mdi-arrow-right-bold',
		'mdi-arrow-right-bold-outline',
		'mdi-battery',
		'mdi-battery-10',
		'mdi-battery-20',
		'mdi-battery-30',
		'mdi-battery-40',
		'mdi-battery-50',
		'mdi-battery-60',
		'mdi-battery-70',
		'mdi-battery-80',
		'mdi-battery-90',
		'mdi-battery-charging',
		'mdi-battery-charging-10',
		'mdi-battery-charging-100',
		'mdi-battery-charging-20',
		'mdi-battery-charging-30',
		'mdi-battery-charging-40',
		'mdi-battery-charging-50',
		'mdi-battery-charging-60',
		'mdi-battery-charging-70',
		'mdi-battery-charging-80',
		'mdi-battery-charging-90',
		'mdi-battery-charging-outline',
		'mdi-battery-outline',
		'mdi-bugle',
		'mdi-car',
		'mdi-car-2-plus',
		'mdi-car-3-plus',
		'mdi-car-arrow-left',
		'mdi-car-arrow-right',
		'mdi-car-back',
		'mdi-car-battery',
		'mdi-car-brake-abs',
		'mdi-car-brake-alert',
		'mdi-car-brake-fluid-level',
		'mdi-car-brake-hold',
		'mdi-car-brake-low-pressure',
		'mdi-car-brake-parking',
		'mdi-car-brake-retarder',
		'mdi-car-brake-temperature',
		'mdi-car-brake-worn-linings',
		'mdi-car-child-seat',
		'mdi-car-clock',
		'mdi-car-clutch',
		'mdi-car-cog',
		'mdi-car-connected',
		'mdi-car-convertible',
		'mdi-car-coolant-level',
		'mdi-car-cruise-control',
		'mdi-car-defrost-front',
		'mdi-car-defrost-rear',
		'mdi-car-door',
		'mdi-car-door-lock',
		'mdi-car-electric',
		'mdi-car-electric-outline',
		'mdi-car-emergency',
		'mdi-car-esp',
		'mdi-car-estate',
		'mdi-car-hatchback',
		'mdi-car-info',
		'mdi-car-key',
		'mdi-car-lifted-pickup',
		'mdi-car-light-alert',
		'mdi-car-light-dimmed',
		'mdi-car-light-fog',
		'mdi-car-light-high',
		'mdi-car-limousine',
		'mdi-car-multiple',
		'mdi-car-off',
		'mdi-car-outline',
		'mdi-car-parking-lights',
		'mdi-car-pickup',
		'mdi-car-search',
		'mdi-car-search-outline',
		'mdi-car-seat',
		'mdi-car-seat-cooler',
		'mdi-car-seat-heater',
		'mdi-car-select',
		'mdi-car-settings',
		'mdi-car-shift-pattern',
		'mdi-car-side',
		'mdi-car-speed-limiter',
		'mdi-car-sports',
		'mdi-car-tire-alert',
		'mdi-car-traction-control',
		'mdi-car-turbocharger',
		'mdi-car-wash',
		'mdi-car-windshield',
		'mdi-car-windshield-outline',
		'mdi-car-wireless',
		'mdi-car-wrench',
		'mdi-caravan',
		'mdi-cellphone-nfc',
		'mdi-coolant-temperature',
		'mdi-counter',
		'mdi-engine',
		'mdi-engine-off',
		'mdi-engine-off-outline',
		'mdi-engine-outline',
		'mdi-ev-plug-ccs1',
		'mdi-ev-plug-ccs2',
		'mdi-ev-plug-chademo',
		'mdi-ev-plug-tesla',
		'mdi-ev-plug-type1',
		'mdi-ev-plug-type2',
		'mdi-ev-station',
		'mdi-fan',
		'mdi-fan-off',
		'mdi-fuel',
		'mdi-fuel-cell',
		'mdi-fuse',
		'mdi-fuse-alert',
		'mdi-fuse-blade',
		'mdi-fuse-off',
		'mdi-gas-station',
		'mdi-gauge',
		'mdi-gauge-empty',
		'mdi-gauge-full',
		'mdi-gauge-low',
		'mdi-hazard-lights',
		'mdi-hydraulic-oil-level',
		'mdi-hydraulic-oil-temperature',
		'mdi-hydrogen-station',
		'mdi-induction',
		'mdi-key',
		'mdi-key-chain',
		'mdi-key-chain-variant',
		'mdi-key-variant',
		'mdi-liquid-spot',
		'mdi-lock',
		'mdi-lock-open',
		'mdi-lock-open-outline',
		'mdi-lock-outline',
		'mdi-oil',
		'mdi-oil-level',
		'mdi-oil-temperature',
		'mdi-piston',
		'mdi-refresh-auto',
		'mdi-reload',
		'mdi-seatbelt',
		'mdi-shield-car',
		'mdi-smoking',
		'mdi-snowflake',
		'mdi-speedometer',
		'mdi-speedometer-medium',
		'mdi-speedometer-slow',
		'mdi-steering',
		'mdi-steering-off',
		'mdi-thermometer',
		'mdi-thermometer-bluetooth',
		'mdi-tire',
		'mdi-truck-flatbed',
		'mdi-wiper-wash',
		'mdi-wiper-wash-alert',
	],
	banking: [
		'mdi-account-cash',
		'mdi-account-cash-outline',
		'mdi-account-credit-card',
		'mdi-account-credit-card-outline',
		'mdi-bank',
		'mdi-bank-check',
		'mdi-bank-circle',
		'mdi-bank-circle-outline',
		'mdi-bank-minus',
		'mdi-bank-off',
		'mdi-bank-off-outline',
		'mdi-bank-outline',
		'mdi-bank-plus',
		'mdi-bank-remove',
		'mdi-bank-transfer',
		'mdi-bank-transfer-in',
		'mdi-bank-transfer-out',
		'mdi-bitcoin',
		'mdi-cash',
		'mdi-cash-100',
		'mdi-cash-check',
		'mdi-cash-clock',
		'mdi-cash-fast',
		'mdi-cash-lock',
		'mdi-cash-lock-open',
		'mdi-cash-marker',
		'mdi-cash-minus',
		'mdi-cash-multiple',
		'mdi-cash-plus',
		'mdi-cash-refund',
		'mdi-cash-register',
		'mdi-cash-remove',
		'mdi-cash-sync',
		'mdi-circle-multiple',
		'mdi-circle-multiple-outline',
		'mdi-credit-card',
		'mdi-credit-card-check',
		'mdi-credit-card-check-outline',
		'mdi-credit-card-chip',
		'mdi-credit-card-chip-outline',
		'mdi-credit-card-clock',
		'mdi-credit-card-clock-outline',
		'mdi-credit-card-edit',
		'mdi-credit-card-edit-outline',
		'mdi-credit-card-fast',
		'mdi-credit-card-fast-outline',
		'mdi-credit-card-lock',
		'mdi-credit-card-lock-outline',
		'mdi-credit-card-marker',
		'mdi-credit-card-marker-outline',
		'mdi-credit-card-minus',
		'mdi-credit-card-minus-outline',
		'mdi-credit-card-multiple',
		'mdi-credit-card-multiple-outline',
		'mdi-credit-card-off',
		'mdi-credit-card-off-outline',
		'mdi-credit-card-outline',
		'mdi-credit-card-plus',
		'mdi-credit-card-plus-outline',
		'mdi-credit-card-refresh',
		'mdi-credit-card-refresh-outline',
		'mdi-credit-card-refund',
		'mdi-credit-card-refund-outline',
		'mdi-credit-card-remove',
		'mdi-credit-card-remove-outline',
		'mdi-credit-card-scan',
		'mdi-credit-card-scan-outline',
		'mdi-credit-card-search',
		'mdi-credit-card-search-outline',
		'mdi-credit-card-settings',
		'mdi-credit-card-settings-outline',
		'mdi-credit-card-sync',
		'mdi-credit-card-sync-outline',
		'mdi-credit-card-wireless',
		'mdi-credit-card-wireless-off',
		'mdi-credit-card-wireless-off-outline',
		'mdi-credit-card-wireless-outline',
		'mdi-currency-bdt',
		'mdi-currency-brl',
		'mdi-currency-btc',
		'mdi-currency-cny',
		'mdi-currency-eth',
		'mdi-currency-eur',
		'mdi-currency-eur-off',
		'mdi-currency-fra',
		'mdi-currency-gbp',
		'mdi-currency-ils',
		'mdi-currency-inr',
		'mdi-currency-jpy',
		'mdi-currency-krw',
		'mdi-currency-kzt',
		'mdi-currency-mnt',
		'mdi-currency-ngn',
		'mdi-currency-php',
		'mdi-currency-rial',
		'mdi-currency-rub',
		'mdi-currency-rupee',
		'mdi-currency-sign',
		'mdi-currency-thb',
		'mdi-currency-try',
		'mdi-currency-twd',
		'mdi-currency-uah',
		'mdi-currency-usd',
		'mdi-currency-usd-off',
		'mdi-file-sign',
		'mdi-finance',
		'mdi-hand-coin',
		'mdi-hand-coin-outline',
		'mdi-integrated-circuit-chip',
		'mdi-litecoin',
		'mdi-network-pos',
		'mdi-piggy-bank',
		'mdi-piggy-bank-outline',
		'mdi-safe',
		'mdi-wallet',
		'mdi-wallet-giftcard',
		'mdi-wallet-outline',
		'mdi-wallet-plus',
		'mdi-wallet-plus-outline',
	],
	battery: [
		'mdi-battery',
		'mdi-battery-10',
		'mdi-battery-10-bluetooth',
		'mdi-battery-20',
		'mdi-battery-20-bluetooth',
		'mdi-battery-30',
		'mdi-battery-30-bluetooth',
		'mdi-battery-40',
		'mdi-battery-40-bluetooth',
		'mdi-battery-50',
		'mdi-battery-50-bluetooth',
		'mdi-battery-60',
		'mdi-battery-60-bluetooth',
		'mdi-battery-70',
		'mdi-battery-70-bluetooth',
		'mdi-battery-80',
		'mdi-battery-80-bluetooth',
		'mdi-battery-90',
		'mdi-battery-90-bluetooth',
		'mdi-battery-alert',
		'mdi-battery-alert-bluetooth',
		'mdi-battery-alert-variant',
		'mdi-battery-alert-variant-outline',
		'mdi-battery-arrow-down',
		'mdi-battery-arrow-down-outline',
		'mdi-battery-arrow-up',
		'mdi-battery-arrow-up-outline',
		'mdi-battery-bluetooth',
		'mdi-battery-bluetooth-variant',
		'mdi-battery-charging',
		'mdi-battery-charging-10',
		'mdi-battery-charging-100',
		'mdi-battery-charging-20',
		'mdi-battery-charging-30',
		'mdi-battery-charging-40',
		'mdi-battery-charging-50',
		'mdi-battery-charging-60',
		'mdi-battery-charging-70',
		'mdi-battery-charging-80',
		'mdi-battery-charging-90',
		'mdi-battery-charging-high',
		'mdi-battery-charging-low',
		'mdi-battery-charging-medium',
		'mdi-battery-charging-outline',
		'mdi-battery-charging-wireless',
		'mdi-battery-charging-wireless-10',
		'mdi-battery-charging-wireless-20',
		'mdi-battery-charging-wireless-30',
		'mdi-battery-charging-wireless-40',
		'mdi-battery-charging-wireless-50',
		'mdi-battery-charging-wireless-60',
		'mdi-battery-charging-wireless-70',
		'mdi-battery-charging-wireless-80',
		'mdi-battery-charging-wireless-90',
		'mdi-battery-charging-wireless-alert',
		'mdi-battery-charging-wireless-outline',
		'mdi-battery-check',
		'mdi-battery-check-outline',
		'mdi-battery-clock',
		'mdi-battery-clock-outline',
		'mdi-battery-heart',
		'mdi-battery-heart-outline',
		'mdi-battery-heart-variant',
		'mdi-battery-high',
		'mdi-battery-lock',
		'mdi-battery-lock-open',
		'mdi-battery-low',
		'mdi-battery-medium',
		'mdi-battery-minus',
		'mdi-battery-minus-outline',
		'mdi-battery-minus-variant',
		'mdi-battery-negative',
		'mdi-battery-off',
		'mdi-battery-off-outline',
		'mdi-battery-outline',
		'mdi-battery-plus',
		'mdi-battery-plus-outline',
		'mdi-battery-plus-variant',
		'mdi-battery-positive',
		'mdi-battery-remove',
		'mdi-battery-remove-outline',
		'mdi-battery-sync',
		'mdi-battery-sync-outline',
		'mdi-battery-unknown',
		'mdi-battery-unknown-bluetooth',
		'mdi-car-battery',
		'mdi-current-dc',
		'mdi-home-battery',
		'mdi-home-battery-outline',
		'mdi-microsoft-xbox-controller-battery-alert',
		'mdi-microsoft-xbox-controller-battery-charging',
		'mdi-microsoft-xbox-controller-battery-empty',
		'mdi-microsoft-xbox-controller-battery-full',
		'mdi-microsoft-xbox-controller-battery-low',
		'mdi-microsoft-xbox-controller-battery-medium',
		'mdi-microsoft-xbox-controller-battery-unknown',
	],
	brand: [
		'mdi-android',
		'mdi-android-studio',
		'mdi-angular',
		'mdi-angularjs',
		'mdi-ansible',
		'mdi-apache-kafka',
		'mdi-apple',
		'mdi-apple-finder',
		'mdi-apple-icloud',
		'mdi-apple-ios',
		'mdi-apple-safari',
		'mdi-arch',
		'mdi-artstation',
		'mdi-atlassian',
		'mdi-aws',
		'mdi-babel',
		'mdi-bitbucket',
		'mdi-bitcoin',
		'mdi-black-mesa',
		'mdi-blender-software',
		'mdi-bootstrap',
		'mdi-box',
		'mdi-bulma',
		'mdi-centos',
		'mdi-codepen',
		'mdi-cordova',
		'mdi-creative-commons',
		'mdi-cryengine',
		'mdi-debian',
		'mdi-dev-to',
		'mdi-deviantart',
		'mdi-digital-ocean',
		'mdi-disqus',
		'mdi-dlna',
		'mdi-docker',
		'mdi-dolby',
		'mdi-dot-net',
		'mdi-dropbox',
		'mdi-drupal',
		'mdi-electron-framework',
		'mdi-ember',
		'mdi-emby',
		'mdi-eslint',
		'mdi-ethereum',
		'mdi-evernote',
		'mdi-facebook',
		'mdi-facebook-gaming',
		'mdi-facebook-messenger',
		'mdi-facebook-workplace',
		'mdi-fedora',
		'mdi-firebase',
		'mdi-firefox',
		'mdi-folder-google-drive',
		'mdi-font-awesome',
		'mdi-freebsd',
		'mdi-gatsby',
		'mdi-gentoo',
		'mdi-git',
		'mdi-github',
		'mdi-gitlab',
		'mdi-gmail',
		'mdi-gnome',
		'mdi-gog',
		'mdi-goodreads',
		'mdi-google',
		'mdi-google-ads',
		'mdi-google-analytics',
		'mdi-google-assistant',
		'mdi-google-cardboard',
		'mdi-google-chrome',
		'mdi-google-circles',
		'mdi-google-circles-communities',
		'mdi-google-circles-extended',
		'mdi-google-circles-group',
		'mdi-google-classroom',
		'mdi-google-cloud',
		'mdi-google-drive',
		'mdi-google-earth',
		'mdi-google-fit',
		'mdi-google-glass',
		'mdi-google-hangouts',
		'mdi-google-keep',
		'mdi-google-lens',
		'mdi-google-maps',
		'mdi-google-play',
		'mdi-google-plus',
		'mdi-google-translate',
		'mdi-graphql',
		'mdi-home-assistant',
		'mdi-hulu',
		'mdi-humble-bundle',
		'mdi-instagram',
		'mdi-iobroker',
		'mdi-jabber',
		'mdi-jira',
		'mdi-jquery',
		'mdi-jsfiddle',
		'mdi-kickstarter',
		'mdi-kodi',
		'mdi-kubernetes',
		'mdi-language-c',
		'mdi-language-cpp',
		'mdi-language-csharp',
		'mdi-language-css3',
		'mdi-language-fortran',
		'mdi-language-go',
		'mdi-language-haskell',
		'mdi-language-html5',
		'mdi-language-java',
		'mdi-language-javascript',
		'mdi-language-kotlin',
		'mdi-language-lua',
		'mdi-language-markdown',
		'mdi-language-markdown-outline',
		'mdi-language-php',
		'mdi-language-python',
		'mdi-language-r',
		'mdi-language-ruby',
		'mdi-language-ruby-on-rails',
		'mdi-language-rust',
		'mdi-language-swift',
		'mdi-language-typescript',
		'mdi-language-xaml',
		'mdi-laravel',
		'mdi-lastpass',
		'mdi-linkedin',
		'mdi-linux',
		'mdi-linux-mint',
		'mdi-litecoin',
		'mdi-lumx',
		'mdi-manjaro',
		'mdi-mapbox',
		'mdi-mastodon',
		'mdi-material-design',
		'mdi-material-ui',
		'mdi-meteor',
		'mdi-microsoft',
		'mdi-microsoft-access',
		'mdi-microsoft-azure',
		'mdi-microsoft-azure-devops',
		'mdi-microsoft-bing',
		'mdi-microsoft-dynamics-365',
		'mdi-microsoft-edge',
		'mdi-microsoft-excel',
		'mdi-microsoft-internet-explorer',
		'mdi-microsoft-office',
		'mdi-microsoft-onedrive',
		'mdi-microsoft-onenote',
		'mdi-microsoft-outlook',
		'mdi-microsoft-powerpoint',
		'mdi-microsoft-sharepoint',
		'mdi-microsoft-teams',
		'mdi-microsoft-visual-studio',
		'mdi-microsoft-visual-studio-code',
		'mdi-microsoft-windows',
		'mdi-microsoft-windows-classic',
		'mdi-microsoft-word',
		'mdi-microsoft-xbox',
		'mdi-midi',
		'mdi-minecraft',
		'mdi-nativescript',
		'mdi-netflix',
		'mdi-nfc',
		'mdi-nintendo-switch',
		'mdi-nintendo-wii',
		'mdi-nintendo-wiiu',
		'mdi-nix',
		'mdi-nodejs',
		'mdi-npm',
		'mdi-nuxt',
		'mdi-oci',
		'mdi-odnoklassniki',
		'mdi-onepassword',
		'mdi-open-source-initiative',
		'mdi-openid',
		'mdi-opera',
		'mdi-origin',
		'mdi-pandora',
		'mdi-patreon',
		'mdi-pi-hole',
		'mdi-pinterest',
		'mdi-plex',
		'mdi-pokemon-go',
		'mdi-polymer',
		'mdi-qqchat',
		'mdi-react',
		'mdi-reddit',
		'mdi-rollupjs',
		'mdi-salesforce',
		'mdi-sass',
		'mdi-semantic-web',
		'mdi-simple-icons',
		'mdi-sina-weibo',
		'mdi-skype',
		'mdi-skype-business',
		'mdi-slack',
		'mdi-snapchat',
		'mdi-sony-playstation',
		'mdi-soundcloud',
		'mdi-spotify',
		'mdi-stack-exchange',
		'mdi-stack-overflow',
		'mdi-stackpath',
		'mdi-steam',
		'mdi-svg',
		'mdi-symfony',
		'mdi-tailwind',
		'mdi-teamviewer',
		'mdi-terraform',
		'mdi-trello',
		'mdi-twitch',
		'mdi-twitter',
		'mdi-ubisoft',
		'mdi-ubuntu',
		'mdi-umbraco',
		'mdi-unicode',
		'mdi-unity',
		'mdi-unreal',
		'mdi-vimeo',
		'mdi-vlc',
		'mdi-vuejs',
		'mdi-vuetify',
		'mdi-waze',
		'mdi-webpack',
		'mdi-webrtc',
		'mdi-wechat',
		'mdi-whatsapp',
		'mdi-wikipedia',
		'mdi-wordpress',
		'mdi-xamarin',
		'mdi-xmpp',
		'mdi-yahoo',
		'mdi-youtube',
		'mdi-youtube-gaming',
		'mdi-youtube-studio',
		'mdi-youtube-subscription',
		'mdi-youtube-tv',
		'mdi-z-wave',
		'mdi-zend',
		'mdi-zigbee',
	],
	phone: [
		'mdi-call-made',
		'mdi-call-merge',
		'mdi-call-missed',
		'mdi-call-received',
		'mdi-call-split',
		'mdi-cellphone',
		'mdi-cellphone-arrow-down',
		'mdi-cellphone-arrow-down-variant',
		'mdi-cellphone-basic',
		'mdi-cellphone-charging',
		'mdi-cellphone-check',
		'mdi-cellphone-cog',
		'mdi-cellphone-dock',
		'mdi-cellphone-information',
		'mdi-cellphone-key',
		'mdi-cellphone-link',
		'mdi-cellphone-link-off',
		'mdi-cellphone-lock',
		'mdi-cellphone-marker',
		'mdi-cellphone-message',
		'mdi-cellphone-message-off',
		'mdi-cellphone-nfc',
		'mdi-cellphone-nfc-off',
		'mdi-cellphone-off',
		'mdi-cellphone-play',
		'mdi-cellphone-remove',
		'mdi-cellphone-screenshot',
		'mdi-cellphone-settings',
		'mdi-cellphone-sound',
		'mdi-cellphone-text',
		'mdi-cellphone-wireless',
		'mdi-deskphone',
		'mdi-fax',
		'mdi-file-phone',
		'mdi-file-phone-outline',
		'mdi-monitor-cellphone',
		'mdi-monitor-cellphone-star',
		'mdi-network-strength-1',
		'mdi-network-strength-1-alert',
		'mdi-network-strength-2',
		'mdi-network-strength-2-alert',
		'mdi-network-strength-3',
		'mdi-network-strength-3-alert',
		'mdi-network-strength-4',
		'mdi-network-strength-4-alert',
		'mdi-network-strength-off',
		'mdi-network-strength-off-outline',
		'mdi-network-strength-outline',
		'mdi-phone',
		'mdi-phone-alert',
		'mdi-phone-alert-outline',
		'mdi-phone-bluetooth',
		'mdi-phone-bluetooth-outline',
		'mdi-phone-cancel',
		'mdi-phone-cancel-outline',
		'mdi-phone-check',
		'mdi-phone-check-outline',
		'mdi-phone-classic',
		'mdi-phone-clock',
		'mdi-phone-dial',
		'mdi-phone-dial-outline',
		'mdi-phone-forward',
		'mdi-phone-forward-outline',
		'mdi-phone-hangup',
		'mdi-phone-hangup-outline',
		'mdi-phone-in-talk',
		'mdi-phone-in-talk-outline',
		'mdi-phone-incoming',
		'mdi-phone-incoming-outgoing',
		'mdi-phone-incoming-outgoing-outline',
		'mdi-phone-incoming-outline',
		'mdi-phone-lock',
		'mdi-phone-lock-outline',
		'mdi-phone-log',
		'mdi-phone-log-outline',
		'mdi-phone-message',
		'mdi-phone-message-outline',
		'mdi-phone-minus',
		'mdi-phone-minus-outline',
		'mdi-phone-missed',
		'mdi-phone-missed-outline',
		'mdi-phone-off',
		'mdi-phone-off-outline',
		'mdi-phone-outgoing',
		'mdi-phone-outgoing-outline',
		'mdi-phone-outline',
		'mdi-phone-paused',
		'mdi-phone-paused-outline',
		'mdi-phone-plus',
		'mdi-phone-plus-outline',
		'mdi-phone-refresh',
		'mdi-phone-refresh-outline',
		'mdi-phone-remove',
		'mdi-phone-remove-outline',
		'mdi-phone-return',
		'mdi-phone-return-outline',
		'mdi-phone-ring',
		'mdi-phone-ring-outline',
		'mdi-phone-rotate-landscape',
		'mdi-phone-rotate-portrait',
		'mdi-phone-settings',
		'mdi-phone-settings-outline',
		'mdi-phone-sync',
		'mdi-phone-sync-outline',
		'mdi-phone-voip',
		'mdi-signal',
		'mdi-signal-2g',
		'mdi-signal-3g',
		'mdi-signal-4g',
		'mdi-signal-5g',
		'mdi-signal-cellular-1',
		'mdi-signal-cellular-2',
		'mdi-signal-cellular-3',
		'mdi-signal-cellular-outline',
		'mdi-signal-hspa',
		'mdi-signal-hspa-plus',
		'mdi-signal-off',
		'mdi-sim',
		'mdi-sim-alert',
		'mdi-sim-alert-outline',
		'mdi-sim-off',
		'mdi-sim-off-outline',
		'mdi-sim-outline',
		'mdi-tablet-cellphone',
		'mdi-tooltip-cellphone',
		'mdi-volume-high',
		'mdi-volume-low',
		'mdi-volume-medium',
		'mdi-volume-minus',
		'mdi-volume-mute',
		'mdi-volume-off',
		'mdi-volume-plus',
		'mdi-volume-source',
		'mdi-volume-variant-off',
		'mdi-volume-vibrate',
	],
	'date time': [
		'mdi-account-clock',
		'mdi-account-clock-outline',
		'mdi-airplane-clock',
		'mdi-alarm',
		'mdi-alarm-check',
		'mdi-alarm-multiple',
		'mdi-alarm-off',
		'mdi-alarm-plus',
		'mdi-alarm-snooze',
		'mdi-archive-clock',
		'mdi-archive-clock-outline',
		'mdi-av-timer',
		'mdi-battery-clock',
		'mdi-battery-clock-outline',
		'mdi-bed-clock',
		'mdi-book-clock',
		'mdi-book-clock-outline',
		'mdi-briefcase-clock',
		'mdi-briefcase-clock-outline',
		'mdi-bus-clock',
		'mdi-calendar',
		'mdi-calendar-account',
		'mdi-calendar-account-outline',
		'mdi-calendar-alert',
		'mdi-calendar-alert-outline',
		'mdi-calendar-arrow-left',
		'mdi-calendar-arrow-right',
		'mdi-calendar-badge',
		'mdi-calendar-badge-outline',
		'mdi-calendar-blank',
		'mdi-calendar-blank-multiple',
		'mdi-calendar-blank-outline',
		'mdi-calendar-check',
		'mdi-calendar-check-outline',
		'mdi-calendar-clock',
		'mdi-calendar-clock-outline',
		'mdi-calendar-collapse-horizontal',
		'mdi-calendar-collapse-horizontal-outline',
		'mdi-calendar-cursor',
		'mdi-calendar-cursor-outline',
		'mdi-calendar-edit',
		'mdi-calendar-edit-outline',
		'mdi-calendar-end',
		'mdi-calendar-end-outline',
		'mdi-calendar-expand-horizontal',
		'mdi-calendar-expand-horizontal-outline',
		'mdi-calendar-export',
		'mdi-calendar-export-outline',
		'mdi-calendar-filter',
		'mdi-calendar-filter-outline',
		'mdi-calendar-heart',
		'mdi-calendar-heart-outline',
		'mdi-calendar-import',
		'mdi-calendar-import-outline',
		'mdi-calendar-lock',
		'mdi-calendar-lock-open',
		'mdi-calendar-lock-open-outline',
		'mdi-calendar-lock-outline',
		'mdi-calendar-minus',
		'mdi-calendar-minus-outline',
		'mdi-calendar-month',
		'mdi-calendar-month-outline',
		'mdi-calendar-multiple',
		'mdi-calendar-multiple-check',
		'mdi-calendar-multiselect',
		'mdi-calendar-multiselect-outline',
		'mdi-calendar-outline',
		'mdi-calendar-plus',
		'mdi-calendar-plus-outline',
		'mdi-calendar-question',
		'mdi-calendar-question-outline',
		'mdi-calendar-range',
		'mdi-calendar-range-outline',
		'mdi-calendar-refresh',
		'mdi-calendar-refresh-outline',
		'mdi-calendar-remove',
		'mdi-calendar-remove-outline',
		'mdi-calendar-search',
		'mdi-calendar-search-outline',
		'mdi-calendar-star',
		'mdi-calendar-star-outline',
		'mdi-calendar-start',
		'mdi-calendar-start-outline',
		'mdi-calendar-sync',
		'mdi-calendar-sync-outline',
		'mdi-calendar-text',
		'mdi-calendar-text-outline',
		'mdi-calendar-today',
		'mdi-calendar-today-outline',
		'mdi-calendar-week',
		'mdi-calendar-week-begin',
		'mdi-calendar-week-begin-outline',
		'mdi-calendar-week-outline',
		'mdi-calendar-weekend',
		'mdi-calendar-weekend-outline',
		'mdi-camera-timer',
		'mdi-car-clock',
		'mdi-cash-clock',
		'mdi-clipboard-clock',
		'mdi-clipboard-clock-outline',
		'mdi-clipboard-text-clock',
		'mdi-clipboard-text-clock-outline',
		'mdi-clock',
		'mdi-clock-alert',
		'mdi-clock-alert-outline',
		'mdi-clock-check',
		'mdi-clock-check-outline',
		'mdi-clock-digital',
		'mdi-clock-edit',
		'mdi-clock-edit-outline',
		'mdi-clock-end',
		'mdi-clock-fast',
		'mdi-clock-in',
		'mdi-clock-minus',
		'mdi-clock-minus-outline',
		'mdi-clock-out',
		'mdi-clock-outline',
		'mdi-clock-plus',
		'mdi-clock-plus-outline',
		'mdi-clock-remove',
		'mdi-clock-remove-outline',
		'mdi-clock-start',
		'mdi-clock-time-eight',
		'mdi-clock-time-eight-outline',
		'mdi-clock-time-eleven',
		'mdi-clock-time-eleven-outline',
		'mdi-clock-time-five',
		'mdi-clock-time-five-outline',
		'mdi-clock-time-four',
		'mdi-clock-time-four-outline',
		'mdi-clock-time-nine',
		'mdi-clock-time-nine-outline',
		'mdi-clock-time-one',
		'mdi-clock-time-one-outline',
		'mdi-clock-time-seven',
		'mdi-clock-time-seven-outline',
		'mdi-clock-time-six',
		'mdi-clock-time-six-outline',
		'mdi-clock-time-ten',
		'mdi-clock-time-ten-outline',
		'mdi-clock-time-three',
		'mdi-clock-time-three-outline',
		'mdi-clock-time-twelve',
		'mdi-clock-time-twelve-outline',
		'mdi-clock-time-two',
		'mdi-clock-time-two-outline',
		'mdi-cookie-clock',
		'mdi-cookie-clock-outline',
		'mdi-credit-card-clock',
		'mdi-credit-card-clock-outline',
		'mdi-database-clock',
		'mdi-database-clock-outline',
		'mdi-delete-clock',
		'mdi-delete-clock-outline',
		'mdi-fan-clock',
		'mdi-file-clock',
		'mdi-file-clock-outline',
		'mdi-folder-clock',
		'mdi-folder-clock-outline',
		'mdi-history',
		'mdi-home-clock',
		'mdi-home-clock-outline',
		'mdi-hours-24',
		'mdi-lock-clock',
		'mdi-map-clock',
		'mdi-map-clock-outline',
		'mdi-message-text-clock',
		'mdi-message-text-clock-outline',
		'mdi-phone-clock',
		'mdi-progress-clock',
		'mdi-routes-clock',
		'mdi-send-clock',
		'mdi-send-clock-outline',
		'mdi-sort-calendar-ascending',
		'mdi-sort-calendar-descending',
		'mdi-sort-clock-ascending',
		'mdi-sort-clock-ascending-outline',
		'mdi-sort-clock-descending',
		'mdi-sort-clock-descending-outline',
		'mdi-store-clock-outline',
		'mdi-sun-clock-outline',
		'mdi-table-clock',
		'mdi-timelapse',
		'mdi-timeline-clock',
		'mdi-timeline-clock-outline',
		'mdi-timer',
		'mdi-timer-10',
		'mdi-timer-3',
		'mdi-timer-alert',
		'mdi-timer-alert-outline',
		'mdi-timer-cancel',
		'mdi-timer-cancel-outline',
		'mdi-timer-check',
		'mdi-timer-check-outline',
		'mdi-timer-cog',
		'mdi-timer-cog-outline',
		'mdi-timer-edit',
		'mdi-timer-edit-outline',
		'mdi-timer-lock',
		'mdi-timer-lock-open',
		'mdi-timer-lock-open-outline',
		'mdi-timer-lock-outline',
		'mdi-timer-marker',
		'mdi-timer-marker-outline',
		'mdi-timer-minus',
		'mdi-timer-minus-outline',
		'mdi-timer-music',
		'mdi-timer-music-outline',
		'mdi-timer-off',
		'mdi-timer-off-outline',
		'mdi-timer-outline',
		'mdi-timer-pause',
		'mdi-timer-pause-outline',
		'mdi-timer-play',
		'mdi-timer-play-outline',
		'mdi-timer-plus',
		'mdi-timer-plus-outline',
		'mdi-timer-refresh',
		'mdi-timer-refresh-outline',
		'mdi-timer-remove',
		'mdi-timer-remove-outline',
		'mdi-timer-sand',
		'mdi-timer-sand-complete',
		'mdi-timer-sand-empty',
		'mdi-timer-sand-full',
		'mdi-timer-sand-paused',
		'mdi-timer-settings',
		'mdi-timer-settings-outline',
		'mdi-timer-star',
		'mdi-timer-star-outline',
		'mdi-timer-stop',
		'mdi-timer-stop-outline',
		'mdi-timer-sync',
		'mdi-timer-sync-outline',
		'mdi-timetable',
		'mdi-update',
		'mdi-web-clock',
		'mdi-wrench-clock',
		'mdi-wrench-clock-outline',
	],
	'files folders': [
		'mdi-clipboard-file',
		'mdi-clipboard-file-outline',
		'mdi-file',
		'mdi-file-account',
		'mdi-file-account-outline',
		'mdi-file-alert',
		'mdi-file-alert-outline',
		'mdi-file-arrow-left-right',
		'mdi-file-arrow-left-right-outline',
		'mdi-file-arrow-up-down',
		'mdi-file-arrow-up-down-outline',
		'mdi-file-cabinet',
		'mdi-file-cad',
		'mdi-file-cad-box',
		'mdi-file-cancel',
		'mdi-file-cancel-outline',
		'mdi-file-certificate',
		'mdi-file-certificate-outline',
		'mdi-file-chart',
		'mdi-file-chart-check',
		'mdi-file-chart-check-outline',
		'mdi-file-chart-outline',
		'mdi-file-check',
		'mdi-file-check-outline',
		'mdi-file-clock',
		'mdi-file-clock-outline',
		'mdi-file-cloud',
		'mdi-file-cloud-outline',
		'mdi-file-code',
		'mdi-file-code-outline',
		'mdi-file-cog',
		'mdi-file-cog-outline',
		'mdi-file-compare',
		'mdi-file-delimited',
		'mdi-file-delimited-outline',
		'mdi-file-document',
		'mdi-file-document-alert',
		'mdi-file-document-alert-outline',
		'mdi-file-document-arrow-right',
		'mdi-file-document-arrow-right-outline',
		'mdi-file-document-check',
		'mdi-file-document-check-outline',
		'mdi-file-document-edit',
		'mdi-file-document-edit-outline',
		'mdi-file-document-minus',
		'mdi-file-document-minus-outline',
		'mdi-file-document-multiple',
		'mdi-file-document-multiple-outline',
		'mdi-file-document-outline',
		'mdi-file-document-plus',
		'mdi-file-document-plus-outline',
		'mdi-file-document-remove',
		'mdi-file-document-remove-outline',
		'mdi-file-download',
		'mdi-file-download-outline',
		'mdi-file-edit',
		'mdi-file-edit-outline',
		'mdi-file-excel',
		'mdi-file-excel-box',
		'mdi-file-excel-box-outline',
		'mdi-file-excel-outline',
		'mdi-file-export',
		'mdi-file-export-outline',
		'mdi-file-eye',
		'mdi-file-eye-outline',
		'mdi-file-find',
		'mdi-file-find-outline',
		'mdi-file-gif-box',
		'mdi-file-hidden',
		'mdi-file-image',
		'mdi-file-image-marker',
		'mdi-file-image-marker-outline',
		'mdi-file-image-minus',
		'mdi-file-image-minus-outline',
		'mdi-file-image-outline',
		'mdi-file-image-plus',
		'mdi-file-image-plus-outline',
		'mdi-file-image-remove',
		'mdi-file-image-remove-outline',
		'mdi-file-import',
		'mdi-file-import-outline',
		'mdi-file-jpg-box',
		'mdi-file-key',
		'mdi-file-key-outline',
		'mdi-file-link',
		'mdi-file-link-outline',
		'mdi-file-lock',
		'mdi-file-lock-open',
		'mdi-file-lock-open-outline',
		'mdi-file-lock-outline',
		'mdi-file-marker',
		'mdi-file-marker-outline',
		'mdi-file-minus',
		'mdi-file-minus-outline',
		'mdi-file-move',
		'mdi-file-move-outline',
		'mdi-file-multiple',
		'mdi-file-multiple-outline',
		'mdi-file-music',
		'mdi-file-music-outline',
		'mdi-file-outline',
		'mdi-file-pdf-box',
		'mdi-file-percent',
		'mdi-file-percent-outline',
		'mdi-file-phone',
		'mdi-file-phone-outline',
		'mdi-file-plus',
		'mdi-file-plus-outline',
		'mdi-file-png-box',
		'mdi-file-powerpoint',
		'mdi-file-powerpoint-box',
		'mdi-file-powerpoint-box-outline',
		'mdi-file-powerpoint-outline',
		'mdi-file-presentation-box',
		'mdi-file-question',
		'mdi-file-question-outline',
		'mdi-file-refresh',
		'mdi-file-refresh-outline',
		'mdi-file-remove',
		'mdi-file-remove-outline',
		'mdi-file-replace',
		'mdi-file-replace-outline',
		'mdi-file-restore',
		'mdi-file-restore-outline',
		'mdi-file-rotate-left',
		'mdi-file-rotate-left-outline',
		'mdi-file-rotate-right',
		'mdi-file-rotate-right-outline',
		'mdi-file-search',
		'mdi-file-search-outline',
		'mdi-file-send',
		'mdi-file-send-outline',
		'mdi-file-settings',
		'mdi-file-settings-outline',
		'mdi-file-sign',
		'mdi-file-star',
		'mdi-file-star-outline',
		'mdi-file-swap',
		'mdi-file-swap-outline',
		'mdi-file-sync',
		'mdi-file-sync-outline',
		'mdi-file-table',
		'mdi-file-table-box',
		'mdi-file-table-box-multiple',
		'mdi-file-table-box-multiple-outline',
		'mdi-file-table-box-outline',
		'mdi-file-table-outline',
		'mdi-file-tree',
		'mdi-file-tree-outline',
		'mdi-file-undo',
		'mdi-file-undo-outline',
		'mdi-file-upload',
		'mdi-file-upload-outline',
		'mdi-file-video',
		'mdi-file-video-outline',
		'mdi-file-word',
		'mdi-file-word-box',
		'mdi-file-word-box-outline',
		'mdi-file-word-outline',
		'mdi-file-xml-box',
		'mdi-folder',
		'mdi-folder-account',
		'mdi-folder-account-outline',
		'mdi-folder-alert',
		'mdi-folder-alert-outline',
		'mdi-folder-arrow-down',
		'mdi-folder-arrow-down-outline',
		'mdi-folder-arrow-left',
		'mdi-folder-arrow-left-outline',
		'mdi-folder-arrow-left-right',
		'mdi-folder-arrow-left-right-outline',
		'mdi-folder-arrow-right',
		'mdi-folder-arrow-right-outline',
		'mdi-folder-arrow-up',
		'mdi-folder-arrow-up-down',
		'mdi-folder-arrow-up-down-outline',
		'mdi-folder-arrow-up-outline',
		'mdi-folder-cancel',
		'mdi-folder-cancel-outline',
		'mdi-folder-check',
		'mdi-folder-check-outline',
		'mdi-folder-clock',
		'mdi-folder-clock-outline',
		'mdi-folder-cog',
		'mdi-folder-cog-outline',
		'mdi-folder-download',
		'mdi-folder-download-outline',
		'mdi-folder-edit',
		'mdi-folder-edit-outline',
		'mdi-folder-eye',
		'mdi-folder-eye-outline',
		'mdi-folder-file',
		'mdi-folder-file-outline',
		'mdi-folder-google-drive',
		'mdi-folder-heart',
		'mdi-folder-heart-outline',
		'mdi-folder-hidden',
		'mdi-folder-home',
		'mdi-folder-home-outline',
		'mdi-folder-image',
		'mdi-folder-information',
		'mdi-folder-information-outline',
		'mdi-folder-key',
		'mdi-folder-key-network',
		'mdi-folder-key-network-outline',
		'mdi-folder-key-outline',
		'mdi-folder-lock',
		'mdi-folder-lock-open',
		'mdi-folder-lock-open-outline',
		'mdi-folder-lock-outline',
		'mdi-folder-marker',
		'mdi-folder-marker-outline',
		'mdi-folder-minus',
		'mdi-folder-minus-outline',
		'mdi-folder-move',
		'mdi-folder-move-outline',
		'mdi-folder-multiple',
		'mdi-folder-multiple-image',
		'mdi-folder-multiple-outline',
		'mdi-folder-multiple-plus',
		'mdi-folder-multiple-plus-outline',
		'mdi-folder-music',
		'mdi-folder-music-outline',
		'mdi-folder-network',
		'mdi-folder-network-outline',
		'mdi-folder-off',
		'mdi-folder-off-outline',
		'mdi-folder-open',
		'mdi-folder-open-outline',
		'mdi-folder-outline',
		'mdi-folder-play',
		'mdi-folder-play-outline',
		'mdi-folder-plus',
		'mdi-folder-plus-outline',
		'mdi-folder-pound',
		'mdi-folder-pound-outline',
		'mdi-folder-question',
		'mdi-folder-question-outline',
		'mdi-folder-refresh',
		'mdi-folder-refresh-outline',
		'mdi-folder-remove',
		'mdi-folder-remove-outline',
		'mdi-folder-search',
		'mdi-folder-search-outline',
		'mdi-folder-settings',
		'mdi-folder-settings-outline',
		'mdi-folder-star',
		'mdi-folder-star-multiple',
		'mdi-folder-star-multiple-outline',
		'mdi-folder-star-outline',
		'mdi-folder-swap',
		'mdi-folder-swap-outline',
		'mdi-folder-sync',
		'mdi-folder-sync-outline',
		'mdi-folder-table',
		'mdi-folder-table-outline',
		'mdi-folder-text',
		'mdi-folder-text-outline',
		'mdi-folder-upload',
		'mdi-folder-upload-outline',
		'mdi-folder-wrench',
		'mdi-folder-wrench-outline',
		'mdi-folder-zip',
		'mdi-folder-zip-outline',
		'mdi-text-box',
		'mdi-text-box-check',
		'mdi-text-box-check-outline',
		'mdi-text-box-edit',
		'mdi-text-box-edit-outline',
		'mdi-text-box-minus',
		'mdi-text-box-minus-outline',
		'mdi-text-box-multiple',
		'mdi-text-box-multiple-outline',
		'mdi-text-box-outline',
		'mdi-text-box-plus',
		'mdi-text-box-plus-outline',
		'mdi-text-box-remove',
		'mdi-text-box-remove-outline',
		'mdi-text-box-search',
		'mdi-text-box-search-outline',
		'mdi-zip-box',
		'mdi-zip-box-outline',
	],
	food: [
		'mdi-baguette',
		'mdi-barley',
		'mdi-blender',
		'mdi-blender-outline',
		'mdi-bottle-soda',
		'mdi-bottle-soda-classic',
		'mdi-bottle-soda-outline',
		'mdi-bottle-wine',
		'mdi-bottle-wine-outline',
		'mdi-bowl',
		'mdi-bowl-mix',
		'mdi-bowl-mix-outline',
		'mdi-bowl-outline',
		'mdi-bread-slice',
		'mdi-bread-slice-outline',
		'mdi-cake',
		'mdi-cake-layered',
		'mdi-cake-variant',
		'mdi-cake-variant-outline',
		'mdi-candy',
		'mdi-candy-off',
		'mdi-candy-off-outline',
		'mdi-candy-outline',
		'mdi-candycane',
		'mdi-carrot',
		'mdi-cheese',
		'mdi-cheese-off',
		'mdi-chili-hot',
		'mdi-chili-medium',
		'mdi-chili-mild',
		'mdi-chili-off',
		'mdi-coffee',
		'mdi-coffee-maker',
		'mdi-coffee-maker-check',
		'mdi-coffee-maker-check-outline',
		'mdi-coffee-off',
		'mdi-coffee-off-outline',
		'mdi-coffee-outline',
		'mdi-coffee-to-go',
		'mdi-coffee-to-go-outline',
		'mdi-cookie',
		'mdi-cookie-alert',
		'mdi-cookie-alert-outline',
		'mdi-cookie-check',
		'mdi-cookie-check-outline',
		'mdi-cookie-clock',
		'mdi-cookie-clock-outline',
		'mdi-cookie-cog',
		'mdi-cookie-cog-outline',
		'mdi-cookie-edit',
		'mdi-cookie-edit-outline',
		'mdi-cookie-lock',
		'mdi-cookie-lock-outline',
		'mdi-cookie-minus',
		'mdi-cookie-minus-outline',
		'mdi-cookie-off',
		'mdi-cookie-off-outline',
		'mdi-cookie-outline',
		'mdi-cookie-plus',
		'mdi-cookie-plus-outline',
		'mdi-cookie-refresh',
		'mdi-cookie-refresh-outline',
		'mdi-cookie-remove',
		'mdi-cookie-remove-outline',
		'mdi-cookie-settings',
		'mdi-cookie-settings-outline',
		'mdi-corn',
		'mdi-corn-off',
		'mdi-cow-off',
		'mdi-cube-off-outline',
		'mdi-cube-outline',
		'mdi-cup',
		'mdi-cup-off',
		'mdi-cup-off-outline',
		'mdi-cup-outline',
		'mdi-cup-water',
		'mdi-cupcake',
		'mdi-egg',
		'mdi-egg-fried',
		'mdi-egg-off',
		'mdi-egg-off-outline',
		'mdi-egg-outline',
		'mdi-fish',
		'mdi-fish-off',
		'mdi-food',
		'mdi-food-apple',
		'mdi-food-apple-outline',
		'mdi-food-croissant',
		'mdi-food-drumstick',
		'mdi-food-drumstick-off',
		'mdi-food-drumstick-off-outline',
		'mdi-food-drumstick-outline',
		'mdi-food-fork-drink',
		'mdi-food-halal',
		'mdi-food-hot-dog',
		'mdi-food-kosher',
		'mdi-food-off',
		'mdi-food-off-outline',
		'mdi-food-outline',
		'mdi-food-steak',
		'mdi-food-steak-off',
		'mdi-food-takeout-box',
		'mdi-food-takeout-box-outline',
		'mdi-food-turkey',
		'mdi-food-variant',
		'mdi-food-variant-off',
		'mdi-french-fries',
		'mdi-fruit-cherries',
		'mdi-fruit-cherries-off',
		'mdi-fruit-citrus',
		'mdi-fruit-citrus-off',
		'mdi-fruit-grapes',
		'mdi-fruit-grapes-outline',
		'mdi-fruit-pear',
		'mdi-fruit-pineapple',
		'mdi-fruit-watermelon',
		'mdi-glass-cocktail',
		'mdi-glass-cocktail-off',
		'mdi-glass-flute',
		'mdi-glass-fragile',
		'mdi-glass-mug',
		'mdi-glass-mug-off',
		'mdi-glass-mug-variant',
		'mdi-glass-mug-variant-off',
		'mdi-glass-pint-outline',
		'mdi-glass-stange',
		'mdi-grill',
		'mdi-grill-outline',
		'mdi-hamburger',
		'mdi-hamburger-check',
		'mdi-hamburger-minus',
		'mdi-hamburger-off',
		'mdi-hamburger-plus',
		'mdi-hamburger-remove',
		'mdi-hops',
		'mdi-ice-cream',
		'mdi-ice-cream-off',
		'mdi-ice-pop',
		'mdi-kettle',
		'mdi-kettle-alert',
		'mdi-kettle-alert-outline',
		'mdi-kettle-off',
		'mdi-kettle-off-outline',
		'mdi-kettle-outline',
		'mdi-kettle-steam',
		'mdi-kettle-steam-outline',
		'mdi-leaf',
		'mdi-leaf-off',
		'mdi-leek',
		'mdi-liquor',
		'mdi-microwave',
		'mdi-muffin',
		'mdi-mushroom',
		'mdi-mushroom-off',
		'mdi-mushroom-off-outline',
		'mdi-mushroom-outline',
		'mdi-noodles',
		'mdi-nutrition',
		'mdi-pasta',
		'mdi-peanut',
		'mdi-peanut-off',
		'mdi-peanut-off-outline',
		'mdi-peanut-outline',
		'mdi-pizza',
		'mdi-popcorn',
		'mdi-pot',
		'mdi-pot-mix',
		'mdi-pot-mix-outline',
		'mdi-pot-outline',
		'mdi-pot-steam',
		'mdi-pot-steam-outline',
		'mdi-pretzel',
		'mdi-rice',
		'mdi-sausage',
		'mdi-sausage-off',
		'mdi-scale',
		'mdi-seed',
		'mdi-seed-off',
		'mdi-seed-off-outline',
		'mdi-seed-outline',
		'mdi-shaker',
		'mdi-shaker-outline',
		'mdi-silverware',
		'mdi-silverware-clean',
		'mdi-silverware-fork',
		'mdi-silverware-fork-knife',
		'mdi-silverware-spoon',
		'mdi-silverware-variant',
		'mdi-soy-sauce',
		'mdi-spoon-sugar',
		'mdi-square-circle',
		'mdi-stove',
		'mdi-taco',
		'mdi-tea',
		'mdi-tea-outline',
		'mdi-toaster-oven',
		'mdi-water',
	],
	games: [
		'mdi-arrow-projectile',
		'mdi-arrow-projectile-multiple',
		'mdi-axe-battle',
		'mdi-black-mesa',
		'mdi-bomb',
		'mdi-bomb-off',
		'mdi-boomerang',
		'mdi-bottle-tonic-plus',
		'mdi-bottle-tonic-plus-outline',
		'mdi-bottle-tonic-skull',
		'mdi-bottle-tonic-skull-outline',
		'mdi-bow-arrow',
		'mdi-candy-off-outline',
		'mdi-candy-outline',
		'mdi-cards',
		'mdi-cards-club',
		'mdi-cards-diamond',
		'mdi-cards-heart',
		'mdi-cards-outline',
		'mdi-cards-playing',
		'mdi-cards-playing-club',
		'mdi-cards-playing-club-multiple',
		'mdi-cards-playing-club-multiple-outline',
		'mdi-cards-playing-club-outline',
		'mdi-cards-playing-diamond',
		'mdi-cards-playing-diamond-multiple',
		'mdi-cards-playing-diamond-multiple-outline',
		'mdi-cards-playing-diamond-outline',
		'mdi-cards-playing-heart',
		'mdi-cards-playing-heart-multiple',
		'mdi-cards-playing-heart-multiple-outline',
		'mdi-cards-playing-heart-outline',
		'mdi-cards-playing-outline',
		'mdi-cards-playing-spade',
		'mdi-cards-playing-spade-multiple',
		'mdi-cards-playing-spade-multiple-outline',
		'mdi-cards-playing-spade-outline',
		'mdi-cards-spade',
		'mdi-cards-spade-outline',
		'mdi-cards-variant',
		'mdi-checkerboard',
		'mdi-chess-bishop',
		'mdi-chess-knight',
		'mdi-chess-pawn',
		'mdi-chess-queen',
		'mdi-chess-rook',
		'mdi-controller',
		'mdi-controller-classic',
		'mdi-controller-classic-outline',
		'mdi-controller-off',
		'mdi-crown-circle',
		'mdi-crown-circle-outline',
		'mdi-crystal-ball',
		'mdi-dice-1',
		'mdi-dice-1-outline',
		'mdi-dice-2',
		'mdi-dice-2-outline',
		'mdi-dice-3',
		'mdi-dice-3-outline',
		'mdi-dice-4',
		'mdi-dice-4-outline',
		'mdi-dice-5',
		'mdi-dice-5-outline',
		'mdi-dice-6',
		'mdi-dice-6-outline',
		'mdi-dice-d10',
		'mdi-dice-d10-outline',
		'mdi-dice-d12',
		'mdi-dice-d12-outline',
		'mdi-dice-d20',
		'mdi-dice-d20-outline',
		'mdi-dice-d4',
		'mdi-dice-d4-outline',
		'mdi-dice-d6',
		'mdi-dice-d6-outline',
		'mdi-dice-d8',
		'mdi-dice-d8-outline',
		'mdi-dice-multiple',
		'mdi-dice-multiple-outline',
		'mdi-expansion-card',
		'mdi-flask',
		'mdi-flask-empty',
		'mdi-flask-empty-outline',
		'mdi-flask-outline',
		'mdi-gamepad',
		'mdi-gamepad-circle',
		'mdi-gamepad-circle-down',
		'mdi-gamepad-circle-left',
		'mdi-gamepad-circle-outline',
		'mdi-gamepad-circle-right',
		'mdi-gamepad-circle-up',
		'mdi-gamepad-down',
		'mdi-gamepad-left',
		'mdi-gamepad-outline',
		'mdi-gamepad-right',
		'mdi-gamepad-round',
		'mdi-gamepad-round-down',
		'mdi-gamepad-round-left',
		'mdi-gamepad-round-outline',
		'mdi-gamepad-round-right',
		'mdi-gamepad-round-up',
		'mdi-gamepad-square',
		'mdi-gamepad-square-outline',
		'mdi-gamepad-up',
		'mdi-gamepad-variant',
		'mdi-gamepad-variant-outline',
		'mdi-ghost',
		'mdi-ghost-off',
		'mdi-ghost-off-outline',
		'mdi-ghost-outline',
		'mdi-gog',
		'mdi-google-downasaur',
		'mdi-heart',
		'mdi-heart-half',
		'mdi-heart-half-full',
		'mdi-heart-half-outline',
		'mdi-heart-outline',
		'mdi-hololens',
		'mdi-knife-military',
		'mdi-lambda',
		'mdi-mace',
		'mdi-magic-staff',
		'mdi-medal',
		'mdi-microsoft-windows',
		'mdi-microsoft-xbox',
		'mdi-microsoft-xbox-controller',
		'mdi-microsoft-xbox-controller-battery-alert',
		'mdi-microsoft-xbox-controller-battery-charging',
		'mdi-microsoft-xbox-controller-battery-empty',
		'mdi-microsoft-xbox-controller-battery-full',
		'mdi-microsoft-xbox-controller-battery-low',
		'mdi-microsoft-xbox-controller-battery-medium',
		'mdi-microsoft-xbox-controller-battery-unknown',
		'mdi-microsoft-xbox-controller-menu',
		'mdi-microsoft-xbox-controller-off',
		'mdi-microsoft-xbox-controller-view',
		'mdi-nintendo-game-boy',
		'mdi-nintendo-wii',
		'mdi-nintendo-wiiu',
		'mdi-ocarina',
		'mdi-one-up',
		'mdi-pac-man',
		'mdi-pokeball',
		'mdi-pokemon-go',
		'mdi-poker-chip',
		'mdi-puzzle',
		'mdi-puzzle-check',
		'mdi-puzzle-check-outline',
		'mdi-puzzle-edit',
		'mdi-puzzle-edit-outline',
		'mdi-puzzle-heart',
		'mdi-puzzle-heart-outline',
		'mdi-puzzle-minus',
		'mdi-puzzle-minus-outline',
		'mdi-puzzle-outline',
		'mdi-puzzle-plus',
		'mdi-puzzle-plus-outline',
		'mdi-puzzle-remove',
		'mdi-puzzle-remove-outline',
		'mdi-puzzle-star',
		'mdi-puzzle-star-outline',
		'mdi-sack',
		'mdi-script',
		'mdi-script-outline',
		'mdi-script-text',
		'mdi-script-text-outline',
		'mdi-shield',
		'mdi-shield-cross',
		'mdi-shield-cross-outline',
		'mdi-shield-crown',
		'mdi-shield-crown-outline',
		'mdi-shield-outline',
		'mdi-shield-sword',
		'mdi-shield-sword-outline',
		'mdi-skull',
		'mdi-skull-crossbones',
		'mdi-skull-crossbones-outline',
		'mdi-skull-outline',
		'mdi-sony-playstation',
		'mdi-space-invaders',
		'mdi-spear',
		'mdi-star-face',
		'mdi-steam',
		'mdi-sword',
		'mdi-sword-cross',
		'mdi-tangram',
		'mdi-tournament',
		'mdi-treasure-chest',
		'mdi-triforce',
		'mdi-ubisoft',
		'mdi-unity',
		'mdi-unreal',
		'mdi-wizard-hat',
	]
}

